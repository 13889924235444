<template>
  <v-container id="terms">
    <div class="col-xs-12 col-sm-9 col-md-9">
      <p>O PROGRAMA DE INDICAÇÃO é aplicável aos usuários do Consulta + Rápida e do Artmed + Rápida PSI, ambos disponibilizados pelo GRUPO A EDUCAÇÃO S.A. (“Grupo A”), sediado na Rua Ernesto Alves, 150, Porto Alegre, Rio Grande do Sul, CNPJ/MF nº 87.133.666/0001-04, para dispositivos móveis com sistemas Android e iOS. Ao realizar o acesso ao aplicativo, o usuário se submeterá automaticamente às regras e condições deste Regulamento.</p>
      <p>• 1. Objetivo: O Programa de Indicação, tem por objetivo gerar benefícios na forma de cupons de descontos e períodos gratuito de testes do App aos Usuários que indicarem novos assinantes, conforme regras e condições a seguir.</p>
      <p>• 2. Período de Vigência do Programa: o programa tem início em 01/06/2021, e vigerá por tempo indeterminado, podendo ser alterado, suspenso ou cancelado a qualquer momento, mediante mera divulgação nos canais de comunicação oficiais do Aplicativo.</p>
      <p>• 3. Critérios de participação: podem participar do programa como Indicador qualquer pessoa física, maior de 18 anos.  </p>
      <p>• 3.1. A participação é voluntária e não acarreta nenhum custo ao participante.</p>
      <p>• 3.2. A Participação significa a adesão ao Programa com a plena e irrestrita concordância à todas as normas contidas neste regulamento.</p>
      <p>• 4. Benefícios:  Indicador e Indicado terão direito aos benefícios previstos neste item.</p>
      <p>• 4.1 Por cada novo indicado, o Indicador ganhará um cupom de desconto na loja do grupo A no valor de: </p>
      <p>• R$ 25,00 (vinte e cinco reais) se o indicado assinar e pagar o plano mensal; e,</p>
      <p>• R$ 50,00 (cinquenta reais) se o indicado assinar e pagar o plano anual.</p>
      <p>• 4.2 O indicado que assinar o aplicativo por meio do link personalizado recebido terá direito a:</p>
      <p>• 14 (quatorze) dias de teste, sem custo, no plano mensal; e,</p>
      <p>• 60 (sessenta dias) de teste, sem custo, no plano anual.</p>
      <p>• 5. Procedimentos, deveres e prerrogativas: Dentro do Aplicativo, os Indicadores poderão acessar o link correspondente, realizar seu cadastro e, por meio do botão “convidar”, enviar um link personalizado para download do aplicativo ao indicado. </p>
      <p>• 5.1. O link enviado é nominal e intransferível, ou seja, só pode ser utilizado pelo usuário indicado;</p>
      <p>• 5.2. O indicador apenas terá direito ao recebimento do benefício quando o indicado, após fruição do período gratuito de testes, realizar o pagamento da assinatura de Plano no aplicativo;</p>
      <p>• 5.3. Os benefícios serão alcançados ao indicador de acordo com o plano selecionado por cada indicado, tantos quantos sejam os indicados;</p>
      <p>• 5.4. Os benefícios do indicado só poderão ser utilizados uma única vez, não sendo cumulativos ou passíveis de utilização em decorrência de diferentes indicações eventualmente recebidas;</p>
      <p>• 5.5. Os créditos do Indicador, somente serão disponibilizados após 30 (trinta) dias contatos do pagamento efetivado pelo indicado;</p>
      <p>• 5.6. Caso o indicado cancele a assinatura, gerando reembolso dos valores pelo Grupo A, antes de completar 60 (sessenta) dias de curso, os créditos serão anulados e o beneficiário será comunicado;</p>
      <p>• 5.7. Em nenhuma hipótese os benefícios previstos neste regulamento serão recebidos em dinheiro pelo indicador ou indicado;</p>
      <p>• 5.8. Os benefícios somente poderão ser utilizados para as finalidades descritas neste Regulamento;</p>
      <p>• 5.9. Os créditos alcançados ao Indicador serão válidos por 2 (dois) anos, contados da data de sua disponibilização;</p>
      <p>• 5.10. O link de acesso para início da fruição dos benefícios concedidos ao Indicado expira após 90 (noventa) dias contatos de sua geração.</p>
      <p>6. Disposições Gerais: Indicador e indicado devem observar, no que couber, todas as regras abaixo.</p>
      <p>• 6.1. Conduta com outras doenças não psiquiátricas</p>
      <p>• 6.2. Conduta com outras doenças não psiquiátricas</p>
      <p>• 6.3. Conduta com outras doenças não psiquiátricas</p>
      <p>• 6.4. Conduta com outras doenças não psiquiátricas</p>
    </div>


  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";


export default {

  data: () => ({

  }),
  
  computed: {
    
  },

  watch: {   
  },

  mounted: function () {
    this.$root.title = "Programa de Indicação";
  },

  methods: {
  }
};
</script>
<style lang="scss"> 
   #terms {
     .title{
       padding-bottom: 30px;
     }
     .subtitle{
       padding-top: 10px;
       padding-bottom: 20px;
     }
     p {
       padding-bottom: 2px;
     }

   }
</style>