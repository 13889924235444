<template>
  <v-container class="pr-4 pl-4" fluid>
    <v-form v-model="form" ref="form">
      
      <v-row class="mx-1">
        <v-col cols="6" class="d-flex flex-column">
          <v-slide-y-reverse-transition>
            <v-avatar
              style="margin: 0 auto"
              size="96"
              @click="$refs.avatar.click()"
            >
              <img v-if="users[0].avatar" :src="users[0].avatar" alt />
              <img v-else src="./../assets/user.png" alt />
            </v-avatar>
          </v-slide-y-reverse-transition>
        </v-col>
        <!-- <v-divider vertical></v-divider> -->
        <v-col cols="6" class="d-flex flex-column">
          <v-slide-y-reverse-transition>
            <v-avatar
              style="margin: 0 auto"
              size="96"
              @click="$refs.avatar.click()"
            >
              <img v-if="users[1].avatar" :src="users[1].avatar" alt />
              <img v-else src="./../assets/user.png" alt />
            </v-avatar>
          </v-slide-y-reverse-transition>
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="6">
          <v-row class="mx-0 py-0">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="users[0].name"
                label="Nome"
                readonly
              />
            </v-col>
            <v-col cols="9" class="py-0">
              <v-text-field
                v-model="users[0].email"
                label="E-mail"
                readonly
              />
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field
                :value="apps[users[0].apps]"
                label="Sistema"
                readonly
              />
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-row class="mx-0 py-0">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="users[1].name"
                label="Nome"
                readonly
              />
            </v-col>
            <v-col cols="9" class="py-0">
              <v-text-field
                v-model="users[1].email"
                label="E-mail"
                readonly
              />
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field
                :value="apps[users[1].apps]"
                label="Sistema"
                readonly
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="6">
          <v-subheader>CREDENCIAIS</v-subheader>
          <v-row>
            <v-col>
              <v-data-table
                :items="users[0].aliases"
                :headers="tables.aliases.headers"
                :loading="loading"
                :hide-default-footer="true"
                no-data-text="Nenhum resultado encontrado"
                  
              >
                <template v-slot:[`item.email`]="{ item }">
                  {{ item.email || '-' }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-subheader>CREDENCIAIS</v-subheader>
          <v-row>
            <v-col>
              <v-data-table
                :items="users[1].aliases"
                :headers="tables.aliases.headers"
                :loading="loading"
                :hide-default-footer="true"
                no-data-text="Nenhum resultado encontrado"
              >
                <template v-slot:[`item.email`]="{ item }">
                  {{ item.email || '-' }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="6">
          <v-subheader>INSTALAÇÔES</v-subheader>
          <v-row>
            <v-col>
              <v-data-table
                  :items="users[0].installations"
                  :headers="tables.installations.headers"
                  :loading="loading"
                  :hide-default-footer="true"
                  no-data-text="Nenhum resultado encontrado"

                >
                  <template v-slot:[`item.date`]="{ item }">
                    <span>{{ format(item.date) }}</span>
                  </template>              
                </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-subheader>INSTALAÇÕES</v-subheader>
          <v-row>
            <v-col>
              <v-data-table
                  :items="users[1].installations"
                  :headers="tables.installations.headers"
                  :loading="loading"
                  :hide-default-footer="true"
                  no-data-text="Nenhum resultado encontrado"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    <span>{{ format(item.date) }}</span>
                  </template>              
                </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="6">
          <v-subheader>HISTÓRICO</v-subheader>
          <v-row>
            <v-col>
              <v-data-table
                  :items="users[0].histories"
                  :headers="tables.histories.headers"
                  :loading="loading"
                  :hide-default-footer="true"
                  no-data-text="Nenhum resultado encontrado"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    <span>{{ format(item.date) }}</span>
                  </template>              
                </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-subheader>HISTORICO</v-subheader>
          <v-row>
            <v-col>
              <v-data-table
                  :items="users[1].histories"
                  :headers="tables.histories.headers"
                  :loading="loading"
                  :hide-default-footer="true"
                  no-data-text="Nenhum resultado encontrado"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    <span>{{ format(item.date) }}</span>
                  </template>              
                </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-form>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { v4 as uuid } from "uuid";
import omitEmpty from "omit-empty";
import { diff } from "deep-diff";
import UserCredentials from "../components/User.Credentials";
import moment from 'moment';

export default {
  components: {
    UserCredentials
  },

  data: () => ({
    loading: false,
    subscriptions: [],
   
    form: false,
    
    users: [{aliases:[],installations:[]},{aliases:[],installations:[]}],

    tables: {
      aliases: {
          loading: false,
          headers: [
            { text: "TIPO", value: "source", width: '60px', sortable: false },
            { text: "E-MAIL", value: "email", sortable: false },
        ]
      },
      installations: {
          loading: false,
          headers: [
            { text: "DISPOSITIVO", value: "device", sortable: false },
            { text: "IDENTIFICADOR", value: "identifier", sortable: false },
            { text: "VERSÃO", value: "version", sortable: false },
            { text: "DATA", value: "date", sortable: false },
        ]
      },
      histories: {
          loading: false,
          headers: [
            { text: "PLANO", value: "plan", sortable: false },
            { text: "GATEWAY", value: "gateway", sortable: false },
            { text: "RECIBO", value: "receipt", sortable: false },
            { text: "DATE", value: "date", sortable: false },
        ]
      }
      
    },

    total: 0,
    types: [
      {
        value: 1,
        name: "Usuário"
      },
      {
        value: 2,
        name: "Autor"
      }
    ],
    apps: {
      1: '+CLIN', 
      2: '+PSI', 
      3: '+CLIN,+PSI'
    }
  }),

  mounted: function() {
    this.$root.title = "Comparação";

    this.$root.show({});
    
    this.$data.loading = true;
    this.load();
  },

  computed: {
    
    manualPremium: function() {
      return (this.old.premium && this.old.premium.manual) ? "Sim" : "Não"
    },

    allTypes: function() {
      return [{ name: "Todos", value: "" }, ...this.types];
    },

    hasChanged: function() {
      const changes = diff(this.newUser, this.old);
      return !!changes;
    }
  },

  watch: {
    hasChanged: {
      handler(changed) {
        this.$root.actions.save.disable = !(
          this.$refs.form.validate() && this.hasChanged
        );
      },
      deep: true
    }
  },

  methods: {
    format(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    debounce: _.debounce(function() {
      this.load();
    }, 500),

    load: function() {
      this.getUsers();
    },

    getUsers() {
      var users = this.$route.query.users.split(',');
      axios
        .get(`users?id=${users[0]},${users[1]}&version=6`)
        .then(e => {
          this.users = e.data.data;
          this.loading = false;
        })
        .catch(e => {})
        .finally(e => {});
    },

    typeMap(_type) {
      const type = this.types.filter(e => e.value == _type)[0];
      return type ? type.name : "-";
    },

    clone: function(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    goTo: function(route, id) {
      router.push(`/${route}/${id}`)
    }
  }
};
</script>
<style>
.link:hover {
  cursor: pointer;
}

.error {
  font-size: 12px;
}
</style>