<template>
  <v-container id="views">
    <div>
      <h2 class="title">{{page.name}}</h2>
      <p v-html="page.text"></p>    
    </div>
  </v-container>
</template>

<script>

import Vue from "vue";
import axios from "axios";
import router from "../router";

export default {

  data: () => ({
    page: {},
  }),
  
  computed: {
    
  },

  watch: {   
  },

  mounted: function () {
    //this.$root.title = "Política de Privacidade";
    this.$root.show({});

    var app = this.$route.query.app || 1;
    var id = app == 1? 'autores-cmr' : 'autores-psi';


    axios
      .get(`/pages/${id}?version=2`, {headers:{'x-app': app}})
      .then(e => {
        this.$root.title = e.data.data.title;
        this.page = e.data.data;
      })
      .catch(e => {

      })
      .finally(e => {
        
      });
  },

  methods: {
  }
};
</script>
<style lang="scss"> 
   #views {
     .title{
       padding-bottom: 30px;
     }
     .subtitle{
       padding-top: 10px;
       padding-bottom: 20px;
     }
     p {
       padding-bottom: 2px;
     }

   }
</style>