<template>
   <v-container style="postion:relative">
      <div>
         <v-spacer></v-spacer>
         <v-row>
            <v-col cols="12" md="12">
               <v-text-field
                  v-model="code"
                  prepend-icon="mdi-magnify"
                  label="Busca"
                  v-on:input="debounce"
                  ></v-text-field>
            </v-col>
         </v-row>
      </div>
      <br><br>
      <v-data-table
         :loading="loading"
         loading-text="Aguarde..."
         :headers="headers"
         :items="entries"
         no-data-text="Sem resultados"
         :options.sync="options"
         :server-items-length="total"
         @click:row="goToVoucher"
         :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"   
         >

         <template v-slot:[`item.code`]="{ item }">
          <div class="copyable" v-on:click.stop="copy(item.code)">{{ item.code }}</div>
         </template>

         <template v-slot:[`item.used`]="{ item }">
          <span>{{ `${item.usage || 0}/${item.limit}`}}</span>
        </template>

        <template v-slot:[`item.expiresAt`]="{ item }">
          <span>{{item.expiresAt | moment("DD/MM/YYYY")}}</span>
        </template>

        <template v-slot:[`item.duration`]="{ item }">
          <span v-if="item.type==1">{{item.endsAt | moment("DD/MM/YYYY") }}</span>
          <span v-if="item.type==2">{{item.days + " dias" }}</span>
        </template>

         <template v-slot:[`item.id`]="{ item }">
          <div class="copyable" v-on:click.stop="copy(item.id)">{{ item.id }}</div>
         </template>
      </v-data-table>

      <v-snackbar v-model="snackbar" :timeout="2000">
         Id copiado
      </v-snackbar>
   
      <v-dialog
         v-model="dialog"
         width="600px"
         >
         <v-card>
            <v-card-title class="">
               Novo Voucher
            </v-card-title>
            <v-container>
              <v-form v-model="valid" ref="form">

                <v-row class="mx-2">
                    <v-col cols="12">
                          <v-text-field
                            label="Código"
                            v-model="voucher.code"
                            prepend-icon="mdi-barcode"
                            :rules="rules.code"
                            />
                    </v-col>
                </v-row>

                <v-row class="mx-2">
                  <v-col cols="6">
                      <v-menu
                        v-model="date1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Expira"
                            v-model="formatted.expiresAt"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            v-on:focus="date1=true"
                            clearable
                            persistent-hint
                            hint="Indica até quando o voucher pode ser usado"
                            /> 
                        </template>
                        <v-date-picker v-model="formatted.expiresAtRaw" no-title @input="date1 = false"></v-date-picker>
                    </v-menu>
                    </v-col>
                  <v-col cols="6">
                      <v-text-field
                        label="Limite"
                        type="number"
                        v-model="voucher.limit"
                        prepend-icon="mdi-tag-multiple"
                        hint="Quantidade de ativações que podem ser feitas"
                        persistent-hint
                        />
                    </v-col>
                </v-row>

                <v-row class="mx-2">
                  <v-col cols="6">
                     <v-select
                      :items="voucherTypes"
                      :item-text="'name'"
                      :item-value="'value'"
                      prepend-icon="local_offer"
                      v-model="voucher.type"
                      label="Tipo"
                    ></v-select>
                  </v-col>

                  <v-col cols="6" v-if="voucher.type == 1">
                      <v-menu
                        v-model="date2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Data"
                            v-model="formatted.endsAt"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            v-on:focus="date2=true"
                            clearable
                            persistent-hint
                            /> 
                        </template>
                        <v-date-picker v-model="formatted.endsAtRaw" no-title @input="date2 = false"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6" v-if="voucher.type == 2">
                    <v-text-field
                      label="Dias"
                      type="number"
                      v-model="voucher.days"
                      prepend-icon="schedule"
                      />
                  </v-col>
                </v-row>
                <v-row class="mx-2">
                  <v-col cols="12">
                    
                    <p v-if="voucher.type == 1" class="text--secondary text-justify">A DURAÇÃO FIXA indica que o voucher será válido até uma data fornecida, independente da data de ativação do voucher.</p> 
                    <p v-if="voucher.type == 2" class="text--secondary text-justify">A DURAÇÃO RELATIVA indica que o voucher terá uma validade relativa ao dia de ativação levando em consideração um número de DIAS definido.</p> 
                        
                  </v-col>
                </v-row>

                <v-row class="mx-2">
                  <v-col cols="12">
                   <v-textarea
                      rows="1"
                      label="Descrição"
                      v-model="voucher.description"
                      multiline
                      prepend-icon="mdi-format-annotation-plus"
                      :counter="1024" 
                      :rules="rules.description"
                      />
                  </v-col>
                </v-row>

              </v-form>
            </v-container>
            <v-card-actions>
               <v-spacer/>
               <v-btn text :loading="saving" @click="save()" :disabled="!valid && !saving"  width="100">Salvar</v-btn>
               <v-btn text color="primary" @click="dialog = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   
   </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import * as storage from '../libs/storage'

Vue.use(VueLodash)

export default {
  data: () => ({
    
    formatted: {
      expiresAtRaw: '',
      endsAtRaw: '',
      expiresAt: '',
      endsAt: ''
    },
    on: false,
    date1: false,
    date2: false,

    code: '',
    snackbar: false,

    valid: false,
    dialog: false,
    saving: false,
    loading: false,
    subscriptions: [],

    rules:{
      code: [
        v => !!v || 'Código é obrigatório',
        v => (v && v.length >=4 && v.length<=32) || 'O código deve ter entre 4 e 32 caracteres',
      ],
      description: [
        v => !!v || 'Descrição é obrigatória',
        v => (v && v.length <= 1024) || 'A descrição deve ter no máximo 1024 caracteres',
      ]

    },
    voucher : {
      code: null,
      expiresAt: '',
      endsAt: '',
      limit: '',
      type: 1,
      description: '',
      days: 0
    },
    
    entries: [],

    options: {
       page: 1,
       itemsPerPage: 10
    },
    total: 0,
    headers: [      
      { text: 'CODE', value: 'code', sortable: false },
      { text: 'ID', width: '320px', align: 'left', sortable: false, value: 'id'},
      { text: 'EXPIRA', width: '120px', value: 'expiresAt', sortable: false },
      { text: 'DURAÇÃO', value: 'duration', width: '50px', sortable: false },
      { text: 'USADOS', value: 'used', width: '50px', sortable: false },
    ],

    voucherTypes: [
      {
        name: 'Duração Fixa',
        value: 1
      },
      {
        name: 'Duração Relativa',
        value: 2
      }
    ]
  }),
  
  watch: {
    'formatted.expiresAtRaw': async function (val){
  
      this.voucher.expiresAt = val && new Date(val);
      this.formatted.expiresAt = val && moment.utc(this.voucher.expiresAt).format("DD/MM/YYYY");
    },
    'formatted.endsAtRaw': async function (val){
      this.voucher.endsAt = val && new Date(val);
      this.formatted.endsAt = val && moment.utc(this.voucher.endsAt).format("DD/MM/YYYY");
    },

    options: {
      async handler () {
        this.reload();
      },
      deep: true
    },
    category: {
      async handler(){
        this.reload();
      }
    },
    empty: {
      async handler(){
        this.reload();
      }
    }
  },

  mounted: function () {
    this.$root.title = "Vouchers";
    this.$root.show({create: true});
    
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.create.event.subscribe(e => {
      this.open();
    }));

    Object.assign(this.$data, pick(JSON.parse(storage.getItem('vouchers'))  || {},['code', 'options']));
    this.load(this.$data.code, 0, this.$data.options.itemsPerPage);
  },

  methods: {
      open: function(){
        this.dialog = true;
      },
      copy: async function(item){
        await this.$copyText(item);
        this.snackbar = true;
      },
      save: function() {

        this.saving = true;
        const voucher = this.voucher
        Object.assign(voucher, {days: parseInt(voucher.days)})

        axios
          .post(`/vouchers`, voucher)
          .then(e => {
            this.$dialog.notify.info('Voucher criado!', {
              position: 'top-right',
              //outlined: true,
              timeout: 2000
            });

            this.voucher = {code: null, expiresAt: null, endsAt: null, limit: '',description: ''};
            this.formatted = {expiresAtRaw: '',endsAtRaw: '',expiresAt: '',endsAt: ''};
            this.$refs.form.reset();
            this.dialog = false;
            this.reload();
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              //outlined: true,
              timeout: 5000
            });
          })
          .finally(e => {            
            this.saving = false;
          });

      },
      debounce: _.debounce(function () { 
        this.reload();
      }, 500),
      reload: function(){
        var code = this.code;
        var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(code, start, limit, sort);
      },
      load: function(code, start, limit, sort){
        this.loading = true;

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/vouchers?version=1&code=${code}&start=${start}&limit=${limit}${filter('sort', sort)}`)
          .then(e => {
            this.entries = e.data.data;
            this.total = e.data.total;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              //outlined: true,
              timeout: 5000
            });
          })
          .finally(e => {
            this.loading = false;
          });
      },


      goToVoucher: function(item) {
        this.$router.push(`vouchers/${item.id}`)
      }
  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem('vouchers', JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
 
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>