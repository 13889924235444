<template>
   <v-container style="postion:relative">
      <div>
         <v-spacer></v-spacer>
         <v-row>
            <v-col cols="12" md="12">
               <v-text-field
                  v-model="name"
                  prepend-icon="mdi-magnify"
                  label="Busca"
                  v-on:input="debounce"
                  ></v-text-field>
            </v-col>
         </v-row>
      </div>
      <br><br>
      <v-data-table
         :loading="loading" 
         loading-text="Aguarde..."
         :headers="headers"
         :items="entries"
         no-data-text="Sem resultados"
         :options.sync="options"
         :server-items-length="total"
         :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"   
         >

         <template v-slot:[`item.id`]="{ item }">
            <div class="copyable" v-on:click.stop="copy(item.id)">{{ item.id }}</div>
         </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              class="mr-2"
              color="red"
              @click="remove(item.id, true)"
            >
              mdi-delete
            </v-icon>
         </template>

         
      </v-data-table>
      <v-snackbar v-model="snackbar" :timeout="2000">
         Id copiado
      </v-snackbar>
      <v-dialog
         v-model="dialog"
         width="600px"
         >
         <v-card>
            <v-card-title class="">
               Novo Plano
            </v-card-title>
            <v-container>
              <v-form v-model="valid" ref="form">
                <v-row class="mx-2">
                    <v-col cols="12" style="padding-top:0px;padding-bottom:0px;">
                          <v-text-field
                            label="Nome"
                            v-model="plan.name"
                            prepend-icon="mdi-barcode"
                            :rules="rules.name"
                            />
                    </v-col>                   
                </v-row>
                <v-row class="mx-2">
                  <v-col cols="12" style="padding-top:0px;padding-bottom:0px;">
                      <v-text-field
                        label="SKU"
                        v-model="plan.sku"
                        prepend-icon="mdi-tag-multiple"
                        />
                    </v-col>
                </v-row>
                <v-row class="mx-2">
                  <v-col cols="12" style="padding-top:0px;padding-bottom:0px;">
                   <v-textarea
                      rows="1"
                      label="Descrição"
                      v-model="plan.description"
                      multiline
                      prepend-icon="mdi-format-annotation-plus"
                      :counter="1024" 
                      :rules="rules.description"
                      />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
               <v-spacer/>
               <v-btn text :loading="saving" @click="save()" :disabled="!valid && !saving"  width="100">Salvar</v-btn>
               <v-btn text color="primary" @click="dialog = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Remover Plano</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover o plano, não será mais possível restaurar. Tem certeza que deseja remover este plano?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove(removeDialog.id, false)" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

   </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import * as storage from '../libs/storage'

Vue.use(VueLodash)

export default {
  data: () => ({
    
    name: '',
    snackbar: false,

    valid: false,
    dialog: false,
    removeDialog: { show: false, loading: false, id: null },
    saving: false,
    loading: false,
    subscriptions: [],

    rules:{
      name: [
        v => !!v || 'Nome é obrigatório',
        v => (v && v.length <= 64) || 'O nome deve ter no máximo 64 caracteres',
      ],
      description: [
        v => !!v || 'Descrição é obrigatória',
        v => (v && v.length <= 1024) || 'A descrição deve ter no máximo 1024 caracteres',
      ]

    },
    plan : {
      name: '',
      sku: '',
      description: ''
    },
    
    entries: [],

    options: {
       page: 1,
       itemsPerPage: 10
    },
    total: 0,
    headers: [      
      { text: 'NOME', value: 'name', sortable: false },
      { text: 'ID', width: '320px', align: 'left', sortable: false, value: 'id'},
      { text: 'SKU', width: '120px', value: 'sku', sortable: false },
      { text: 'AÇÕES', value: 'action', width: '60px', sortable: false }
    ]
  }),
  
  watch: {

    options: {
      async handler () {
        this.reload();
      },
      deep: true
    }
  },

  mounted: function () {
    this.$root.title = "Planos";
    this.$root.show({create: true});
    
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.create.event.subscribe(e => {
      this.open();
    }));

    Object.assign(this.$data, pick(JSON.parse(storage.getItem('plans'))  || {},['name', 'options']));
    this.load(this.$data.code, 0, this.$data.options.itemsPerPage);
  },

  methods: {
      open: function(){
        this.plan = {name: null, sku: '', description: ''};
        this.dialog = true;
      },
      copy: async function(item){
        await this.$copyText(item);
        this.snackbar = true;
      },
      remove: function(id, dialog) {
        
        if (dialog){
          this.removeDialog.show = true;
          this.removeDialog.id = id;
        } else {

          //this.loading.removing = true;
          this.removeDialog.loading = true;

          axios
            .delete(`/plans/${id}`)
            .then(e => {
                this.removeDialog.show = false;
                this.reload();
            })
            .catch(e => {

            })
            .finally(e => {
              this.removeDialog.loading = false;
            });

        }
      },
      save: function() {

        this.saving = true;
        axios
          .post(`/plans`, this.$data.plan)
          .then(e => {
            this.$dialog.notify.info('Plano criado!', {
              position: 'top-right',
              //outlined: true,
              timeout: 2000
            });
            this.dialog = false;
            this.reload();
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              //outlined: true,
              timeout: 5000
            });
          })
          .finally(e => {
            this.saving = false;
          });

      },
      debounce: _.debounce(function () { 
        this.reload();
      }, 500),
      reload: function(){
        var name = this.name;
        var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(name, start, limit, sort);
      },
      load: function(name, start, limit, sort){
        this.loading = true;

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/plans?version=1&name=${name}&start=${start}&limit=${limit}${filter('sort', sort)}`)
          .then(e => {
            this.entries = e.data.data;
            this.total = e.data.total;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              //outlined: true,
              timeout: 5000
            });
          })
          .finally(e => {
            this.loading = false;
          });
      },

  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem('plans', JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
 
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>