

import Vue from "vue";
import jwtDecode from 'jwt-decode';
import router from "./router";

//import Home from "./views/Home.vue";

export default Vue.extend({
  name: "App",

  components: {
    //Home
  },

  data: () => ({
    drawer: false,
    mini: false,
    all: [
      { title: "Dashboard", to: "/home", icon: "mdi-view-dashboard" },
      { title: "Verbetes", to: "/entries", icon: "mdi-notebook", method:'GET', path: '/contents/' },
      { title: "Arquivos", to: "/files", icon: "mdi-cloud-upload", method:'POST', path: '/resources/' },
      { title: "Usuários", to: "/users", icon: "mdi-account", method:'GET', path: '/users/' },
      { title: "Comparativos", to: "/compares", icon: "mdi-account-switch", method:'GET', path: '/compares/' },
      { title: "Mensagens", to: "/messages", icon: "mdi-message", method:'GET', path: '/messages/' },
      { title: "Vouchers", to: "/vouchers", icon: "mdi-tag", method:'GET', path: '/vouchers/' },
      { title: "Planos", to: "/plans", icon: "mdi-comment-account", method:'GET', path: '/plans/' },
      { title: "Parcerias", to: "/partnerships", icon: "people", method:'GET', path: '/partnerships/' },
      { title: "Importações", to: "/imports", icon: "mdi-auto-upload", method:'GET', path: '/imports/' },
      { title: "Especialidades", to: "/specialties", icon: "filter_list", method:'GET', path: '/specialties/' },
      { title: "Categorias", to: "/categories", icon: "category", method:'GET', path: '/categories/' },
      { title: "Substâncias", to: "/substances", icon: "mdi-beaker-outline", method:'GET', path: '/substances/' },
      { title: "Banners", to: "/banners", icon: "mdi-newspaper", method:'GET', path: '/banners/' },
      { title: "Direitos Autorais", to: "/copyright", icon: "mdi-file-excel", method:'GET', path: '/copyright/' },
      { title: "Páginas", to: "/pages", icon: "feed", method:'GET', path: '/pages/' },
      { title: "Programa de Indicações", to: "/referrals", icon: "mdi-trophy-award", method:'GET', path: '/referrals/' }
    ],
    items: [{}],
    app: {},
  }),

  methods: {
    open: function() {
      this.$data.drawer = !this.$data.drawer;
    },
    canAccess: function(method: string, path:string){
      if (!method && !path) return true;

      var token = localStorage.getItem('token');
      if (token){
        var decoded : any = jwtDecode(token);
        if (decoded){
          var access = decoded.permissions && decoded.permissions.some((e:any) => e.method.toUpperCase().includes(method) && new RegExp(e.path).test(path)  );
          return access;
        }
      }
      return false;
    }
  },

  computed: {
    hide: function() {
      return this.$route.path === "/login" || this.$route.path === "/register" || this.$route.path === "/privacity" || this.$route.path === "/terms" || this.$route.path === "/authors" || this.$route.path === "/referral_terms" || this.$route.path.includes("/views");
    }
  }, 
  watch: {
    hide: { 
      handler(changed){
        this.app = (localStorage.getItem('app') &&  JSON.parse(localStorage.getItem('app') || '{}')) || (router.push('/login'));
        this.items = this.all.filter((i:any) => this.canAccess(i.method, i.path));
      }, 
      deep: true
    }
  },
  created: function(){
    this.app = (localStorage.getItem('app') &&  JSON.parse(localStorage.getItem('app') || '{}')) /*||  (router.push('/login'));*/
    this.items = this.all.filter((i:any) => this.canAccess(i.method, i.path));
  }
});
