<template>
  <v-text-field
    :type="show ? 'text' : 'password'"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="show = !show"
    :label="label"
    :rules="rules"
    v-model="value"
    @input="update()"
  />
</template>

<script>
export default {
    name: 'password-input',

    props: {
        label: String,
        rules: Array,
    },

    data: () => ({
        show: true,
        value: ""
    }),

    methods: {
        update() {
            this.$emit('input', this.value)
        },
    },

    mounted() {
        setTimeout(() => this.show = false, 500)
    }
};
</script>

<style>
</style>