<template>
  <v-container>
    <v-subheader>DADOS GERAIS</v-subheader>
    <v-form ref="form" v-model="valid" >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="current.name" label="Nome" required></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              height="41"
              label="Especialidades"
              v-model="current.specialty"
              :items="specialties"
              :item-text="item=>item.name"
              :item-value="item=>item.id"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              label="Categorias"
              v-model="current.category"
              :items="categories"
              :item-text="item=>item.name"
              :item-value="item=>item.id"
            >
              <template slot="selection" scope="data">
                <v-list-item-avatar size="20">
                  <img v-bind:src="'http://api.dev.consultamaisrapida.com.br/resources/'+data.item.image"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
              <template slot="item" scope="data">
                <v-list-item-avatar>
                  <img v-bind:src="'http://api.dev.consultamaisrapida.com.br/resources/'+data.item.image"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-subheader>CONHECIDO COMO</v-subheader>
          <v-col cols="12">
            <v-combobox
              v-model="current.synonyms"
              :items="synonyms"
              label="Adicionar sinônimo..."
              chips
              deletable-chips
              multiple
              append-outer-icon="mdi-open-in-new"
              @click:append-outer="open('synonyms')"
          
            ></v-combobox>
          </v-col>          
        </v-row>

        <v-row>
          <v-subheader>PALAVRAS-CHAVE</v-subheader>
          <v-col cols="12">
            <v-combobox
              v-model="current.keywords"
              :items="keywords"
              label="Adicionar palavra-chave..."
              chips
              deletable-chips
              multiple
              append-outer-icon="mdi-open-in-new"
              @click:append-outer="open('keywords')"
            
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-subheader>PÁGINAS RELACIONADAS</v-subheader>
          <v-col cols="12">
            <v-autocomplete
              v-model="current.links"
              :items="links"
              :item-text="item=>item.name"
              :item-value="item=>item.id"
              :loading="loading"
              @update:search-input="searchlinks($event)"
              label="Selecione..."
              multiple
            >
              <template slot="selection" scope="data">
                <v-chip close @click:close="deleteLink(data.item)">
                  <v-avatar left>
                    <img v-bind:src="'http://api.dev.consultamaisrapida.com.br/resources/'+categories.filter(e=>e.id === data.item.category)[0].image"/>
                  </v-avatar>
                  {{data.item.name}}
                </v-chip>
              </template>
              <template slot="item" scope="data">
                <v-list-item-avatar>
                  <img v-bind:src="'http://api.dev.consultamaisrapida.com.br/resources/'+categories.filter(e=>e.id === data.item.category)[0].image"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>

            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-subheader>AUTORES</v-subheader>
          <v-col cols="12">
            <v-autocomplete
              v-model="current.authors"
              :items="authors"
              @update:search-input="searchAuthors($event)"
              :item-text="item=>item.name"
              :item-value="item=>item.id"
              label="Selecione..."
              multiple
            >
              <template slot="selection" scope="data">
                <v-chip close @click:close="deleteAuthor(data.item)">
                  <v-avatar left>
                    <img v-if="data.item.picture" v-bind:src="`${url}/resources/${data.item.picture}`"/>
                    <img v-else src="./../assets/user.png">
                  </v-avatar>
                  {{data.item.name}}
                </v-chip>
              </template>
              <template slot="item" scope="data">
                <v-list-item-avatar>
                    <img v-if="data.item.picture" v-bind:src="`${url}/resources/${data.item.picture}`"/>
                    <img v-else src="./../assets/user.png">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>

            </v-autocomplete>
          </v-col>          
        </v-row>

      </v-container>
    </v-form>
    <br><br>

    <!-- <br><br> -->
    <!-- <v-row align="end">
      <v-col class="text-right">
        <v-btn text width="120px" @click="save()" :disabled="!hasChanged" :loading="this.loading">SALVAR</v-btn>
        <v-btn text width="120px" @click="back()">VOLTAR</v-btn>
      </v-col>
    </v-row> -->

  <v-dialog
         v-model="dialog.show"
         width="500px"
         height="1900px"
         >
         <v-card>
            <v-card-title class="">
               {{dialog.title}}
            </v-card-title>
             <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Aqui os itens são separados por nova linha. Cada novo item adicionado ou removido daqui será alterado no campo original.</p>
             </v-card-text>
            <v-container>
              <v-form v-model="valid" ref="form">
                <v-row class="mx-2">
                    <v-col cols="12">
                          <v-textarea
                            rows="10"
                            label="Itens"
                            v-model="dialog.current"
                            multiline
                            prepend-icon="mdi-calendar-text"
                            />
                    </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
               <v-spacer/>
               <v-btn text :disabled="!hasDialogChanged" @click="dialog.save()" width="100">Salvar</v-btn>
               <v-btn text color="primary" @click="dialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Deletar Verbete</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover o conteúdo, não será mais possível restaurar. Tem certeza que deseja remover este conteúdo?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove()" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
  </v-container>
  
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { mask } from "vue-the-mask";
import { diff } from "deep-diff";

import masker from "vue-the-mask/src/masker";
import { tokens } from "vue-the-mask";

function unmask(masked, mask) {
  return masker(masked, mask, false, tokens);
}

export default {
  directives: {
    mask
  },
  data: () => ({
    valid: false,
    changed: false,
    subscriptions: [],
    
    dialog: { show: false, save: function(){}, title: '', current: [], old: []},
    removeDialog: { show: false, loading: false },

    loading: true,

    old: {},

    current: {
      name: "",
      category: {
        name: ""
      },
      specialty: {
        name: ""
      },
      synonyms: [],
      keywords: [],
      links: [],
      authors: [],
    },

    specialties: [],
    categories: [],
    authors: [],

    synonyms: [],

    keywords: [],

    links: [],
  }),

  computed: {
    hasChanged() {
      var changes = diff(this.old, this.current);
      return !!changes;
    },
    hasDialogChanged() {
      var changes = diff(this.dialog.old, this.dialog.current);
      return !!changes;
    }
  },

  watch: {
    hasChanged: function(changed){
      this.$root.actions.save.disable = !changed;
    },
    valid:function(e) {
      // console.log(e)
    }
  },

  mounted: function() {
    this.$root.title = "Verbete";
    this.$root.show({remove: true, save: true});
    this.$root.actions.save.disable = true;

    //subscribe to action
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.save.event.subscribe(e => {
      this.save();
    }));
      this.$data.subscriptions.push(this.$root.actions.remove.event.subscribe(e => {
      this.removeDialog.show=true;
    }));

    this.fetchInfo();
    this.url = axios.defaults.baseURL;
  },

  methods: {
    open: function(type){
        if (type == 'keywords'){
          this.dialog.show = true;
          this.dialog.title = 'Palavras-chave';
          this.dialog.current = this.current.keywords.join('\n');
          this.dialog.old = this.dialog.current;
          this.dialog.save = ()=> {
            this.current.keywords = this.dialog.current.split('\n');
            this.dialog.show = false;
          }
        } else if (type == 'synonyms') {
          this.dialog.show = true;
          this.dialog.title = 'Conhecido como';
          this.dialog.current = this.current.synonyms.join('\n');
          this.dialog.old = this.dialog.current;
          this.dialog.save = ()=> {
            this.current.synonyms = this.dialog.current.split('\n');
            this.dialog.show = false;
          }
        }
    },
    deleteAuthor(item) {
      var object = this.current.authors.filter(e => e == item.id);
      const index = this.current.authors.indexOf( object[0] )
      this.current.authors.splice(index, 1)
    },

    deleteLink(item) {
      var object = this.current.links.filter(e => e == item.id);
      const index = this.current.links.indexOf( object[0] )
      this.current.links.splice(index, 1)   
    },

    fetchInfo() {
      this.loading = true;
      Promise.all([
        axios.get(`/contents/${this.$route.params.id}?version=4`),
        axios.get(`/categories?limit=1000`),
        axios.get(`/specialties?limit=1000`)
      ])
        .then(e => {
          e[0].data.data.authors = e[0].data.data.authors || []
          e[0].data.data.synonyms = e[0].data.data.synonyms || []
          e[0].data.data.keywords = e[0].data.data.keywords || []
          e[0].data.data.links = e[0].data.data.links || []
          
          this.current = e[0].data.data;
          this.old = this.clone(this.current)

          this.categories = e[1].data.data;
          this.specialties = e[2].data.data;
         
         return Promise.all([
            axios.get(`/contents?id=${this.current.links.join(',')}&limit=1000&empty=true,false`),
            axios.get(`/users?id=${this.current.authors.join(',')}&limit=1000`)
          ])
        })
        .then(e => {
          this.links = e[0].data.data
          this.authors = e[1].data.data
        })
        .catch(e => {})
        .finally(e => {
          this.loading = false;
        });
    },

    searchlinks: _.debounce(function(e) {
      if (e) {
        this.loading = true;
        axios
          .get(`/contents?version=2&search=${e}`)
          .then(e => {
            let remove = e.data.data.filter(e => this.current.links.includes(e.id))
            remove.forEach((i) => e.data.data.splice(e.data.data.indexOf(i), 1))

            remove = this.links.filter(e => !this.current.links.includes(e.id))
            remove.forEach((i) => this.links.splice(this.links.indexOf(i), 1))

            this.links = this.links.concat(e.data.data).map(o => ({id: o.id, name: o.name, category: o.category}))
            let lk = this.links;
          })
          .catch(e => {})
          .finally(() => {
            this.loading = false;
          });
      }
    }, 300),

    searchAuthors: _.debounce(function(e) {
      if (e) {
        this.loading = true;
        axios
          .get(`/users?version=3&name=${e}&limit=20`)
          .then(e => {
            let remove = e.data.data.filter(e => this.current.authors.includes(e.id))
            remove.forEach((i) => e.data.data.splice(e.data.data.indexOf(i), 1))

            remove = this.authors.filter(e => !this.current.authors.includes(e.id))
            remove.forEach((i) => this.authors.splice(this.authors.indexOf(i), 1))

            this.authors = this.authors.concat(e.data.data).map(o => ({id: o.id, name: o.name, category: o.category}))
            let lk = this.authors;
          })
          .catch(e => {})
          .finally(() => {
            this.loading = false;
          });
      }
    }, 300),
    remove: function() {
      //this.loading.removing = true;
      this.removeDialog.loading = true;

      axios
        .delete(`/contents/${this.$route.params.id}`)
        .then(e => {
          this.removeDialog.show = false;
          router.push('/entries');
        })
        .catch(e => {

        })
        .finally(e => {
          this.removeDialog.loading = false;
        });
    },
    save: function() {
      this.$root.actions.save.loading = true;
      var data = this.clone(this.current)

      delete data.id
      delete data.type
      delete data.value
      
      Object.assign(data, {op: 'update'})

      this.old = this.clone(this.current);
      const id = this.current.id

      axios.patch(`/contents/${id}`, data)
        .then(e => {
          this.$dialog.notify.info('Conteúdo salvo com sucesso', {
            position: 'top-right',
            //outlined: true,
            timeout: 2000
          });
      }).finally(()=>{
          this.$root.actions.save.loading = false;
      })
    },

    back: function() {
      router.go(-1);
    },

    edit: function() {
      router.push(`/entries/${this.current.id}/edit`);
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    }
  }, 
  
  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];
    next();
  }
};
</script>
