<template>
  <v-container>
    <div>
    <v-spacer></v-spacer>

      <v-row>
          <v-col cols="12" md="6" class="hidden-sm-and-down">
            <v-select
              height="32"
              label="Categorias"
              clearable
              v-model="category"
              :items="categories"
              :item-text="item=>item.name"
              :item-value="item=>item.id"
            >
              <template slot="selection" scope="data">
                <v-list-item-avatar size="16">
                  <img v-bind:src="`${url}/resources/`+data.item.image"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
              <template slot="item" scope="data">
                <v-list-item-avatar>
                  <img v-bind:src="`${url}/resources/`+data.item.image"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="width: 1%" v-text="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" md="6" class="hidden-sm-and-down">
            <v-select
              height="32"
              label="Conteúdo"
              clearable
              v-model="empty"
              :items="empties"
              :item-text="item=>item.name"
              :item-value="item=>item.value"
            >
            </v-select>
          </v-col>
      </v-row>
   
    </div>
    <br><br>

    <v-data-table
        :loading="loading" 
        loading-text="Aguarde..."
        :headers="headers"
        :items="entries"
        no-data-text="Sem resultados"

        
        :options.sync="options"
        :server-items-length="total"
    
        @click:row="click"

        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        
    >
    <template v-slot:[`item.action`]="{ item }">
      <v-icon small class="mr-2" @click="view(item)">mdi-eye</v-icon>
      <v-icon small class="mr-2" @click="edit(item)">edit</v-icon>
    </template>
    </v-data-table>
  </v-container>
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
const omitDeep = require('omit-deep');
const omitEmpty = require('omit-empty');
import * as storage from '../libs/storage'


import masker from 'vue-the-mask/src/masker';
import { tokens } from 'vue-the-mask'


export default {
  directives:  {
  },
  data: () => ({
    loading: false,
    search: '',
    
    entries: [],
    category: '',
    empty: '',

    empties:[{name:'Vazios', value: true}, {name:'Não vazios', value: false}],
    categories:[],

    options: {
       page: 1,
       itemsPerPage: 10
    },
    total: 0,
    headers: [      
      { text: 'NOME', value: 'name' },
      { text: 'CATEGORIA', width: '180px', value: 'category' },
      { text: 'ESPECIALIDADE', width: '180px', value: 'specialty' },
      { text: 'CONTEÚDO', width: '80px', align: 'left', sortable: false, value: 'empty'},
      { text: 'ID', width: '300px', align: 'left', sortable: false, value: 'id'},
      { text: 'AÇÕES', value: 'action', width: '120px', sortable: false },
    ]
  }),
  
  computed: {
    // hasChanged() {
    // }
  },

  watch: {
    options: {
      async handler () {
        this.reload();
      },
      deep: true
    },
    category: {
      async handler(){
        this.reload();
      }
    },
    empty: {
      async handler(){
        this.reload();
      }
    }
  },

  mounted: function () {
    this.$root.title = "Links";
    this.$data.loading = true;
    this.url = axios.defaults.baseURL;

    Object.assign(this.$data, JSON.parse(storage.getItem('entry.links')));
    
    axios.get(`/categories?limit=1000`)
        .then(e => this.categories = e.data.data)
        .catch(e => {})
        .finally(e => {
          this.loading = false;
        });
    this.reload();
  },

  methods: {
      click: function(item){
        router.push(`/entries/${item.id}/view`);
      },
      reload: function(){
        var category = this.category;
        var empty = this.empty;
        var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(category, empty, start, limit, sort);
      },
      load: function(category, empty, start, limit, sort){
        this.loading = true;

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/contents/${this.$route.params.id}/links?version=2&start=${start}&limit=${limit}${filter('category', category)}${filter('empty', empty)}${filter('sort', sort)}`)
          .then(e => {
            this.entries = e.data.data.map(e => Object.assign(e, {empty: e.empty?'-':'Sim'}));
            this.total = e.data.total;
          })
          .catch(e => {

          })
          .finally(e => {
            this.loading = false;
          });
      },
      edit: function(item) {
        router.push(`/entries/${item.id}/editMore`);
      },
      view: function(item) {
        router.push(`/entries/${item.id}/view`);
      },
      links: function(item) {
        router.push(`/entries/${item.id}/links`);
      }
  },
  beforeRouteLeave(to, from, next){
    storage.setItem('entry.links', omitDeep(JSON.stringify(this.$data), ['headers','empties']));
    next();
  }
};
</script>
// <style lang="scss"> 
  
// </style>