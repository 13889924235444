<template>
   <v-container style="postion:relative">
      <div>
         <v-spacer></v-spacer>
      </div>
      <br><br>
      <v-data-table
         :loading="loading" 
         loading-text="Aguarde..."
         sort-by="created"
         sort-desc="true"
         :headers="headers"
         :items="entries"
         no-data-text="Sem resultados"
         :options.sync="options"
         :server-items-length="total"
         @click:row="openImport"
         :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"   
         >

        <template v-slot:[`item.id`]="{ item }">
          <div class="copyable" v-on:click.stop="copy(item.id)">{{ item.id }}</div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon :disabled="!item.zip" small class="mr-2" @click="download(item.zip)">mdi-cloud-download</v-icon>
          <v-icon :disabled="!item.log" small class="mr-2" @click="download(item.log)"> mdi-file-find</v-icon>
          <v-icon small class="mr-2" color="red" @click="remove(item.id, true)">mdi-delete</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{statuses[item.status]}}</span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <span>{{item.created  | moment("DD/MM/YYYY HH:mm:ss")}}</span>
        </template>
        <template v-slot:[`item.updated`]="{ item }">
          <span>{{item.updated  | moment("DD/MM/YYYY HH:mm:ss")}}</span>
        </template>
         
      </v-data-table>
      <v-snackbar v-model="snackbar" :timeout="2000">
         Id copiado
      </v-snackbar>

      <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Remover Importação</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover a importação, não será mais possível restaurar. Tem certeza que deseja remover este registro de importação?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove(removeDialog.id, false)" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
       <v-dialog v-model="importDialog.show" width="600px">
         <v-card>
            <v-card-title class="">
               Importação
            </v-card-title>
            <v-container>
              <div id="dialog">
               <v-container style="padding: 0px"> 
                  <v-form v-model="valid" style="padding:0px">
                      <v-row class="mx-2" no-gutters>                       
                       
                        <v-col cols="12" style="padding-left:10px;padding-right:10px">
                          <v-file-input show-size counter v-model="oimport.file.resource" :rules="rules.resource" label="Arquivo" @change="onFileChange"></v-file-input>
                        </v-col>
                        <v-col cols="12" style="padding-left:10px;padding-right:10px">
                            <v-select
                              height="32"
                              prepend-icon="mdi-google-circles-group"
                              label="Especialidade"
                              clearable
                              v-model="oimport.specialty"
                              :items="specialties"
                              :item-text="item=>item.name"
                              :item-value="item=>item.id"
                              >
                            </v-select>
                        </v-col>
                        <v-col cols="12" style="padding-left:10px;padding-right:10px">
                            <v-select
                              height="32"
                              prepend-icon="mdi-google-circles-group"
                              label="Categoria"
                              :rules="rules.category"
                              clearable
                              v-model="oimport.category"
                              :items="categories"
                              :item-text="item=>item.name"
                              :item-value="item=>item.id"
                              >
                              <template slot="selection" scope="data">
                                <v-list-item-avatar size="16">
                                    <img v-bind:src="`${url}/resources/`+data.item.image"/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template slot="item" scope="data">
                                <v-list-item-avatar>
                                    <img v-bind:src="`${url}/resources/`+data.item.image"/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title style="width: 1%" v-text="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" style="padding-left:10px;padding-right:10px">
                            <v-select
                              height="32"
                              prepend-icon="mdi-google-circles-group"
                              label="Contrato"
                              :rules="rules.contract"
                              clearable
                              v-model="oimport.contract"
                              :items="contracts"
                              :item-text="item=>item.name"
                              :item-value="item=>item.id"
                              >
                            </v-select>
                        </v-col>
                        <v-col cols="12" :hidden="!oimport.log" style="padding-left:10px;padding-right:10px">
                          <v-textarea
                                    name="input-7-1"
                                    label="Resultado da importação"
                                    readonly
                                    :value="oimport.log"
                                    hint="Hint text"
                                  ></v-textarea>
                        </v-col>

                      </v-row>
                      
                  </v-form>
                </v-container>
              </div>
            </v-container>
            <v-card-actions>
               <v-spacer/>
               <v-btn text :loading="saving" @click="imports()" :disabled="!valid || saving" width="100">Importar</v-btn>
               <v-btn text color="primary" @click="cancel()" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

   </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import * as storage from '../libs/storage'
const { PollUntil } = require('poll-until-promise');

Vue.use(VueLodash)

export default {
  data: () => ({
    
    status: '',
    snackbar: false,

    valid: false,
    dialog: false,
    removeDialog: { show: false, loading: false, id: null },
    importDialog: { show: false, loading: false, id: null },
    saving: false,
    loading: false,
    subscriptions: [],
    statuses: {"1": "Iniciado", "2": "Executando", "3": "Executando", "4": "Finalizado"},


    oimport: {
      
      specialty: '',
      category: '',
      file: {
        resource: [],
        name: '',
      },
    },

    empties:[{name:'Vazios', value: true}, {name:'Não vazios', value: false}],
    types:[{name:'Verbete', value: 1}, {name:'Calculadora', value: 2}],
    categories:[],
    specialties:[],
    contracts:[],



    
    rules: {
      name: [
        v => !!v || 'O nome é obrigatório',
        v => (v && v.length <= 64) || 'O nome deve ter menos de 64 caracteres',
      ],
      category: [
        v => !!v || 'A categoria é obrigatória'
      ],
      specialty: [],
      contract: [
        v => !!v || 'O contrato é obrigatório'
      ],
      type: [
        v => !!v || 'O tipo é obrigatório'
      ],
      value: [
        v => !!v || 'O verbete deve ter conteúdo'
      ],
      resource: [
        v => !!v || 'Arquivo é obrigatório'
      ]
    },    

    entries: [],

    options: {
       page: 1,
       itemsPerPage: 10
    },
    total: 0,
    headers: [      
      { text: 'ID', width: '280px', align: 'left', sortable: false, value: 'id'},
      { text: 'SITUAÇÃO', width: '80px', align: 'left', sortable: false, value: 'status'},
      { text: 'CRIADO', width: '180px', value: 'created', sortable: false },
      { text: 'ATUALIZADO', width: '180px', value: 'updated', sortable: false },
      { text: 'VERBETES', width: '110px', value: 'contents', sortable: false },
      { text: 'IMAGENS', width: '100px', value: 'images', sortable: false },
      { text: 'AÇÕES', value: 'action', width: '120px', sortable: false }
    ]
  }),
  
  watch: {
   
    options: {
      async handler () {
        this.reload();
      },
      deep: true
    }
  },

  mounted: function () {
    this.$root.title = "Importação";
    this.$root.show({create: true});
    this.$root.actions.create.disable = !this.$root.canAccess('POST', '/contents/');
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.create.event.subscribe(e => {
      this.oimport.specialty = '';
      this.oimport.category = '';
      this.oimport.file.resource = [];
      this.oimport.file.name = '';
      this.importDialog.show = true;
    }));

    axios.get(`/categories?limit=1000`)
          .then(e => this.categories = e.data.data)
          .catch(e => {})
          .finally(e => {
            this.loading = false;
          });
    axios.get(`/specialties?limit=1000`)
          .then(e => this.specialties = e.data.data)
          .catch(e => {})
          .finally(e => {
            this.loading = false;
          });
    axios
      .get(`/contracts?limit=1000`)
      .then(e => this.contracts = e.data.data)
      .catch(e => {})
      .finally(e => {});

    Object.assign(this.$data, pick(JSON.parse(storage.getItem('imports'))  || {},['status', 'options']));
    this.load(this.$data.status, 0, this.$data.options.itemsPerPage);
  },

  methods: {
      copy: async function(item){
        await this.$copyText(item);
        this.snackbar = true;
      },
      remove: function(id, dialog) {
        
        if (dialog){
          this.removeDialog.show = true;
          this.removeDialog.id = id;
        } else {

          //this.loading.removing = true;
          this.removeDialog.loading = true;

          axios
            .delete(`/imports/${id}`)
            .then(e => {
                this.removeDialog.show = false;
                this.reload();
            })
            .catch(e => {

            })
            .finally(e => {
              this.removeDialog.loading = false;
            });

        }
      },
      debounce: _.debounce(function () { 
        this.reload();
      }, 500),
      reload: function(){
        var status = this.status;
        var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(status, start, limit, sort);
      },
      load: function(status, start, limit, sort){
        this.loading = true;

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/imports?version=2&status=${status}&start=${start}&limit=${limit}${filter('sort', sort)}`)
          .then(e => {
            this.entries = e.data.data;
            this.total = e.data.total;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              //outlined: true,
              timeout: 5000
            });
          })
          .finally(e => {
            this.loading = false;
          });
      },
      onFileChange: function(event){
        this.oimport.file.name = this.oimport.file.name || event.name;
      },
      cancel: function(){
          this.oimport.continue = true;
          this.oimport.log = '';
          this.importDialog.show = false;
          this.oimport.specialty = '';
          this.oimport.category = '';
          this.oimport.contract = '';
          this.oimport.file.resource = [];
          this.oimport.file.name = '';

      },

      openImport(item) {
        this.$router.push(`imports/${item.id}`)
      },

      imports: function(){
        var form = new FormData();
        form.append("resource", this.oimport.file.resource);
        form.append("name", "IMPORTAÇÃO - " + moment().format("YYYYMMDD-HHmm"));

        var app = (localStorage.getItem('app') && JSON.parse(localStorage.getItem('app') || '{}')) || (router.push('/login'));
        
        var obj = {
          "input": "word",
          "method": "post",
          "algorithm": "recursive",
          "output": "html",
          "isolation": "serial",
          "import": uuid(),
          "zip": uuid(),
          "log": uuid(),
          "app": app.code,
          "contract": this.oimport.contract,
          "category": this.oimport.category,
          "specialty": this.oimport.specialty
        };
        
        var poll = null;
        this.$data.saving = true;
        axios.put('resources/' + obj.zip, form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then(e => axios.post('importer', obj))
        .then(e => {

            poll = new PollUntil();
            poll
                .stopAfter(60 * 1000)    // Stop trying after 60 seconds
                .tryEvery(1000)          // Tries every 100ms (from the last failure)
                .execute(() => axios.get("/imports/" + obj.import))
                .then(value => {
                  this.saving = false;
                  this.importDialog.show = false;
                  this.reload();
                })
                .catch(err => console.error(err));
            

                //this.reload();           
        })
        .finally(e => {
            
        });
      },
      download: async function(item) {
        var win = window.open(`${axios.defaults.baseURL}/resources/${item}`, '_blank');
        win.focus();
      },

  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem('imports', JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
 
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>