<template>
  <v-container style="postion: relative">
    <div>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="filters.name"
            prepend-icon="mdi-magnify"
            label="Pesquisar por nome..."
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="filters.slug"
            label="Pesquisar por slug..."
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <br /><br />
    <v-data-table
      :loading="loading"
      loading-text="Aguarde..."
      :headers="headers"
      :items="pages"
      @click:row="click"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
      }"
    >
      <template v-slot:[`item.users`]="{ item }">
        <span>{{ item.failed ? `${item.succeed}/${item.sent}` : "-" }}</span>
      </template>

      <template v-slot:[`item.slug`]="{ item }">
        <div class="copyable" v-on:click.stop="$root.copy(item.slug)">{{ item.slug }}</div>
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        <span>{{ item.updated | moment("DD/MM/YYYY HH:mm") }}</span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <div class="copyable" v-on:click.stop="$root.copy(item.id)">{{ item.id }}</div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click.stop="preRemove(item)"
          color="error"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="2000"> Id copiado </v-snackbar>

    <v-dialog v-model="dialog" >
      <page-create v-on:close="dialog = false" v-on:create="created()" :key="$root.componentKey"></page-create>
    </v-dialog>

    <v-dialog v-model="removeDialog.show" width="fit-content" maxWidth="600px">
      <v-card>
        <v-card-title>Excluir Página</v-card-title>

        <v-card-text>Tem certeza que deseja excluir a página <strong>{{ removeDialog.page.name }}</strong>?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="remove(removeDialog.page.id)" :loading="removeDialog.loading">SIM</v-btn>
          <v-btn text @click="removeDialog.show = false">NÃO</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import pick from "object.pick";
import * as storage from "../libs/storage";
import PageCreate from '../components/Page.Create';

Vue.use(VueLodash);

export default {
  components: {
    PageCreate
  },

  data: () => ({
    loading: false,
    loadingCategories: false,
    loadingContents: false,

    removeDialog: {
      show: false,
      page: {},
      loading: false
    },

    pages: [],
    title: "",
    snackbar: false,
    valid: false,
    dialog: false,
    subscriptions: [],

    search: {
      content: "de",
    },

    options: {
      page: 1,
      itemsPerPage: 10,
    },

    filters: {
      name: "",
      slug: "",
    },

    total: 0,
    headers: [
      { text: "NOME", value: "name", sortable: false },
      { text: "SLUG", value: "slug", sortable: false },
      { text: "ATUALIZADO", width: "180px", value: "updated", sortable: true },
      { text: "ID", width: "300px", value: "id", sortable: true },
      { text: "AÇÕES", width: "90px", value: "actions", sortable: false },
    ],
  }),

  watch: {
    options: {
      async handler() {
        this.load();
      },
      deep: true,
    },

    dialog: function () {
      this.$root.componentKey++;
    },

    filters: {
      deep: true,
      handler: _.debounce((function(value) {
        console.log(value)
        this.load()
      }), 300)
    },
  },

  mounted: function () {
    this.$root.title = "Páginas";
    this.$root.show({ create: true });
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.forEach((e) => e.unsubscribe());
    this.$data.subscriptions.push(
      this.$root.actions.create.event.subscribe((e) => {
        this.dialog = true;
      })
    );

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("pages")) || {}, ["filters", "options"])
    );
    this.load();
  },

  methods: {
    copy: async function (item) {
      await this.$copyText(item.id);
      this.snackbar = true;
    },
    click: function (item) {
      router.push(`/pages/${item.id}`);
    },
    debounce: _.debounce(function () {
      this.load();
    }, 500),
    load: function () {
      var sort = this.options.sortBy
        .filter((e) => !!e)
        .map((e, i) => `${this.options.sortDesc[i] ? "-" : ""}${e}`)
        .join(",");

      var limit = this.options.itemsPerPage;
      var start = (this.options.page - 1) * limit;
      this.loading = true;

      var filter = function (field, value) {
        return value === null || value === undefined
          ? ""
          : "&" + field + "=" + value;
      };

      axios
        .get(`/pages?start=${start}&limit=${limit}&name=${this.filters.name || ""}&slug=${this.filters.slug || ""}&${filter("sort", sort)}`)
        .then((e) => {
          this.pages = e.data.data;
          this.total = e.data.total;
        })
        .catch((e) => {})
        .finally((e) => {
          this.loading = false;
        });
    },
    created: function() {
      this.dialog = false
      this.$root.openSnackbar('Página criada com sucesso!')
      this.load()
    },
    upload: function (page) {
      this.$data.saving = true;
      axios
        .post("pages/", page)
        .then((e) => {
          this.dialog = false;
          this.load();
        })
        .finally((e) => {
          this.saving = false;
        });
    },
    typeChange: function () {
      this.page.value = "";
    },

    preRemove: function(item) {
      this.removeDialog.show = true
      this.removeDialog.page = item
    },

    remove: function(page) {
      this.removeDialog.loading = true

      axios
        .delete(`pages/${page}`)
        .then(() => {
          this.$root.openSnackbar('Página excluída com sucesso.')
        })
        .finally(() => {
          this.removeDialog.show = false
          this.removeDialog.loading = false
          this.load()
        })
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach((e) => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("pages", JSON.stringify(this.$data));
    next();
  },
};
</script>
<style lang="scss">
.copyable {
  &:hover {
    color: rebeccapurple;
    cursor: pointer;
  }
}
</style>