import * as root from '../../../../main'

const contentLink = function (editor) {
  editor.ui.registry.addButton('contentLink', {
    text: 'Link Conteúdo',
    icon: 'link',
    onAction: () => activateLink(),
  });

  editor.ui.registry.addMenuItem('contentLink', {
    text: 'Link Conteúdo...',
    icon: 'link',
    onAction: () => activateLink()
  });

  editor.ui.registry.addContextMenu('contentLink', {
    update: (e) => !e ? '' : 'contentLink'
  });
}

function activateLink() {
  root.default.tinyMCE.customLink = true
}

export {
  contentLink
};