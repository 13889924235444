<template>
  <v-container style="height: 100%">

    <v-card v-if="loader.opening">
      <div style="display:flex; justify-content: flex-start">
        <v-skeleton-loader
          v-for="i in 16"
          :key="i"
          style="margin: 2px; width: 45px"
          :loading="true"
          :transition="'fade-transition'"
          type="button">
        </v-skeleton-loader>
      </div>

      <v-divider></v-divider>

      <!-- <v-skeleton-loader
        style=""
        :loading="true"
        :transition="'fade-transition'"
        type="card">
      </v-skeleton-loader> -->

      <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="article">
      </v-skeleton-loader>
      
      
      <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="article">
      </v-skeleton-loader>
       <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="article">
      </v-skeleton-loader>
      <br>
      <br>
      <div style="display:flex;isplay: flex;justify-content: flex-end">
        <v-skeleton-loader
            width="20%"
            :loading="true"
            :transition="'fade-transition'"
            type="text">
        </v-skeleton-loader>
      </div>
      <br>
      <br>
      <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="sentences">
      </v-skeleton-loader>
      <br>
      <br>

      <div style="display:flex; justify-content: flex-end">
          <v-skeleton-loader
            style="margin: 5px"
            :loading="true"
            :transition="'fade-transition'"
            type="button">
          </v-skeleton-loader>
          <v-skeleton-loader
            style="margin: 5px"
            :loading="true"
            :transition="'fade-transition'"
            type="button">
          </v-skeleton-loader>
      </div>
      <br>
      <br>
    </v-card>

    <div v-show="!loader.opening" style="height:100%">
    <editor
      style="height: calc(100% - 100px)"
      ref="editor"
      api-key="no-api-key"
      v-on:input="onChange"
      :disabled="loader.saving"
      :initial-value="user.curriculum"
      :init="{
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
          alignleft aligncenter alignright alignjustify | table image | \
          bullist numlist outdent indent | removeformat | help',
        images_upload_url: 'postAcceptor.php'
       }"
       >
    </editor>
    <br>

    <v-row align="end">
      <v-col class="text-right">
        <v-btn :disabled="!changed" :loading="loader.saving" text width="120px" @click="save()">SALVAR</v-btn>
        <v-btn text width="120px" @click="back()">VOLTAR</v-btn>
      </v-col>
    </v-row>  
    <br>
    </div>

  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import Editor from '@tinymce/tinymce-vue'

import VueLodash from 'vue-lodash'

Vue.use(VueLodash)

export default {
  components:  {
    'editor': Editor
  },
  data: () => ({
    user: {},
    previous: '',
    changed: false,
    loader: {
      opening: false,
      loading: true,
      saving: false,
    }
  }),
  
  computed: {},

  watch: {},

  mounted: function () {
    this.$root.title = "Verbete";
    this.loader.opening = true;

    axios
      .get(`/users/${this.$route.params.id}`)
      .then(e => {
        this.user = e.data.data;
      })
      .catch(e => {

      })
      .finally(e => {
        this.loader.opening = false;

        tinyMCE.activeEditor.setContent(this.user.curriculum)
        this.previous = tinyMCE.activeEditor.getContent({format: 'raw'})
        // tinyMCE.activeEditor.on('change', function(e) {console.log('changed!')})
      });
  },

  methods: {
    back: function(){
      router.go(-1);
    },

    onChange: _.debounce(function (e) {
      this.changed = !(this.previous == tinyMCE.activeEditor.getContent({format: 'raw'}))
    }, 500),

    save: function() {

      this.loader.saving = true;
          axios
      .patch(`/users/${this.$route.params.id}`, {op:'update', curriculum:tinyMCE.activeEditor.getContent({format:'raw'})})
      .then(e => {
        this.previous = tinyMCE.activeEditor.getContent({format: 'raw'});
        this.changed = false;
      })
      .catch(e => {

      })
      .finally(e => {
        this.loader.saving = false;

      });

    }
  }
};
</script>

<style lang="scss">

  #curriculum{
    .v-skeleton-loader__image{
      height: 80vh !important;
    }

    .tox-tinymce {
      height: calc(100% - 100px) !important;
    }
  }

</style>
