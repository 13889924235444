<template>
   <v-container>
      <h2>Consulta mais Rápida</h2>
      <br><br>
      <p>
         O conteúdo se origina de 4 livros da mesma coleção (Consulta Rápida) e com um autor em comum (Dr. Elvino) e outro principal (Dr. Stephen).
      </p>
      <p>
         Clínica Médica
         Medicamentos de A-Z
         Laboratório
         Sinais e Sintomas
         Do nome da coleção nasce o nome do projeto Consulta Mais Rápida (C+R).
      </p>
      <p>
         Os livros são um grande sucesso editorial. E existe um app concorrente de sucesso, Whitebook. Daí nasce o que deve ser oferecido na primeira versão, conforme adiante.
      </p>
      <br>
      <br>
      <h3>Índice</h3>
      <br>
      <p>
         A tela de índice é implementada por uma TableView simples, com agrupamento (Sections).A API dirá se existe agrupamento (ex: especialidade x doenças). Possui 4 índices, sendo que cada índice corresponde a um conteúdo específico, do livro que lhe deu origem:
         Especialidade / Doença (Clínica Médica)
         Medicamento
         Laboratório
         Sintoma
      </p>
      <p>
         Eventualmente poderá existir uma ordenação geral das doenças, e outra agrupada por especialidade (a definir no mock).
      </p>
      <p>
         Deverá existir um botão para troca de índice.
      </p>
      <p>
         Ao clicar numa página, navegar para o conteúdo selecionado (ex: "Diabetes")
      </p>
      <p>
         Usar elemento gráfico (cor/ícone) para mostrar qual o índice (doença / medicamento / laboratório / sintoma).
      </p>
      <p>
         Incluir um componente de índice A...Z, como a lista de contatos do iPhone, quando a lista for alfabética.
      </p>
      <p>
         Levar em conta que em implementações posteriores podem ser incluídas novos tipos de página.
         Busca
         Campo de busca textual (TextEdit), ligado a uma TableView e um índice como o app de contatos da Apple. (UISearchController?)
      </p>
      <p>
         O resultado da busca deve conter uma indicação visual de sua origem/tipo/índice (doença / medicamento / laboratório / sintoma).
      </p>
      <p>
         Deve conter botões ou CollectionView que permita filtrar o resultado de acordo com a origem/tipo (doença/medicamento/laboratório/sintoma)
      </p>
      <p>
         O MVP terá uma lista pré-definida (lista de strings) contendo o conteúdo padrão de cada um dos quatro tipos de página. Por exemplo:
         Doença: Definição, Classificação, Quadro Clínico, Diagnóstico, Tratamento
         Medicamento: Grupo farmacológico, Nomes comerciais, Apresentação, Especto, Usos, Contraindicações, Posologia, Modo de administração, Interações Medicamentosas, Conservação e preparo, etc.
      </p>
      <p>
         Permitir que essas listas possam ser alteradas diretamente no código do app.
      </p>
      <p>
         Se o usuário usar um termo adicional de busca igual a um termo da lista da estrutura da página, deixar isso marcado no resultado de busca, ou quando clicar, navegar diretamente para aquele conteúdo específico.
      </p>
      <p>
         Quando clicar num resultado de busca, navegar para o conteúdo da página.
      </p>
      <br>
      <br>
      <h3>Página</h3>
      <br>
      <p>
         A página contém o conteúdo de um verbete, ou seja, uma doença / medicamento / laboratório / sintoma.
      </p>
      <p>
         Deixar claro através de cor ou ícone qual o tipo de conteúdo
      </p>
      <p>
         No topo da tela colocar uma collection view permitindo a exibição de um dos conteúdos padrão da página
         Conteúdo (texto markdown)
         Doenças (índice local)
         Exames (índice local)
         Medicamentos (índice local)
         Sintomas (índice local)
         Autores (índice local)
      </p>
      <p>
         Abaixo da collection view existem dois componentes que se alternam na exibição, conforme a seleção.
      </p>
      <p>
         O conteúdo, que é um texto markdown é exibido de forma colapsável. O título da parte colapsável é o que estiver marcado como # Heading 1 do markdown. E a parte a ser exibida é tudo que estiver abaixo do H1, até o próximo H1.
      </p>
      <p>
         Apenas como exemplo: o protótipo implementado anteriormente foi feito com uma TableView que tem N sections (uma para cada H1). Ao clicar na section header, é então "criada" dinamicamente uma célula para exibição. 
      </p>
      <p>
         Os índices locais são implementados numa table view que aponta diretamente para cada página listada.
      </p>
      <p>
         Os índices locais fazem parte do conteúdo da página enviado pela API.
      </p>
      <p>
         Os autores podem ser modelados como um tipo de página adicional. 
      </p>
      <p>
         A definir no Mock: Podemos deixar de lado os autores neste MVP.
      </p>
      <p>
         O conteúdo markdown terá os marcadores descritos no início deste documento
      </p>
      <p>
         Os links podem ser externos ou internos. Formato a definir durante esta implementação
      </p>
      <p>
         O App deve renderizar cada marcador corretamente, de acordo com o design a ser criado
      </p>
      <p>
         Observação: esse markdown foi adaptado para nossa plataforma
      </p>
      <p>
         Decoradores talvez não sejam necessários (foram concebidos mas seu uso tem sido descartado até esse momento).
      </p>
      <p>
         O click do link sobre o texto deve funcionar (para outro verbete/página ou para outro aplicativo/URL), navegando para onde necessário.
      </p>
      <p>
         As páginas contém alguns tipos de conteúdo especial:
         Tabelas
         Figuras
         Quadros
      </p>
      <p>
         A definir no MVP: A API pode devolver tudo junto na página
      </p>
      <p>
         Cada um desses é modelado como uma página (ou conteúdo em separado) no banco de dados.
      </p>
      <p>
         A indicação de um conteúdo pode ser feita da seguinte forma, com um botão, com uma formatação do tipo abaixo. Ao se clicar, irá para tela especial com o elemento indicado.
      </p>
   </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';

export default {
  data: () => ({
  }),

  mounted: function () {
    this.$root.title = "Dashboard";
  },

  methods: {
    
  }
};
</script>
