import Vue, { PluginObject } from "vue"; 

// export function PluginFunction<T>(Vue: typeof _Vue, options?: any): void {
//     Vue.prototype.$codes = function(object: any){
//         alert("OPS!");
//     }
// }

// type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;

// interface PluginObject<T> {
//   install: PluginFunction<T>;
//   [key: string]: any;
// }

const messages :{ [key: number]: string } = { 
1000: "version not found",
1001: "refresh token not found or token is not valid anymore",
1002: "date not valid",
1003: "access token is not valid",
1004: "access token expired",
1005: "operation is not valid",
1006: "username not supplied",
1007: "password not supplied",
1008: "username or password invalid",
1009: "username not found - it should be found... it was authenticated milliseconds ago",
1010: "token not supplied",
1011: "source not supplied",
1012: "version not found",
1013: "error retrieving the user",
1014: "not possible to create the refresh token",
1015: "unexpected error",
1016: "email not supplied",
1017: "password too short",
1018: "id not supplied",
1019: "authentication error - token missing",
1020: "resource not found",
1021: "email not supplied",
1022: "operation not supplied",
1023: "id not valid",
1024: "filter name not supplied",
1025: "filter id not supplied",
1026: "user not found",
1027: "social network access token not supplied",
1028: "authentication error",
1029: "no name returned - did you give the right scope?",
1030: "no email returned - did you give the right scope?",
1031: "duplicated username",
1032: "alias for the current source already exists", 
1033: "duplicated id",
1034: "type not supplied",
1035: "type not valid",
1036: "name not supplied",
1037: "value not supplied",
1038: "source id not supplied",
1039: "source name not supplied",
1040: "category not supplied",
1041: "category not valid",
1042: "specialty not supplied",
1043: "specialty not valid",
1044: "slug not supplied",
1045: "slug not valid",
1046: "username not valid",
1047: "password not valid",
1048: "author not valid",
1049: "synonym not valid",
1050: "link not valid",
1051: "name not valid",
1052: "content not found",
1053: "keyword not valid",
1054: "user token not supplied",
1055: "content not supplied",
1056: "content not valid",
1057: "favorite not found",
1058: "duplicated favorite",
1059: "user token not valid",
1060: "category not found",
1061: "specialty not found",
1062: "color not supplied",
1063: "color not valid",
1064: "weight not supplied",
1065: "weight not valid",
1066: "image not supplied",
1067: "image not valid",
1068: "search not supplied",
1069: "search not valid",
1070: "picture not valid",
1071: "specialties not valid",
1072: "active not valid",
1073: "specialties not supplied",
1074: "active not supplied",
1075: "author name not supplied",
1076: "author name not valid",
1077: "author type not supplied",
1078: "author type not valid",
1079: "address not supplied",
1080: "profession not valid",
1081: "region not valid",
1082: "premium not valid",
1083: "user not supplied",
1084: "user not valid",
1085: "token not supplied",
1086: "token not valid",
1087: "duplicated token",
1088: "device not valid",
1089: "identifier not valid",
1090: "version not valid",
1091: "timezone not valid",
1092: "locale not valid",
1093: "badge not valid",
1094: "title not supplied",
1095: "title not valid",
1096: "messasge not found",
1097: "plan not valid",
1098: "voucher not found",
1099: "description not supplied",
1100: "description not valid",
1101: "code not supplied",
1102: "code not valid",
1103: "expiresAt not supplied",
1104: "expiresAt not valid",
1105: "endsAt not supplied",
1106: "endsAt not valid",
1107: "limit not supplied",
1108: "limit not valid",
1109: "limit reached",
1110: "created not valid",
1111: "duplicated code",
1112: "voucher expired",
1113: "renewAt not valid"
};

class CodesPlugin implements PluginObject<any>{

    install = (_Vue: typeof Vue, options?: any) => {
        
        _Vue.prototype.$codes = function(object: any){
            var message = "";
            if (object){
                message = messages[object.code] || (object.detail && object.detail.message) || "nenhuma informação disponível";
            }
            return message
        }  
    };
    [key: string]: any;    
}

const plugin = new CodesPlugin;
Vue.use(plugin);
