<template>
  <v-container style="postion:relative">
    <div>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="title"
            prepend-icon="mdi-magnify"
            label="Busca"
            v-on:input="debounce"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <br /><br />
    <v-data-table
      :loading="loading"
      loading-text="Aguarde..."
      :headers="headers"
      :items="messages"
      @click:row="click"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.users`]="{ item }">
        <span>{{ item.failed? `${item.succeed}/${item.sent}`: '-' }}</span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span>{{ item.date | moment("DD/MM/YYYY HH:mm:ss") }}</span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <div class="copyable" v-on:click.stop="copy(item)">{{ item.id }}</div>
      </template>
    </v-data-table>
    
    <v-snackbar v-model="snackbar" :timeout="2000">
      Id copiado
    </v-snackbar>

    <v-dialog v-model="dialog" width="600px">
      <message-create :key="$root.componentKey" v-on:close="dialog = false" v-on:save="upload($event)"></message-create>
    </v-dialog>
  </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import pick from "object.pick";
import * as storage from "../libs/storage";
import config from '../plugins/tinymce/config/config'
import Editor from '@tinymce/tinymce-vue'
import MessageCreate from '../components/Message.Create'

Vue.use(VueLodash);

export default {
  components:  {
    'message-create': MessageCreate
  },

  data: () => ({
    loading: false,
    loadingCategories: false,
    loadingContents: false,

    messages: [],
    title: "",
    snackbar: false,
    valid: false,
    dialog: false,
    subscriptions: [],

    search: {
      content: "de"
    },

    options: {
      page: 1,
      itemsPerPage: 10
    },
    
    total: 0,
    headers: [
      { text: "TÍTULO", value: "title", sortable: false },
      {
        text: "ID",
        width: "320px",
        align: "left",
        sortable: false,
        value: "id"
      },
      { text: "USUÁRIOS", width: "120px", value: "users", sortable: false },
      { text: "DATA", width: "240px", value: "date", sortable: true }
    ]
  }),

  watch: {
    options: {
      async handler() {
        this.reload();
      },
      deep: true
    },

    dialog: function() {
      this.$root.componentKey ++
    }
  },

  mounted: function() {
    this.$root.title = "Mensagens";
    this.$root.show({ create: true });
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(
      this.$root.actions.create.event.subscribe(e => {
        this.dialog = true;
      })
    );

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("messages")) || {}, ["title", "options"])
    );
    this.load(this.$data.title, 0, this.$data.options.itemsPerPage);
  },

  methods: {
    copy: async function(item) {
      await this.$copyText(item.id);
      this.snackbar = true;
    },
    click: function(item) {
      router.push(`/messages/${item.id}`);
    },
    debounce: _.debounce(function() {
      this.reload();
    }, 500),
    reload: function() {
      var title = this.title;
      var sort = this.options.sortBy
        .filter(e => !!e)
        .map((e, i) => `${this.options.sortDesc[i] ? "-" : ""}${e}`)
        .join(",");

      var limit = this.options.itemsPerPage;
      var start = (this.options.page - 1) * limit;

      this.load(title, start, limit, sort);
    },
    load: function(title, start, limit, sort) {
      this.loading = true;

      var filter = function(field, value) {
        return value === null || value === undefined
          ? ""
          : "&" + field + "=" + value;
      };

      axios
        .get(
          `/messages?version=4&title=${title}&start=${start}&limit=${limit}${filter(
            "sort",
            sort
          )}`
        )
        .then(e => {
          this.messages = e.data.data;
          this.total = e.data.total;
        })
        .catch(e => {})
        .finally(e => {
          this.loading = false;
        });
    },
    upload: function(message) {
      this.$data.saving = true;
      axios
        .post("messages/", message)
        .then(e => {
          this.dialog = false;
          this.reload();
        })
        .finally(e => {
          this.saving = false;
        });
    },
    typeChange: function() {
      this.message.value = "";
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("messages", JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
.copyable {
  &:hover {
    color: rebeccapurple;
    cursor: pointer;
  }
}
</style>