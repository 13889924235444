<template>
  <v-container style="position='relative'">
    <v-form v-model="form">
      <v-row>
        <v-col cols="3">
          <v-text-field label="Código" v-model="voucher.code" />
        </v-col>
        <v-col>
          <v-text-field readonly label="ID" v-model="voucher.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-select
            label="Tipo"
            v-model="voucher.type"
            :items="types"
            item-text="name"
            item-value="value"
            :rules="rules.required"
          ></v-select>
        </v-col>

        <v-col cols="3">
          <v-menu
            v-if="voucher.type == 1"
            v-model="dateEnds"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="Usado até"
                prepend-icon="event"
                readonly
                v-on="on"
                v-on:focus="date1 = true"
                clearable
                persistent-hint
                :value="ends"
              />
            </template>
            <v-date-picker
              v-model="voucher.endsAt"
              no-title
              @input="dateEnds = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            v-else
            type="number"
            prepend-icon="timer"
            label="Tempo de uso"
            v-model="ends"
            suffix="dias"
          />
        </v-col>

        <v-col>
          <v-menu
            v-model="dateExpires"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="Expira em"
                prepend-icon="event"
                readonly
                v-on="on"
                v-on:focus="date1 = true"
                clearable
                persistent-hint
                :value="expires"
              />
            </template>
            <v-date-picker
              v-model="voucher.expiresAt"
              no-title
              @input="dateExpires = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-text-field
            label="Limite"
            type="number"
            v-model="voucher.limit"
            prepend-icon="mdi-tag-multiple"
            autocomplete="off"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            counter="2048"
            label="Descrição"
            v-model="voucher.description"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-subheader>USUÁRIOS</v-subheader>

    <v-data-table
      :loading="loadingTable"
      loading-text="Aguarde..."
      :headers="headers"
      :items="users"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.picture`]="{ item }">
        <v-avatar left size="32">
          <img
            v-if="item.picture"
            v-bind:src="`${url}/resources/${item.picture}`"
          />
          <img v-else src="./../assets/user.png" />
        </v-avatar>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span>{{ _formatDate(item.date) }}</span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <span class="copyable" @click="copy(item.id)">{{ item.id }}</span>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbars.copy"> Texto copiado! </v-snackbar>
    <v-snackbar v-model="snackbars.edit"> Voucher alterado! </v-snackbar>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import pick from "object.pick";
import moment from "moment";
import * as storage from "../libs/storage";
import { diff } from "deep-diff";

Vue.use(VueLodash);

export default {
  data: () => ({
    form: false,
    loading: false,
    loadingTable: false,
    total: 0,
    snackbars: {
      copy: false,
      edit: false
    },

    dialogs: {
      create: false
    },

    users: [],

    options: {
      itemsPerPage: 5
    },

    types: [
      { value: 1, name: "Duração Fixa" },
      { value: 2, name: "Duração Relativa" }
    ],

    subscriptions: [],
    dateEnds: false,
    dateExpires: false,

    headers: [
      {
        text: "#",
        value: "picture",
        width: "60px",
        align: "left",
        sortable: false
      },
      { text: "NOME", value: "name", sortable: false },
      { text: "DATA DE USO", value: "date", width: "240px", sortable: false },
      { text: "ID", value: "id", width: "320px", sortable: false }
    ],

    rules: {
      required: [v => !!v || "Campo obrigatório."]
    },

    voucher: {},
    old: {}
  }),

  watch: {
    options: {
      async handler() {
        this.load();
      },
      deep: true
    },

    hasChanged: {
      deep: true,
      handler(changed) {
        this.$root.actions.save.disable = !changed || !this.form;
      }
    }
  },

  computed: {
    expires: {
      get() {
        return moment(this.voucher.expiresAt).format("DD/MM/YYYY");
      },
      set(val) {
        this.voucher.expiresAt = val;
      }
    },

    ends: {
      get() {
        if (this.voucher.type == 1)
          return moment(this.voucher.endsAt).format("DD/MM/YYYY");
        else if (this.voucher.type == 2) {
          return `${this.voucher.days || "0"}`;
        }

        return "";
      },
      set(val) {
        if (this.voucher.type == 1) this.voucher.endsAt = val;
        else if (this.voucher.type == 2) this.voucher.days = val;
      }
    },

    hasChanged() {
      return !!diff(this.old, this.voucher);
    }
  },

  mounted: function() {
    this.$root.title = "Voucher";
    this.$root.show({ create: false });
    this.url = axios.defaults.baseURL;

    this.$root.show({ save: true });
    this.subscriptions.forEach(e => e.unsubscribe());
    this.subscriptions.push(
      this.$root.actions.save.event.subscribe(e => {
        this.save();
      })
    );

    this.load(0, this.options.itemsPerPage);
  },

  methods: {
    open: function() {
      this.dialogs.create = true;
    },
    copy: async function(item) {
      await this.$copyText(item);
      this.snackbars.copy = true;
    },
    debounce: _.debounce(function() {
      this.load();
    }, 500),

    load: function() {
      var limit = this.options.itemsPerPage || 10;
      var start = (this.options.page - 1) * limit || 0;

      this.loading = true;
      this.loadingTable = true;

      const id = this.$route.params.id;
      axios
        .get(`/vouchers/${id}?version=1`)
        .then(e => {
          this.old = e.data.data;
          this.voucher = this.clone(this.old);
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.loading = false;
        });

      axios
        .get(`/vouchers/${id}/usage?version=2&start=${start}&limit=${limit}`)
        .then(e => {
          this.users = e.data.data;
          this.total = e.data.total;
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.loadingTable = false;
        });
    },

    _formatDate(date) {
      return moment(date).format("DD/MM/YYYY - H:mm");
    },

    save() {
      this.$root.actions.save.loading = true;
      let keys = diff(this.voucher, this.old);
      keys = keys && keys.map(e => e.path);

      if (keys) {
        const obj = { op: "update" };

        keys.forEach(e => {
          Object.assign(obj, { [e]: this.voucher[e] });
        });

        if (obj.expiresAt)Object.assign(obj, {expiresAt: moment(obj.expiresAt).toISOString()});
        if (obj.endsAt) Object.assign(obj, {endsAt: moment(obj.endsAt).toISOString()});

        axios
          .patch(`vouchers/${this.voucher.id}`, obj)
          .then(res => {
            this.snackbars.edit = true;
            this.load();
          })
          .catch(err => {
            this.$dialog.notify.error(this.$codes(err), {
              position: "top-right",
              timeout: 5000
            });
          })
          .finally(() => (this.$root.actions.save.loading = false));
      }
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];
    next();
  }
};
</script>
<style lang="scss">
.copyable {
  &:hover {
    color: rebeccapurple;
    cursor: pointer;
  }
}
</style>