<template>
  <v-form ref="form" v-model="valid">
    <v-container class="px-3">
      <v-row>
        <v-col cols="4">
          <v-text-field
            prepend-icon="mdi-format-color-text"
            label="Nome"
            v-model="current.name"
            counter="128"
            :rules="[rules.required]"
            autocomplete="off"
            :loading="loading"
          />
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-label-outline"
            label="Slug"
            v-model="current.slug"
            v-on:keyup.32="format()"
            @click="$root.copy(current.slug)"
            counter="256"
            class="copyable"
            :rules="[rules.required, rules.slug]"
            placeholder="Ex.: pagina-exemplo"
            autocomplete="off"
            :loading="loading"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            prepend-icon="mdi-barcode"
            label="ID"
            v-model="current.id"
            autocomplete="off"
            readonly
            class="copyable"
            @click="$root.copy(current.id)"
            :loading="loading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <editor
            ref="editor"
            api-key="no-api-key"
            v-model="current.text"
            :disabled="saving"
            :init="{
              height: '500',
              menubar: false,
              plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
              'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
              alignleft aligncenter alignright alignjustify | table image | \
              bullist numlist outdent indent | removeformat | code | help',
              images_upload_url: 'postAcceptor.php'
            }"
          ></editor>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbars.save"></v-snackbar>
  </v-form>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import moment from "moment";
import { diff } from "deep-diff";
import Editor from "@tinymce/tinymce-vue";

Vue.use(VueLodash);

export default {
  components: {
    editor: Editor,
  },

  data: () => ({
    saving: false,
    valid: false,

    subscriptions: [],

    snackbars: {
      copy: false,
      save: false,
    },

    loading: false,

    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      slug: (slug) => /^[a-z0-9]+(?:(_||-)[a-z0-9]+)*$/.test(slug) || "Slug inválida",
    },

    old: {},
    current: {},
  }),

  computed: {
    dateFormat(item) {
      return moment.utc(item).format("DD/MM/YYYY");
    },

    changed() {
      var changes = diff(this.old, this.current);
      return !!changes;
    },

    currentPlans() {
      console.log(this.current.plans);
      return this.current.plans;
    },

    selectedPlans: function () {
      return this.plans.filter((e) =>
        this.current.plans.map((p) => p.id).includes(e.id)
      );
    },

    filterPlans: function () {
      return this.plans.all.filter((e) =>
        this.current.plans.map((p) => p.id).includes(e.id)
      );
    },
  },

  watch: {
    current: {
      deep: true,
      handler: function() {
        const isValid = this.$refs.form && this.$refs.form.validate()
        const hasChanged = !!diff(this.old, this.current)
        this.$root.actions.save.disable = !isValid || !hasChanged;
      }
    },
  },

  mounted: function () {
    this.$root.title = "Página";
    this.$root.show({ create: false, remove: true, save: true });

    this.$data.subscriptions.push(
      this.$root.actions.save.event.subscribe((e) => {
        this.save();
      }),

      this.$root.actions.remove.event.subscribe((e) => {
        this.remove();
      })
    );

    this.load();
  },

  methods: {
    load() {
      this.loading = true
      const id = router.currentRoute.params.id;
      axios.get(`pages/${id}`)
        .then((res) => {
          this.old = res.data.data;
          this.current = this.$root.clone(this.old);

          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false
        })
    },

    copy: async function (text) {
      await this.$copyText(text);
      this.snackbars.copy = true;
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    format: function () {
      let slug = this.current.slug;
      slug = slug ? slug.split(" ").join("-") : "";
      this.current.slug = slug
    },

    openPlans() {
      this.createDialog.show = true;

      this.getPlans();
    },

    getPlans() {
      axios.get(`plans`).then((res) => {
        console.log(this.current.plans);
        this.plans.all = res.data.data;
        this.plans.available = this.plans.all.filter(
          (e) => !this.current.plans.map((p) => p.id).includes(e.id)
        );
      });
    },

    save() {
      this.$root.actions.save.loading = true;
      let keys = diff(this.current, this.old).map((e) => e.path[0]);

      let obj = { op: "update" };
      keys.forEach((e) => {
        Object.assign(obj, { [e]: this.current[e] });
      });

      const id = this.$route.params.id;
      axios
        .patch(`pages/${id}`, obj)
        .then((res) => {
          this.$root.openSnackbar('Alterações salvas com sucesso!')
          this.load();
        })
        .catch((e) => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally(() => (this.$root.actions.save.loading = false));
    },

    remove() {
      this.$root.actions.remove.loading = true

      const id = this.current.id

      axios
        .delete(`pages/${id}`)
        .then(res => {
          this.$root.openSnackbar('Página excluída com sucesso.')
          router.back()
        })
        .catch((e) => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally(() => this.$root.actions.remove.loading = false)
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach((e) => e.unsubscribe());
    this.$data.subscriptions = [];

    next();
  },
};
</script>
<style lang="scss">
</style>