<template>
  <v-form ref="form" v-model="valid">
    <v-container style="position='relative'">
      <v-row>
        <v-col cols="4">
          <v-text-field label="Nome" v-model="current.name" :rules="rules.name" counter="32" />
        </v-col>

        <v-col cols="4">
          <v-select
            label="Tipo"
            v-model="current.type"
            item-text="name"
            item-value="value"
            :items="types"
          >
          </v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field readonly label="Data de criação" v-model="dateFormat" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-select
            label="Plano destaque"
            v-model="current.featured"
            :item-text="formatPlan"
            :item-value="'id'"
            :items="current.plans"
            :rules="rules.featured"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field readonly label="ID" v-model="current.id" />
        </v-col>
        <v-col>
          <v-select
            label="Ativo"
            :items="activeItems"
            item-value="value"
            item-text="name"
            v-model="current.active"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="current.type != 1 && current.type != 4">
        <v-col :cols="current.type == 3 ? 6 : 12">
          <v-text-field label="Campo" v-model="current.field" counter="64" :rules="rules.field" />
        </v-col>

        <v-col v-if="current.type == 3">
          <v-text-field label="Código" v-model="current.code" counter="32" :rules="rules.code" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field label="Descrição" v-model="current.description" :rules="rules.description" />
        </v-col>
      </v-row>

      <v-dialog v-model="createDialog.show" width="600px">
        <v-card>
          <v-card-title>Adicionar Plano</v-card-title>
          <v-form v-model="validPlans">
            <v-container>
              <v-row class="mx-2">
                <v-col cols="12">
                  <v-select
                    label="Planos disponíveis"
                    v-model="plans.add"
                    :items="plans.available"
                    :item-text="formatPlan"
                    no-data-text="Sem planos disponíveis"
                    :item-value="'id'"
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" @click="addPlan()" :loading="createDialog.loading" :disabled="!validPlans">Adicionar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="removeDialog.show" width="500px" height="200px">
        <v-card>
          <v-card-title class>Remover</v-card-title>
          <v-card-text style="padding-bottom:0px">
            <p style="padding-bottom:0px">Tem certeza que deseja remover este plano?</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="error" @click="remove(removeDialog.id)" width="180" :loading="removeDialog.loading">Remover Plano</v-btn>
            <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <v-subheader>PLANOS</v-subheader>
        <v-data-table
          :loading="loading"
          loading-text="Aguarde..."
          :headers="headers"
          :items="current.plans"
          no-data-text="Sem resultados"
          :server-items-length="total"
          :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
          }"
        >
          <template v-slot:[`item.featured`]="{ item }">
            <div v-if="item.featured">
              <v-chip label small color="green" text-color="white">DESTAQUE</v-chip>
            </div>
          </template>

          <template v-slot:[`item.sku`]="{ item }">
            <div class="copyable" @click="copy(item.sku)">{{ item.sku }}</div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="removeDialog.show = true; removeDialog.id = item.id">
              <v-icon small color="red">delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="d-flex align-self-stretch justify-end">
          <v-btn @click="openPlans()" text>
            <v-icon size="medium">add</v-icon>Adicionar
          </v-btn>
        </div>
      </div>

      <div>
        <v-subheader>USUÁRIOS DESTA PARCERIA</v-subheader>
        <v-data-table
          :loading="usersLoading"
          loading-text="Aguarde..."
          :headers="usersHeaders"
          :items="users"
          no-data-text="Sem resultados"
          :options.sync="usersOptions"
          :server-items-length="usersTotal"
          :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
          }"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="copyable" @click="$root.copy(item.id)">{{ item.id }}</div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="goToUser(item.id)">
              <v-icon small>open_in_new</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="d-flex align-self-stretch justify-end">
          <v-btn @click="openPlans()" text>
            <v-icon size="medium">add</v-icon>Adicionar
          </v-btn>
        </div>
      </div>      
      <v-snackbar v-model="snackbars.copy">Texto copiado!</v-snackbar>
      <v-snackbar v-model="snackbars.save">Alterações salvas!</v-snackbar>
      <v-snackbar v-model="snackbars.remove">Plano removido.</v-snackbar>

    </v-container>
  </v-form>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import pick from "object.pick";
import moment from "moment";
import { diff } from "deep-diff";

Vue.use(VueLodash);

export default {
  data: () => ({
    validPlans: true,
    valid: false,

    saving: false,

    subscriptions: [],
    users: [],

    snackbars: {
      copy: false,
      save: false,
      remove: false,
      create: false,
    },

    loading: false,
    usersLoading: false,
    
    total: 0,
    usersTotal: 0,

    usersOptions: {
      page: 1,
      itemsPerPage: 10,
    },

    changePlan: false,
    removeDialog: {
      show: false,
      id: "",
      loading: false,
    },
    createDialog: {
      show: false,
      loading: false
    },

    plans: {
      all: [],
      available: [],
      add: {}
    },

    rules: {
      plans: [v => !!v.length || "Pelo menos um plano é obrigatório."],
      featured: [v => !!v || "O plano destaque é obrigatório."],
      name: [
        v => !!v || "O nome é obrigatório.",
        v => (!!v && v.length <= 32) || "O nome deve ter no máximo 32 letras."
      ],
      field: [
        v => !!v || "O campo é obrigatório.",
        v => (!!v && v.length <= 64) || "O campo deve ter no máximo 32 letras."
      ],
      code: [
        v => !!v || "O código é obrigatório.",
        v => (!!v && v.length <= 32) || "O código deve ter no máximo 32 letras."
      ],
      description: [
        v => !!v || "A descrição é obrigatória."
        // v => (!!v && v.length <= 32) || "A descrição deve ter no máximo 32 letras."
      ]
    },

    types: [
      { name: "Padrão", value: 1 },
      { name: "Secad", value: 2 },
      { name: "Empresa", value: 3 },
      { name: "Programa de indicação", value: 4 }
    ],

    old: {},
    current: {},
    activeItems: [
      { value: true, name: "Sim" },
      { value: false, name: "Não" }
    ],

    headers: [
      { text: "NOME", value: "name", sortable: false },
      { text: "", value: "featured", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      { text: "DESCRIÇÃO", value: "description", sortable: false },
      { text: "AÇÕES", value: "actions", sortable: false }
    ],

    usersHeaders: [
      { text: "NOME", value: "name", sortable: false },
      { text: "EMAIL", value: "email", sortable: false },
      { text: "ID", value: "id", sortable: false },
      { text: "AÇÕES", value: "actions", sortable: false }
    ]
  }),

  computed: {
    dateFormat(item) {
      return moment.utc(item).format("DD/MM/YYYY");
    },

    validate() {
      var changes = diff(this.old, this.current);
      var valid = this.valid

      return !!changes && valid
    },
    
    currentPlans() {
      console.log(this.current.plans);
      return this.current.plans;
    },

    selectedPlans: function() {
      return this.plans.filter(e =>
        this.current.plans.map(p => p.id).includes(e.id)
      );
    },

    filterPlans: function() {
      return this.plans.all.filter(e =>
        this.current.plans.map(p => p.id).includes(e.id)
      );
    }
  },

  watch: {
    validate: {
      handler(valid) {
        this.$root.actions.save.disable = !valid || this.loading
      },
      deep: true
    },

    usersOptions: {
      deep: true,
      handler() {
        this.load()
      }
    },

    current: {
      handler() {
        this.current.plans.forEach(e => {
          Object.assign(e, { featured: !!(this.current.featured == e.id) });
        });

        this.plans.available = this.plans.all.filter(
          e => !this.current.plans.map(p => p.id).includes(e.id)
        );
      },
      deep: true
    },

    valid: function(e) {
      if (!e) this.$root.actions.save.disable = true;
    }
  },

  mounted: function() {
    this.$root.title = "Parceria";
    this.$root.show({ create: false, save: true });

    this.$data.subscriptions.push(
      this.$root.actions.save.event.subscribe(e => {
        this.save();
      })
    );

    this.load()
  },

  methods: {
    load() {
      this.loading = true;
      this.usersLoading = true;
      this.usersLoading = true;

      const id = router.currentRoute.params.id;
      axios.get(`partnerships/${id}?version=1`).then(res => {
        this.old = res.data.data;
        const plans = this.old.plans.concat('-1').join(",");
        axios.get(`plans?id=${plans}`).then(res => {
          this.old.plans = res.data.data;
          this.old.plans.forEach(e => {
            Object.assign(e, { featured: !!(this.old.featured == e.id) });
          });

          this.current = this.clone(this.old);
          this.total = res.data.total;
          this.loading = false;
        });
      });

      const start = (this.usersOptions.page - 1) * this.usersOptions.itemsPerPage
      const limit = this.usersOptions.itemsPerPage
      axios.get(`users?version=1&partnership=${id}&start=${start}&limit=${limit}`)
        .then(res => {
          this.users = res.data.data
          this.usersTotal = res.data.total;
        })
        .finally(() => {
          this.usersLoading = false;
        })
    },

    copy: async function(text) {
      await this.$copyText(text);
      this.snackbars.copy = true;
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    openPlans() {
      this.createDialog.show = true;

      this.getPlans();
    },

    getPlans() {
      axios.get(`plans`).then(res => {
        console.log(this.current.plans);
        this.plans.all = res.data.data;
        this.plans.available = this.plans.all.filter(
          e => !this.current.plans.map(p => p.id).includes(e.id)
        );
      });
    },

    formatPlan: function(item) {
      return `${item.name} (${item.sku})`;
    },

    save() {
      this.$root.actions.save.loading = true
      let keys = diff(this.current, this.old).map(e => e.path[0]);

      let obj = { op: "update" };
      keys.forEach(e => {
        Object.assign(obj, { [e]: this.current[e] });
      });

      const id = this.$route.params.id
      axios
        .patch(`partnerships/${id}`, obj)
        .then(res => {
          this.snackbars.save = true
          this.load()
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(() => this.$root.actions.save.loading = false)
    },

    remove: function(id) {
      this.removeDialog.loading = true

      axios
        .delete(`partnerships/${this.$route.params.id}/plans/${id}`)
        .then(res => {
          this.removeDialog.show = false
          this.snackbars.remove = true

          this.load()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(() => {
          this.removeDialog.loading = false
        })
    },

    goToUser: function(id) {
      router.push(`/users/${id}`)
    },

    addPlan() {
      const obj = {id: this.plans.add.id}
      this.createDialog.loading = true

      axios
        .post(`partnerships/${this.$route.params.id}/plans`, obj)
        .then(res => {
          this.createDialog.show = false
          this.snackbars.create = true

          this.load()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(() => {
          this.createDialog.loading = false
        })
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    next();
  }
};
</script>
<style lang="scss">
</style>