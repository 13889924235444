import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#606D7C',
            secondary: '#b0bec5',
            accent: '#AAD3C7',
            error: '#b71c1c',
          },
        },
    }
});
