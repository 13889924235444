<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          label="Data de criação"
          readonly
          v-model="created"
        />
      </v-col>

      <v-col>
        <v-text-field
          label="Data de alteração"
          readonly
          v-model="created"
        />
      </v-col>

      <v-col>
        <v-text-field
          readonly
          v-model="status"
          label="Status"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          readonly
          v-model="imp.id"
          label="ID"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Log"
          readonly
          v-model="imp.log"
        >
          <template v-slot:append>
            <v-btn
              icon
              @click="download(imp.log)"
            >
              <v-icon>
                cloud_download
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          label="Zip"
          readonly
          v-model="imp.zip"
        >
          <template v-slot:append>
            <v-btn
              icon
              @click="download(imp.zip)"
            >
              <v-icon>
                cloud_download
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <v-snackbar v-model="snackbars.copy">Texto copiado!</v-snackbar>
      </v-col>
    </v-row>

    <v-subheader>VERBETES IMPORTADOS</v-subheader>
    <v-data-table
      :headers="contentheaders"
      :items="contents"
      hide-default-footer
      :loading="loaders.contents"
    >
      <template v-slot:[`item.new`]="{ item }">
        <v-chip
          small
          v-if="item.new"
          color="green"
          text-color="white"
        >
          NOVO
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="$router.push(`/entries/${item.id}/view`)">
          <v-icon small>open_in_new</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <span class="copyable" @click="copy(item.id)">{{ item.id }}</span>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span>{{ dateFormat(item.created) }}</span>
      </template>
    </v-data-table>

    <v-subheader>IMAGENS IMPORTADAS</v-subheader>
    <v-data-table
      :headers="imgheaders"
      :items="resources"
      hide-default-footer
      :loading="loaders.resources"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn 
          icon
          @click="download(item.id)"
        >
          <v-icon
            small
          >
            cloud_download
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.created`]="{}">
        <div>{{ created }}</div>
      </template>
    </v-data-table>

  </v-container>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

export default {
  data: () => ({
    imp: {},
    contents: [],
    resources: [],

    loaders: {
      contents: false,
      resources: false
    },

    snackbars: {
      copy: false
    },

    statuses: {
      1: "Iniciado",
      2: "Executando",
      3: "Executando",
      4: "Finalizado"
    },

    imgheaders: [
      {text: 'NOME', value: 'name', sortable: false},
      {text: 'DATA', value:'created', sortable: false, width: '90px'},
      {text: 'ID', value: 'id', width: '320px', sortable: false},
      {text: 'AÇÕES', value: 'actions', sortable: false, width: '60px'}
    ],

    contentheaders: [
      {text: 'NOME', value:'name', sortable: false},
      {text: 'CRIADO', value:'created', width:'90px', sortable: false},
      {text: '', value:'new', width:'60px', align:'left', sortable: false},
      {text: 'ID', value: 'id', width: '320px', sortable: false},
      {text: 'AÇÕES', value: 'actions', sortable: false, width: '60px'}
    ]
  }),

  computed: {
    created: function() {
      return moment(this.imp.created).format('DD/MM/YYYY')
    },

    updated: function() {
      return moment(this.imp.updated).format('DD/MM/YYYY')
    },

    status: function() {
      return this.statuses[this.imp.status]
    }

  },

  mounted: function() {
    this.$root.title = "Importação"
    this.$root.show({import: false})

    this.get()
  },

  methods: {
    get() {
      const id = this.$router.currentRoute.params.id

      this.loaders.contents = true
      this.loaders.resources = true
      axios
        .get(`imports/${id}`)
        .then(res => {
          this.imp = res.data.data

          axios
            .get(`imports/${id}/contents?version=2`)
              .then(res => {
                this.contents = res.data.data
                this.loaders.contents = false
              })

          axios
            .get(`imports/${id}/resources?version=2`)
              .then(res => {
                this.resources = res.data.data
                this.loaders.resources = false
              })
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            timeout: 5000
          });          
        })
    },

    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    copy: async function(item){
      await this.$copyText(item);
      this.snackbars.copy = true;
    },

    download(id) {
      var win = window.open(`${axios.defaults.baseURL}/resources/${id}`, '_blank');
      win.focus();
    }
  }
}
</script>
