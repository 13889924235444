<template>
    <v-card>
        <v-card-title>Visualizar Alterações</v-card-title>

        <v-card-text>
            <p>
                As informações abaixo informam as alterações feitas neste verbete pelo usuário <b>{{ user }}</b>. 
            </p>

            <v-row v-for="change in changesArr" :key="change.key" :class="changes && changes.value ? '' : 'justify-space-between'">
                <div class="mx-3"><b>{{ translations[change.key] || format(change.key) }}:</b></div>
                <div class="mx-3">{{ values[change.key] || "-" }}</div>
            </v-row>

            <v-row v-for="item in exceptions" :key="item">
                <v-container class="mx-0" v-if="changes && changes.value">
                    <v-subheader class="px-0">CONTEÚDO DO VERBETE</v-subheader>
                    <div v-html="changes.value" style="border: 1px solid #666; padding: 8px"></div>
                </v-container>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('close')" text>OK</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

const omitEmpty = require("omit-empty");

export default {
    props: {
        user: String,
        changes: Object
    },

    data: () => ({
        translations: {
            name: 'Nome',
            authors: 'Autores',
            type: 'Tipo',
            category: 'Categoria',
            categories: 'Categorias',
            specialty: 'Especialidade',
            specialties: 'Especialidades',
            premium: 'Premium',
            draft: 'Com rascunho',
            searchable: 'Pesquisável',
            contract: 'Contrato',
            synonyms: 'Sinônimos',
            keywords: 'Palavras-chave',
            slug: 'Slug',
            value: 'Valor',
            date: 'Data',
            similarity: 'Similaridade',
            links: 'Páginas Relacionadas'
        },

        exceptions: ['value'],

        values: {},
    }),

    computed: {
        changesArr: function() {
            return Object.keys(this.changes)
                .filter(e => !this.exceptions.includes(e))
                .map(key => ({key: key || this.format(key), value: this.changes[key]}))
        }
    },

    methods: {
        format(text) {
            return text.charAt(0).toUpperCase() + text.slice(1)
        },

        async authors() {
            const authors = this.changes.authors.join(',')
            const res = await axios.get(`users?id=${authors}`)
            this.values.authors = res.data.data.map(e => e.name).join(', ')
        },

        async type () {
            this.values.type = {1: 'Artigo', 2: 'Ferramenta', 3: 'Hierarquia', 4: 'Interações'}[this.changes.type]
        },

        async name() {
            this.values.name = this.changes.name
        },

        async category() {
            const res = await axios.get(`categories/${this.changes.category}`)
            this.values.category = res.data.data.name
        },

        async specialtiy() {
            const res = await axios.get(`specialties/${this.changes.specialty}`)
            this.values.specialty = res.data.data.name
        },

        async specialties() {
            const specialties = this.changes.specialties.join(',')
            const res = await axios.get(`specialties?id=${specialties}`)
            this.values.specialties = res.data.data.map(e => e.name).join(', ')
        },

        async premium() { this.values.premium = this.changes.premium ? "Sim" : "Não" },

        async draft() { this.values.draft = this.changes.draft ? "Sim" : "Não" },

        async searchable() { this.values.searchable = this.changes.searchable ? "Deve vir na pesquisa" : "Não deve vir na pesquisa" },

        async contract() {
            const res = await axios.get(`contracts/${this.changes.contract}`)
            this.values.contract = res.data.data.name
        },

        async keywords() {
            this.values.keywords = this.changes.keywords.join(', ')
        },

        async synonyms() {
            this.values.synonyms = this.changes.synonyms.join(', ')
        },

        async date() {
            this.values.date = moment(this.changes.date).format('DD/MM/YYYY - HH:mm')
        },

        async links() {
            const links = this.changes.links.join(',')
            const res = await axios.get(`contents?id=${links}`)
            this.values.links = res.data.data.map(e => e.name).join(',')
        },

        async value() {
            this.values.value = this.changes.value
        }

    },

    mounted: function() {
        
        const keys = [...Object.keys(this.changes)]
        const promises = keys.map(key => this[key]())

        Promise.all(promises)
            .then(() => {
                this.$forceUpdate()
            })
    }
}
</script>

<style>

</style>