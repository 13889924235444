<template>
  <v-container style="postion: relative" id="entries">
    <div>
      <!-- v-on:reload="onFilter($event)" -->
      <content-filter
        :filters="filters"
        v-on:requestChange="onRequestChange($event)"
        v-on:reload="onFilter($event)"
      ></content-filter>
    </div>
    <br />
    <v-data-table
      :loading="loading"
      loading-text="Aguarde..."
      :headers="headers"
      :items="entries"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      @click:row="click"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span class="mr-2 px-2">{{ item.name }}</span>
        <v-icon v-if="item.drafts" small class="px-1">mdi-pencil</v-icon>
        <v-icon v-if="item.premium" small>mdi-lock</v-icon>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="view(item)">mdi-eye</v-icon>
        <v-icon small class="mr-2" @click="history(item)">mdi-history</v-icon>
        <v-icon
          v-if="item.drafts"
          @click.stop="publish(item)"
          small
          class="mr-2"
          color="error"
        >
          mdi-publish
        </v-icon>
        <v-icon v-if="item.drafts" @click.stop="edit(item)" small class="mr-2">
          mdi-pencil
        </v-icon>

        <v-icon
          small
          class="mr-2"
          @click="
            $event.stopPropagation();
            download(item);
          "
          >mdi-cloud-download</v-icon
        >
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ item.date | moment("DD/MM/YYYY") }}</span>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <div class="copyable" v-on:click.stop="copy(item)">
          {{ item.id.substring(0, 8) }}
        </div>
      </template>
      <template v-slot:[`item.links`]="{ item }">
        <span>{{ "in:" + item.links.in + " | out:" + item.links.out }}</span>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="2000">Id copiado</v-snackbar>

    <v-dialog v-model="dialog.create" width="900px">
      <v-card>
        <v-card-title class>Novo Verbete</v-card-title>
        <v-container>
          <div id="dialog">
            <v-container style="padding: 0px">
              <v-form v-model="valid" style="padding: 0px">
                <v-row class="mx-2" no-gutters>
                  <v-col
                    cols="12"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-text-field
                      label="Nome"
                      :rules="rules.name"
                      v-model="entry.name"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-2" no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-select
                      height="32"
                      label="Tipo"
                      :rules="rules.type"
                      v-model="entry.type"
                      v-on:input="onTypeChange"
                      :items="types"
                      :item-text="item => item.name"
                      :item-value="item => item.value"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-select
                      height="32"
                      label="Pesquisável"
                      :rules="rules.searchable"
                      v-model="entry.searchable"
                      :items="searchables"
                      :item-text="item => item.name"
                      :item-value="item => item.value"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mx-2" no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-select
                      height="32"
                      :rules="rules.specialty"
                      label="Especialidade"
                      clearable
                      multiple
                      v-model="entry.specialties"
                      :items="specialties"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-select
                      height="32"
                      label="Categorias"
                      :rules="rules.category"
                      v-model="entry.category"
                      :items="filteredCategories"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                    >
                      <template slot="selection" scope="data">
                        <v-list-item-avatar size="16">
                          <img
                            v-bind:src="`${url}/resources/` + data.item.image"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template slot="item" scope="data">
                        <v-list-item-avatar>
                          <img
                            v-bind:src="`${url}/resources/` + data.item.image"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            style="width: 1%"
                            v-text="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row
                  class="mx-2"
                  no-gutters
                  v-if="[1, 4].indexOf(entry.type) >= 0"
                >
                  <v-col
                    cols="12"
                    md="12"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-select
                      height="32"
                      :rules="rules.contract"
                      label="Contrato"
                      clearable
                      v-model="entry.contract"
                      :items="contracts"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row
                  class="mx-2"
                  v-if="entry.type == 1"
                  style="height: 220px; padding-top: 10px"
                  no-gutters
                >
                  <v-col cols="12">
                    <editor
                      v-if="entry.type == 1"
                      id="app-editor"
                      ref="editor"
                      api-key="no-api-key"
                      v-on:input="onChange"
                      :disabled="saving"
                      :initial-value="entry.value"
                      :init="initialMCE"
                    ></editor>
                  </v-col>
                </v-row>
                <v-row
                  class="mx-2"
                  v-if="entry.type == 2"
                  style="height: 220px; padding-top: 10px"
                  no-gutters
                >
                  <v-textarea
                    v-if="entry.type == 2"
                    height="220"
                    filled
                    label="Código"
                    v-model="entry.value"
                    hint="Código da calculadora"
                  ></v-textarea>
                </v-row>
                <v-row
                  class="mx-2"
                  v-if="entry.type == 3"
                  style="height: 60px; padding-left: 10px; padding-right: 10px"
                  no-gutters
                >
                  <v-autocomplete
                    v-model="entry.parent"
                    :search-input.sync="entry.sparent"
                    :items="parents"
                    :loading="loadingParents"
                    hide-no-data
                    hide-selected
                    item-text="name"
                    item-value="id"
                    @update:search-input="searchParents($event)"
                    label="Nível superior"
                  >
                    <template slot="selection" scope="data">{{
                      data.item.name
                    }}</template>
                    <template slot="item" scope="data">
                      <v-list-item-avatar>
                        <img
                          v-bind:src="
                            `${url}/resources/` +
                              categories.filter(
                                e => e.id === data.item.category
                              )[0].image
                          "
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-row>
                <v-row
                  class="mx-2"
                  v-if="entry.type == 4"
                  style="height: 300px; padding-left: 0px; padding-right: 0px"
                  no-gutters
                >
                  <v-row class="mx-2">
                    <v-col
                      cols="12"
                      md="6"
                      style="padding-left: 0px; padding-right: 10px"
                    >
                      <v-combobox
                        v-model="entry.substance1"
                        :items="substances1"
                        :loading="loadingSubstances1"
                        hide-no-data
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :rules="rules.substances1"
                        @input.native="
                          entry.substance1 = $event.srcElement.value
                        "
                        @update:search-input="searchSubstance1($event)"
                        label="Primeria Substância"
                      ></v-combobox>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      style="padding-left: 10px; padding-right: 0px"
                    >
                      <v-combobox
                        v-model="entry.substance2"
                        :items="substances2"
                        :loading="loadingSubstances2"
                        hide-no-data
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        :rules="rules.substances2"
                        @input.native="
                          entry.substance2 = $event.srcElement.value
                        "
                        @update:search-input="searchSubstance2($event)"
                        label="Segunda Substância"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row
                    style="
                      height: 220px;
                      padding-left: 10px;
                      padding-right: 10px;
                    "
                  >
                    <v-col cols="12">
                      <editor
                        v-if="entry.type == 4"
                        ref="editor"
                        id="app-editor"
                        api-key="no-api-key"
                        v-on:input="onChange"
                        :disabled="saving"
                        :initial-value="entry.value"
                        :init="initialMCE"
                      ></editor>
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
            </v-container>
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            :loading="saving"
            @click="create()"
            :disabled="!valid || saving || (entry.type != 3 && !entry.value)"
            width="100"
            >Salvar</v-btn
          >
          <v-btn text color="primary" @click="dialog.create = false" width="100"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      width="600"
      z-index="1099"
      v-model="customLink"
      @click:outside="$root.tinyMCE.customLink = false"
    >
      <editor-link-dialog
        v-on:close="$root.tinyMCE.customLink = false"
        v-on:save="newCustomLink($event)"
      ></editor-link-dialog>
    </v-dialog>

    <v-dialog v-model="publishDialog.show" width="500px" height="200px">
      <content-publish
        v-on:close="publishDialog.show = false"
        v-on:reload="reload()"
        :content="publishDialog.content"
        :revision="publishDialog.object"
      ></content-publish>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import Editor from "@tinymce/tinymce-vue";
import pick from "object.pick";
import Subscription from "rxjs";
import moment from "moment";
import { v4 as uuid } from "uuid";
import slug from "slug";
import * as storage from "../libs/storage";
import ContentPublish from "../components/Content.Publish";
import ContentFilter from "../components/Content.Filter";
import config from "../plugins/tinymce/config/config";
import EditorLinkDialog from "../components/EditorLinkDialog";

const omitEmpty = require("omit-empty");

Vue.use(VueLodash);

export default {
  components: {
    editor: Editor,
    ContentPublish,
    ContentFilter,
    EditorLinkDialog
  },

  data: () => ({
    requestId: "",

    valid: false,
    loading: false,
    loadingParents: false,
    loadingSubstances1: false,
    loadingSubstances2: false,
    saving: false,
    search: "",
    snackbar: false,
    subscriptions: [],

    publishDialog: {
      show: false,
      object: null
    },

    dialog: { create: false, import: false },

    entries: [],
    category: "",
    specialty: "",
    empty: "",
    content: "",
    hasDraft: "",

    filters: {
      name: "",
      category: "",
      specialty: "",
      author: "",
      empty: "",
      draft: "",
      mode: 1
    },

    entry: {
      sparent: "",
      value: "",
      name: "",
      type: "",
      substance1: "",
      substance2: "",
      category: "",
      specialty: "",
      contract: ""
    },

    rules: {
      name: [
        v => !!v || "O nome é obrigatório",
        v => (v && v.length <= 100) || "O nome deve ter menos de 100 caracteres"
      ],
      category: [v => !!v || "A categoria é obrigatória"],
      specialty: [],
      contracts: [v => !!v || "O contrato é obrigatório"],
      specialtyO: [v => !!v || "A especialidade é obrigatória"],
      type: [v => !!v || "O tipo é obrigatório"],
      searchable: [v => !!v || "O pesquisável é obrigatório"],
      value: [v => !!v || "O verbete deve ter conteúdo"],
      resource: [v => !!v || "Arquivo é obrigatório"],
      substance1: [v => !!v || "A primeira substância é obrigatória"],
      substance2: [v => !!v || "A segunda substância é obrigatória"]
    },

    empties: [
      { name: "Vazios", value: true },
      { name: "Não vazios", value: false }
    ],
    types: [
      { name: "Verbete", value: 1 },
      { name: "Calculadora", value: 2 },
      { name: "Hierarquia", value: 3 },
      { name: "Interação Medicamentosa", value: 4 }
    ],
    searchables: [
      { value: true, name: "Deve vir na pesquisa" },
      { value: false, name: "Não deve vir na pesquisa" }
    ],

    filteredCategories: [],
    categories: [],
    specialties: [],
    contracts: [],
    parents: [],
    substances1: [],
    substances2: [],

    options: {
      page: 1,
      itemsPerPage: 10
    },
    total: 0,
    headers: [
      {
        text: "NOME",
        value: "name"
      },
      {
        text: "CATEGORIA",
        width: "180px",
        value: "category"
      },
      {
        text: "ID",
        width: "100px",
        align: "left",
        sortable: false,
        value: "id"
      },
      {
        text: "DATA",
        width: "120px",
        align: "left",
        sortable: false,
        value: "date"
      },
      {
        text: "LINKS",
        width: "110px",
        align: "left",
        sortable: false,
        value: "links"
      },
      {
        text: "AÇÕES",
        value: "action",
        width: "120px",
        sortable: false
      }
    ]
  }),

  computed: {
    initialMCE: function() {
      return config.config;
    },

    customLink: function() {
      return this.$root.tinyMCE.customLink;
    }
  },

  watch: {
    options: {
      async handler() {
        this.reload();
      },
      deep: true
    },
    "entry.substance1": {
      async handler() {
        this.onSubstanceChange();
      },
      deep: true
    },
    "entry.substance2": {
      async handler() {
        this.onSubstanceChange();
      },
      deep: true
    }
  },

  mounted: function() {
    this.$root.title = "Verbetes";
    this.$root.show({ create: true });
    this.$root.actions.create.disable = !this.$root.canAccess(
      "POST",
      "/contents/"
    );
    this.url = axios.defaults.baseURL;

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("entries")) || {}, ["options", "filters"])
    );

    this.load(
      this.filters.mode,
      this.filters.search,
      this.filters.category,
      this.filters.specialty,
      this.filters.contract,
      this.filters.empty,
      this.filters.draft,
      this.filters.content,
      0,
      this.options.itemsPerPage,
      this.options.sort,
      this.filters.premium
    );

    this.$data.loading = true;

    //subscribe to action
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(
      this.$root.actions.create.event.subscribe(e => {
        this.dialog.create = true;
      })
    );

    axios
      .get(`/categories?limit=1000`)
      .then(e => {
        this.filteredCategories = [];
        this.categories = e.data.data;
      })
      .catch(e => {})
      .finally(e => {});
    axios
      .get(`/specialties?limit=1000`)
      .then(e => (this.specialties = e.data.data))
      .catch(e => {})
      .finally(e => {});
    axios
      .get(`/contracts?limit=1000`)
      .then(e => {
        this.filteredCategories = [];
        this.contracts = e.data.data;
      })
      .catch(e => {})
      .finally(e => {});
  },

  methods: {
    onRequestChange(id) {
      this.requestId = id;
    },
    copy: async function(item) {
      await this.$copyText(item.id);
      this.snackbar = true;
    },
    click: function(item) {
      //router.push(`/entries/${item.id}/view`);
      var route = router.resolve(`/entries/${item.id}/view`);
      window.open(route.href, "_blank");
    },
    debounce: _.debounce(function() {
      this.reload();
    }, 500),

    onChange: _.debounce(function(e) {
      if (tinyMCE.activeEditor) {
        this.entry.value = tinyMCE.activeEditor.getContent({});
      }
    }, 500),

    onTypeChange: function(e) {
      this.category = null;

      if (e == 2)
        this.filteredCategories = this.categories.filter(c => c.type == 3);
      else if (e == 3)
        this.filteredCategories = this.categories.filter(c => c.type == 5);
      else if (e == 4)
        this.filteredCategories = this.categories.filter(c => c.type == 4);
      else
        this.filteredCategories = this.categories.filter(
          c => c.type != 3 && c.type != 4 && c.type != 5
        );
    },
    onSubstanceChange: function() {
      //if (this.entry.type == 4) this.entry.name = `Interação entre ${this.entry.substance1 || "-"} e ${this.entry.substance2 || "-"}`
    },
    newCustomLink: function(link) {
      const content = tinymce.activeEditor.selection.getContent();
      tinymce.activeEditor.selection.setContent(
        `<a href=${link}>${content}</a>`
      );
      this.onChange(tinymce.activeEditor.selection.getContent());
    },
    reload: function(filters) {
      filters = filters || this.filters;

      var search = filters.name;
      var category = filters.category;
      var specialty = filters.specialty;
      var contract = filters.contract;
      var author = filters.author;
      var empty = filters.empty;
      var content = filters.content;
      var draft = filters.draft;
      var mode = filters.mode;
      var premium = filters.premium;

      var sort = this.options.sortBy
        .filter(e => e)
        .map((e, i) => `${this.options.sortDesc[i] ? "-" : ""}${e}`)
        .join(",");

      var limit = this.options.itemsPerPage;
      var start = (this.options.page - 1) * limit;

      this.load(
        mode,
        search,
        category,
        specialty,
        contract,
        author,
        empty,
        content,
        draft,
        start,
        limit,
        sort,
        premium
      );
    },
    load: function(
      mode,
      search,
      category,
      specialty,
      contract,
      author,
      empty,
      content,
      draft,
      start,
      limit,
      sort,
      premium
    ) {
      this.loading = true;

      var filter = function(field, value) {
        return value === null || value === undefined
          ? ""
          : "&" + field + "=" + value;
      };

      const p1 = Promise.resolve(this.requestId);
      const p2 = axios.get(
        `/contents?version=4${filter("mode", mode)}${filter("search",search)}${filter("category",category)}${filter("specialty", specialty)}${filter("contract", contract)}${filter("author", author)}${filter("draft",draft)}${filter("content", content)}${filter("empty", empty)}${filter("premium",premium)}${filter("sort", sort)}&start=${start}${filter("limit", limit)}`
      );

      Promise.all([p1, p2])
        .then(res => {
          if (res[0] == this.requestId) {
            this.entries = res[1].data.data;
            this.total = res[1].data.total;
            this.loading = false;
          }
        })
        .catch(e => {
          this.loading = false;
        });
    },
    history: function(item) {
      var route = router.resolve(`/entries/${item.id}/revisions`);
      window.open(route.href, "_blank");
    },
    view: function(item) {
      var route = router.resolve(`/entries/${item.id}/view`);
      window.open(route.href, "_blank");
    },
    links: function(item) {
      var route = router.resolve(`/entries/${item.id}/links`);
      window.open(route.href, "_blank");
    },
    create: function() {
      var data = this.$data.entry;

      if (data.type == 1)
        data.value = tinyMCE.activeEditor.getContent({ format: "raw" }); //get html content

      data.slug = (slug(data.name) || "").substring(0, 64);

      this.$data.saving = true;
      data = omitEmpty(data);

      axios
        .post("contents/", data)
        .then(e => {
          this.dialog.create = false;
          this.entry.name = "";
          this.entry.category = "";
          this.entry.specialty = "";
          this.entry.contract = "";
          this.entry.type = "";
          this.entry.value = "";
          this.entry.searchable = "";

          this.reload();
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.saving = false;
        });
    },

    publish: function(item) {
      axios
        .get(`contents/${item.id}/revisions`)
        .then(res => {
          this.publishDialog.content = item.id;
          this.publishDialog.object = res.data.data[0];
          this.publishDialog.show = true;
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        });
    },

    edit: function(item) {
      axios
        .get(`contents/${item.id}/revisions`)
        .then(res => {
          //router.push(`/entries/${item.id}/revisions/${res.data.data[0].id}/edit`);
          var route = router.resolve(
            `/entries/${item.id}/revisions/${res.data.data[0].id}/edit`
          );
          window.open(route.href, "_blank");
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        });
    },

    download: function(item) {
      var app = (localStorage.getItem('app') && JSON.parse(localStorage.getItem('app') || '{}')) || (router.push('/login'));
      var win = window.open(`${axios.defaults.baseURL}/contents/${item.id}?version=5&x-app=${app.code}`,"_blank");
      win.focus();
    },
    searchParents: _.debounce(function(e) {
      if (e) {
        this.loadingParents = true;
        axios
          .get(`/contents?version=1&name=${e}&limit=20&empty=true,false&type=3`)
          .then(e => {
            this.parents = e.data.data;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000
            });
          })
          .finally(() => {
            this.loadingParents = false;
          });
      }
    }, 500),
    searchSubstance1: _.debounce(function(e) {
      if (e) {
        this.loadingSubstances1 = true;
        axios
          .get(`/contents/substances?version=1&name=${e}&limit=20`)
          .then(e => {
            this.substances1 = e.data.data;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000
            });
          })
          .finally(() => {
            this.loadingSubstances1 = false;
          });
      }
    }, 500),
    searchSubstance2: _.debounce(function(e) {
      if (e) {
        this.loadingSubstances2 = true;
        axios
          .get(`/contents/substances?version=1&name=${e}&limit=20`)
          .then(e => {
            this.substances2 = e.data.data;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000
            });
          })
          .finally(() => {
            this.loadingSubstances2 = false;
          });
      }
    }, 500),

    onFilter: function(e) {
      this.search = e.name || "";
      this.category = e.category || "";
      this.specialty = e.specialty || "";
      this.author = e.author || "";
      this.empty = e.empty !== undefined ? e.empty : "";
      this.hasDraft = e.hasDraft !== undefined ? e.hasDraft : "";

      if (this.options.page == 1) this.reload();
      else this.options.page = 1;
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("entries", JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
#entries {
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

  .v-skeleton-loader__image {
    height: 80vh !important;
  }
}

#dialog {
  .tox-tinymce {
    height: calc(100%) !important;
  }
}
</style>
