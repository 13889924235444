<template>
  <v-card>
    <v-card-title> Nova Mensagem </v-card-title>
    <v-container>
      <v-stepper v-model="step" alt-labels style="box-shadow:none">
        <v-stepper-header style="box-shadow:none">
          <v-stepper-step :complete="step > 1" step="1">Mensagem</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">Segregação</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">Envio</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items style="box-shadow:none">
          <v-stepper-content step="1">
            <div>
              <v-form v-model="valid" ref="form">
                <v-col cols="12">
                  <p class="text--secondary text-justify">
                    O <b>Título App</b> é uma informação que será apresentada na
                    mensagem dentro do app quando elas forem listadas na lista de
                    notificações.
                  </p>
                  <v-text-field
                    class="pt-0"
                    label="Título App"
                    counter="128"
                    v-model="message.title"
                    :rules="rules.appTitleRules"
                  />

                  <v-select
                    v-model="message.type"
                    label="Tipo"
                    item-text="value"
                    item-value="id"
                    :rules="rules.type"
                    v-on:change="typeChange()"
                    :items="types"
                    required
                  >
                  </v-select>

                  <div v-if="message.type == 1">
                    <p class="text--secondary text-justify">
                      Esta mensagem apenas envia uma notificação de PUSH para os
                      usuários, sem nenhuma ação disponível.
                    </p>
                  </div>
                  <div v-if="message.type == 2">
                    <p class="text--secondary text-justify">
                      Esta mensagem apenas envia uma notificação de PUSH para os
                      usuários, porém vincula a uma CATEGORIA, conforme selecionado
                      abaixo.
                    </p>
                    <v-select
                      height="32"
                      label="Categorias"
                      v-model="message.value"
                      :items="categories"
                      :item-text="(item) => item.name"
                      :item-value="(item) => item.id"
                      :rules="rules.categoryRules"
                    >
                      <template slot="selection" scope="data">
                        <v-list-item-avatar size="16">
                          <img
                            v-bind:src="`${$root.url}/resources/` + data.item.image"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template slot="item" scope="data">
                        <v-list-item-avatar>
                          <img
                            v-bind:src="`${$root.url}/resources/` + data.item.image"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            style="width: 1%"
                            v-text="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </div>
                  <div v-if="message.type == 3">
                    <p class="text--secondary text-justify">
                      Esta mensagem apenas envia uma notificação de PUSH para os
                      usuários, porém vincula a um VERBETE, conforme selecionado
                      abaixo.
                    </p>
                    <v-autocomplete
                      v-model="message.value"
                      :items="contents"
                      item-text="name"
                      item-value="id"
                      :loading="loadingContents"
                      :search-input.sync="search.content"
                      label="Verbete"
                      no-data-text="Nenhum conteúdo encontrado"
                      :rules="rules.contentRules"
                    >
                      <template slot="selection" scope="data">
                        <v-list-item-avatar size="16">
                          <img
                            v-bind:src="
                              `${$root.url}/resources/` +
                              categories.filter((e) => e.id === data.item.category)[0]
                                .image
                            "
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template slot="item" scope="data">
                        <v-list-item-avatar>
                          <img
                            v-bind:src="
                              `${$root.url}/resources/` +
                              categories.filter((e) => e.id === data.item.category)[0]
                                .image
                            "
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div v-if="message.type == 4">
                    <p class="text--secondary text-justify">
                      Esta mensagem apenas vincula uma URL ao seu conteúdo.
                    </p>
                    <v-text-field
                      v-model="message.value"
                      counter="255"
                      label="URL"
                      :rules="rules.newRules"
                    ></v-text-field>
                  </div>
                  <div v-if="message.type == 5">
                    <p class="text--secondary text-justify">
                      Esta mensagem vincula um texto formatado ao seu conteúdo.
                    </p>
                    <div>
                      <editor
                        id="app-editor"
                        ref="editor"
                        api-key="no-api-key"
                        v-model="message.value"
                        v-on:onInit="onEditorInit"
                        :disabled="saving"
                        :init="initialMCE"
                        v-on:input="onEditorChange"
                      >
                      </editor>
                    </div>
                  </div>

                  <br>
                  <v-card-subtitle class="px-0">PUSH</v-card-subtitle>

                  <p class="text--secondary text-justify">
                    O <b>Título Push</b> é a informação que será apresentada no
                    dispositivo quando o push for apresentado ao usuário.
                  </p>
                  <v-text-field
                    class="pt-0"
                    label="Título Push"
                    counter="64"
                    v-model="message.ptitle"
                    :rules="rules.pushTitleRules"
                  />
                  <v-textarea
                    label="Conteúdo"
                    v-model="message.content"
                    filled
                    counter="10240"
                    :rules="rules.messageRules"
                  />
                </v-col>
              </v-form>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div>
              <v-form ref="form">
                <p class="text--secondary text-justify">
                  Existem instalações sem usuários. Ao selecionar algum filtro abaixo, essas instalações não serão consideradas.
                </p>
                <v-row class="px-4 py-0">
                  <v-col cols="8" class="py-0">                     
                   <v-text-field
                      label="Nome"
                      counter="65"
                      v-model="message.name"
                      @input="seggregationChange()"
                    />
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-select
                      v-model="message.premium"
                      label="Premium"
                      item-text="name"
                      item-value="value"
                      clearable
                      :items="premiums"
                      @input="seggregationChange()"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="px-4">
                  <v-col cols="12" class="py-0">    
                    <v-select
                        label="Áreas"
                        clearable
                        multiple
                        v-model="message.area"
                        :items="areas"
                        :item-text="item=>item.name"
                        :item-value="item=>item.id"
                        @input="seggregationChange()"
                    ></v-select>

                    <v-select
                        label="Especialidade"
                        clearable
                        multiple
                        v-model="message.specialty"
                        :items="specialties"
                        :item-text="item=>item.name"
                        :item-value="item=>item.id"
                        @input="seggregationChange()"                    
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-4 py-0">
                  <v-col cols="12" class="py-0">    
                    <v-select
                          label="Formação"
                          clearable
                          multiple
                          v-model="message.degree"
                          :items="degrees"
                          :item-text="item=>item.name"
                          :item-value="item=>item.value"
                          @input="seggregationChange()"                    
                      ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-4 pt-0">
                  <v-col cols="12">                    
                    <v-select
                        label="Planos"
                        clearable
                        multiple
                        v-model="message.plan"
                        :items="plans"
                        :item-text="item=>item.name + ' - ' + item.sku"
                        :item-value="item=>item.id"
                        @input="seggregationChange()"
                    ></v-select>                    
                  </v-col>
                </v-row>
              </v-form>

              <v-text-field
                  filled
                  rounded
                  dense
                  class="centered-input text--darken-3 mt-3" 
                  :value="users.message"
                  :loading="loadingUsers"
                  readonly
                />
            
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div>
              <v-card-subtitle class="mt-2">O processo de envio de pushs é assíncrono, de modo que você pode fechar essa janela e o envio será continuado.</v-card-subtitle>
              <div class="text-center">
                <v-progress-circular :rotate="360" :size="120" :width="15" :value="process.progress" color="teal"> {{ `${process.progress}%` }} </v-progress-circular>
              </div>
              <v-card-subtitle class="mt-2">Aqui é apresentado um estado geral do envio.</v-card-subtitle>
              <v-row class="mx-2">
                <v-col cols="4">
                    <v-text-field
                      class="pt-0"
                      label="Mensagens enviadas"
                      v-model="process.sent"
                    />
                </v-col>
                <v-col cols="4">
                    <v-text-field
                      class="pt-0"
                      label="Mensagens com sucesso"
                      v-model="process.succeed"
                    />
                </v-col>
                <v-col cols="4">
                    <v-text-field
                      class="pt-0"
                      label="Mensagens com erro"
                      v-model="process.failed"
                    />
                </v-col>
              </v-row>    
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" v-if="step==1" @click="step=2" width="100">Próximo</v-btn>
      <v-btn text color="primary" v-if="step==2" @click="step=1" width="100">Anterior</v-btn>
      <v-btn
        text
        :loading="saving"
        @click="save()"
        :disabled="step==1 || (!valid || saving || waiting || (message.type > 1 && !message.value))"
        width="100">Enviar</v-btn>
      <v-btn text color="primary" @click="close()" width="100">Fechar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import config from "../plugins/tinymce/config/config";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import { v4 as uuid } from "uuid";

export default {
  components: {
    editor: Editor,
  },

  data: () => ({
    message: {},
    valid: false,
    step: 1,

    users: {
      count: 0,
      total: 0,
      message: ""
    },

    search: {
      content: "",
    },

    categories: [],
    specialties: [],
    plans:[],
    areas: [],
    contents: [],

    process: {
      progress: 0,
      sent: 0,
      succeed: 0,
      failed: 0
    },
    interval: {
      fn: ()=>{},
      handler: null,
      stop: false
    },

    waiting: false,
    saving: false,
    loadingContents: false,
    loadingCategories: false,
    loadingSpecialties: false,
    loadingPlans : false,
    loadingAreas: false,
    loadingUsers: false,

    types: [
      { id: 1, value: "Normal" },
      { id: 2, value: "Categoria" },
      { id: 3, value: "Verbete" },
      { id: 4, value: "URL" },
      { id: 5, value: "Text" },
    ],

    premiums:[
      { value: false, name: "Não" },
      { value: true, name: "Sim" }
    ],

    degrees:[
      { value: 1, name: "Estudante" },
      { value: 2, name: "Residente" },
      { value: 3, name: "Técnico" },
      { value: 4, name: "Profissional" }
    ],

    rules: {
      type: [
        (v) => !!v || "O tipo é obrigatório",
      ],
      appTitleRules: [
        (v) => !!v || "O título da app é obrigatório",
        (v) =>
          (v && v.length <= 128) || "O título deve ter no máximo 65 caracteres",
      ],
      pushTitleRules: [
        (v) => !!v || "O título para o push é obrigatório",
        (v) =>
          (v && v.length <= 65) || "O título deve ter no máximo 65 caracteres",
      ],
      messageRules: [
        (v) => !!v || "A menságem é obrigatória",
        (v) => (v && v.length <= 1024 * 10) || "A mensagem é muito grande",
      ],
      contentRules: [(v) => !!v || "O conteúdo é obrigatório"],
      categoryRules: [(v) => !!v || "A categoria é obrigatória"],
      newRules: [(v) => !!v || "O link é obrigatório"],
    },
  }),

  watch: {
    "search.content": _.debounce(function (value) {
      if (value) {
        this.loadingContents = true;

        axios
          .get(`/contents?version=2&search=${value}`)
          .then((e) => {
            this.contents = e.data.data.map((o) => ({
              id: o.id,
              name: o.name,
              category: o.category,
            }));
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loadingContents = false;
          });
      }
    }),
  },

  mounted: function () {
    
    this.loadingCategories = this.loadingSpecialties = this.loadingPlans = this.loadingAreas = true;

    axios
      .get(`/categories?limit=1000`)
      .then((e) => (this.categories = e.data.data))
      .catch((e) => {})
      .finally((e) => {
        this.loadingCategories = false;
      });
    // axios
    //   .get(`/areas/specialties?limit=1000&area=${this.areas.map(e=>e.id).join(',')}`)
    //   .then((e) => (this.specialties = e.data.data))
    //   .catch((e) => {})
    //   .finally((e) => {
    //     this.loadingSpecialties = false;
    //   });
    axios
      .get(`/plans?limit=1000`)
      .then((e) => (this.plans = e.data.data))
      .catch((e) => {})
      .finally((e) => {
        this.loadingPlans = false;
      });
    axios
      .get(`/areas?limit=1000`)
      .then((e) => (this.areas = e.data.data))
      .catch((e) => {})
      .finally((e) => {
        this.loadingAreas = false;
      });

    this.seggregationChange();

    this.dialog = true;
  },

  beforeDestroy: function(){
    this.interval.stop = true;
  },

  computed: {
    initialMCE: function () {
      return config.config;
    },
  },

  methods: {
    close: function(){
      this.interval.stop = true;
      this.$emit('close');
    },

    typeChange: function () {
      this.message.value = "";
    },
    
    seggregationChange: _.debounce(function () {

      this.loadingUsers = true;

      axios.get(`/areas/specialties?limit=1000&area=${(this.message.area||[]).join(',')||"-1"}`)
            .then(obj => {
              this.specialties = obj.data.data;
            });


      Promise.all([
            axios.get(`/users/installations?version=2&limit=0&name=${this.message.name||""}&premium=${this.message.premium==undefined? "":this.message.premium}&specialty=${(this.message.specialty||[]).join(',')||""}&profession=${this.message.profession||""}&degree=${(this.message.degree||[]).join(',')||""}&region=${this.message.region||""}&plan=${(this.message.plan||[]).join(',')||""}&area=${(this.message.area||[]).join(',')||""}`),
            axios.get(`/users/installations?version=2&limit=0`)
          ])
          .then((obj) => {
            this.users.count = obj[0].data.total;
            this.users.total = obj[1].data.total;
            this.users.message = `Foram segregados ${this.users.count} de um total de ${this.users.total} usuários`;
          })
          .catch((e) => {})
          .finally((e) => {
            this.loadingUsers = false;
          });

      console.log(JSON.stringify(this.message))
    }, 500),
    
    onEditorInit: function () {
      this.tinyInited = true;
    },

    onEditorChange: function () {
      this.$forceUpdate();
    },

    save: function(wait) {
      this.step = 3;
      this.saving = true;
      
      //define the id
      this.message.id = uuid();
      axios
        .post("messages?version=1", this.message)
        .then(e => {
          //this.snackbar = true;
          this.waiting = true;

          this.interval.fn = ()=>{
               axios
                .get(`messages/${this.message.id}?version=3`)
                .then(e => {
                  this.process.progress = e.data.data.progress || 0;
                  this.process.sent = e.data.data.sent || 0;
                  this.process.failed = e.data.data.failed || 0;
                  this.process.succeed = e.data.data.succeed || 0;
                  if(this.process.progress == 100) this.interval.stop = true; //if the progress is 100%, stop the loop
                }).finally(e =>{
                  if(!this.interval.stop) this.interval.handler = setTimeout(this.interval.fn, 1000);
                });
          };
          this.interval.stop = false;
          this.interval.handler = setTimeout(this.interval.fn, 1000);
          
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.saving = false;
        });      
    }
  },
};
</script>

<style>
    .centered-input input {
      text-align: center
    }
</style>