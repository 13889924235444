<template>
  <v-container style="postion:relative" id="entries">
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <v-data-table
      :loading="loading"
      loading-text="Aguarde..."
      :headers="headers"
      :items="entries"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      @click:row="click"
      :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click.stop="publishDialog.object=item; publishDialog.content = $route.params.id; publishDialog.show=true;"
          color="red"
          :disabled="!$root.canAccess('DELETE', '/contents/1')"
          v-if="item.draft"
        >mdi-publish</v-icon>
        <v-icon small class="mr-2" @click="view(item)">mdi-eye</v-icon>
        <v-icon small class="mr-2" @click="edit(item)" v-if="item.draft">edit</v-icon>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <div class="copyable" v-on:click.stop="copy(item)">{{ item.id }}</div>
      </template>
      <template v-slot:[`item.draft`]="{ item }">
        <span>{{ item.draft? "sim": "-" }}</span>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{item.date | moment("DD/MM/YYYY HH:mm:ss")}}</span>
      </template>
      <template v-slot:[`item.published`]="{ item }">
        <span>{{item.published | moment("DD/MM/YYYY HH:mm:ss")}}</span>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="2000">Id copiado</v-snackbar>

    <v-dialog v-model="publishDialog.show" width="500px" height="200px">
      <content-publish 
        v-on:close="publishDialog.show = false"
        v-on:reload="reload()"
        :content="publishDialog.content"
        :revision="publishDialog.object"
      ></content-publish>
    </v-dialog>

    <v-dialog v-model="draftDialog.show" width="500px" height="200px">
      <v-card>
        <v-card-title class>Rascunho</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p style="padding-bottom:0px">Ao confirmar, um novo rascunho será gerado.</p>
          <p style="padding-bottom:0px">Tem certeza que deseja criar esse rascunho?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="blue"
            :loading="draftDialog.loading"
            @click="draft()"
            width="180"
          >Criar Rascunho</v-btn>
          <v-btn text color="primary" @click="draftDialog.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import pick from "object.pick";
import Subscription from "rxjs";
import moment from "moment";
import { v4 as uuid } from "uuid";
import * as storage from "../libs/storage";
import ContentPublish from "../components/Content.Publish";

const { PollUntil } = require("poll-until-promise");

const omitEmpty = require("omit-empty");

Vue.use(VueLodash);

export default {
  components: {
    ContentPublish
  },

  data: () => ({
    items: [],

    valid: false,
    loading: false,
    saving: false,
    search: "",
    snackbar: false,
    subscriptions: [],

    dialog: { create: false, import: false },
    publishDialog: { content: null, show: false, object: {}, loading: false, },
    draftDialog: { show: false, object: {}, loading: false },

    entries: [],
    category: "",
    specialty: "",
    empty: "",

    options: {
      page: 1,
      itemsPerPage: 10
    },
    total: 0,
    headers: [
      {
        text: "VERSÃO",
        align: "center",
        width: "80px",
        value: "version",
        sortable: false
      },
      { text: "NOME", value: "name" },
      { text: "CRIADO", width: "180px", align: "left", value: "date" },
      { text: "PUBLICADO", width: "180px", align: "left", value: "published" },
      {
        text: "LINKS",
        width: "80px",
        align: "center",
        sortable: false,
        value: "links"
      },
      {
        text: "AÇÕES",
        value: "action",
        align: "center",
        width: "170px",
        sortable: false
      }
    ]
  }),

  watch: {
    options: {
      async handler() {
        this.reload();
      },
      deep: true
    },
    category: {
      async handler() {
        this.options.page = 1;
        this.reload();
      }
    },
    specialty: {
      async handler() {
        this.options.page = 1;
        this.reload();
      }
    },
    empty: {
      async handler() {
        this.options.page = 1;
        this.reload();
      }
    }
  },

  mounted: function() {
    this.$root.title = "Revisões";
    this.$root.show({ editnew: true });
    this.$root.actions.editnew.disable = !this.$root.canAccess(
      "PATCH",
      "/contents/1"
    );

    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(
      this.$root.actions.editnew.event.subscribe(e => {
        this.draftDialog.show = true;
      })
    );

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("revisions")) || {}, [
        "search",
        "options"
      ])
    );

    this.$data.loading = true;

    axios
      .get(`/contents/${this.$route.params.id}`)
      .then(e => {
        this.items = [
          {
            text: "Verbetes",
            disabled: false,
            href: "/entries"
          },
          {
            text: e.data.data.name,
            disabled: false,
            href: `/entries/${e.data.data.id}/view`
          },
          {
            text: "Revisões",
            disabled: true
          }
        ];
      })
      .catch(e => {})
      .finally(e => {
        this.loading = false;
        this.load(0, this.$data.options.itemsPerPage);
      });
  },

  methods: {
    copy: async function(item) {
      await this.$copyText(item.id);
      this.snackbar = true;
    },
    click: function(item) {
      this.view(item);
    },
    reload: function() {
      var sort = this.options.sortBy
        .filter(e => !!e)
        .map((e, i) => `${this.options.sortDesc[i] ? "-" : ""}${e}`)
        .join(",");

      var limit = this.options.itemsPerPage;
      var start = (this.options.page - 1) * limit;

      this.load(start, limit, sort);
    },
    load: function(start, limit, sort) {
      this.loading = true;

      var filter = function(field, value) {
        return value === null || value === undefined
          ? ""
          : "&" + field + "=" + value;
      };

      axios
        .get(
          `/contents/${
            this.$route.params.id
          }/revisions?version=1&start=${start}&limit=${limit}${filter(
            "sort",
            sort
          )}`
        )
        .then(e => {
          this.entries = e.data.data;
          this.total = e.data.total;
        })
        .catch(e => {})
        .finally(e => {
          this.loading = false;
        });
    },
    edit: function(item) {
      router.push(`/entries/${item.content}/revisions/${item.id}/edit`);
    },

    view: function(item) {
      router.push(`/entries/${item.content}/revisions/${item.id}/view`);
    },
    links: function(item) {
      router.push(`/entries/${item.content}/links`);
    },
    draft: function() {
      this.draftDialog.loading = true;

      axios
        .patch(`/contents/${this.$route.params.id}`, { op: "draft" })
        .then(e => {
          this.$dialog.notify.info("Nova revisão para rascunho criada", {
            position: "top-right",
            timeout: 5000
          });
          this.draftDialog.show = false;
          this.reload();
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.draftDialog.loading = false;
        });
    },
    publish: function() {
      this.publishDialog.loading = true;

      axios
        .patch(`/contents/${this.$route.params.id}`, {
          op: "publish",
          version: this.publishDialog.object.version
        })
        .then(e => {
          this.publishDialog.show = false;
          this.$dialog.notify.info("Conteúdo publicado com sucesso!", {
            position: "top-right",
            timeout: 5000
          });

          this.reload();
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.publishDialog.loading = false;
        });
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("revisions", JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
#entries {
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

  .v-skeleton-loader__image {
    height: 80vh !important;
  }
}

#dialog {
  .tox-tinymce {
    height: calc(100%) !important;
  }
}
</style>