<template>
  <v-container id="editor">
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <v-tabs fixed-tabs>
      <v-tab key=web>Web</v-tab>
      <v-tab key=smartphone>Smartphone</v-tab>
      
      <v-tab-item key=web>
        <v-container>
          <p v-html="entry.html"></p>
        </v-container>          
      </v-tab-item>
      <v-tab-item key=smartphone>
        <div class="smartphone" style="margin-top:40px">
          <div class="content" id="content">
            <p v-html="entry.html" style="width:100%;border:none;height:100%"></p>
          </div>
        </div>
      </v-tab-item>
  
    </v-tabs>     

     <v-dialog
         v-model="publishDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Publicar</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao publicar esta revisão, o conteúdo será disponibilizado para os aplicativos.</p> 
              <p style="padding-bottom:0px">Tem certeza que deseja publicar esta revisao?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="publishDialog.loading" @click="publish()" width="180">Publicar Revisão</v-btn>
               <v-btn text color="primary" @click="publishDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
    </v-dialog>
    <v-dialog
        v-model="removeDialog.show"
        width="500px"
        height="200px"
        >
        <v-card>
          <v-card-title class="">Remover</v-card-title>
          <v-card-text style="padding-bottom:0px">
            <p style="padding-bottom:0px">Ao remover o conteúdo, não será mais possível restaurar. Aqui será removido uma <b>REVISÃO</b>. O conteúdo publicado não será afetado.</p> 
            <p style="padding-bottom:0px">Tem certeza que deseja remover esta revisao?</p>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
              <v-btn text color="error"  :loading="removeDialog.loading" @click="remove()" width="180">Remover Revisão</v-btn>
              <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import { mask } from 'vue-the-mask'
import { diff } from "deep-diff";

import masker from 'vue-the-mask/src/masker';
import { tokens } from 'vue-the-mask'

function unmask (masked, mask) {
  return masker(masked, mask, false, tokens)
}

export default {
  directives:  {
    mask,
  },
  data: () => ({

    items: [],

    iframe: {
      style: null
    },

    parent: {},

    removeDialog: { show: false, loading: false },
    publishDialog: { show: false, loading: false },
    subscriptions: [],

    loading: {
      retrieving: false,
      removing: false
    },
    entry: { },
  
  }),
  
  computed: {
    hasChanged() {
      return !!diff(this.old, {name: this.$data.name,phone: unmask(this.$data.phone, this.$data.mask), email: this.$data.email});
    }
  },

  watch: {
    options: {
      async handler () {
       
      },
      deep: true
    }
  },

  mounted: function () {
    this.$root.title = "Revisão";
    this.loading.retrieving = true;
    this.$root.show({});
    
    //subscribe to action
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.edit.event.subscribe(e => {
      this.edit();
    }));
    this.$data.subscriptions.push(this.$root.actions.publish.event.subscribe(e => {
        this.publishDialog.show = true;
    }));
    this.$data.subscriptions.push(this.$root.actions.remove.event.subscribe(e => {
        this.removeDialog.show = true;
    }));

    axios
      .get(`/contents/${this.$route.params.id}/revisions/${this.$route.params.revision}?version=1`)
      .then(e => {
        this.$data.entry = e.data.data;
        var entry = e.data.data;

        this.items= [{
                text: 'Verbetes',
                disabled: false,
                href: '/entries',
              },
              {
                text: "...",
                disabled: false,
                href: `/entries/${e.data.data.id}/view`
              },{
                text: 'Revisões',
                disabled: false,
                href: `/entries/${this.$route.params.id}/revisions`
              },{
                text: e.data.data.version,
                disabled: false
              }];
    
        //only show edit and history if it is draft
        this.$root.title = "Revisão #" + entry.version;
        this.$root.show({edit: entry.draft, publish: entry.draft, remove: entry.draft});
        this.$root.actions.edit.disable = !this.$root.canAccess('PATCH', '/contents/1/revisions/1');
        this.$root.actions.publish.disable = !this.$root.canAccess('DELETE', '/contents/1');
        this.$root.actions.remove.disable = !this.$root.canAccess('DELETE', '/contents/1/revisions/1');
    
        this.$data.entry.html = `<h1>${entry.name}</h1>` +
                                `<p class="overline">${(entry.specialty && entry.specialty.name) || '-'}, ${entry.category && entry.category.name}</p><br><br>` + 
                                entry.value + 
                                `<br><br>` +
                                `<div style="align-items:flex-end; display:flex;flex-direction:column;">` +
                                `<p><i>${ (entry.authors||[]).map(e => e.name).join(', ') }</i></p>` +
                                `<br><br>` +
                                `</div>` +
                                `<div>`+
                                `  <p style="margin-bottom: 0px"><b>palavras-chave</b> - ${ (entry.keywords||[]).join(', ') }</p>`+
                                `</div>`+
                                `<div>`+
                                `  <p style="margin-bottom: 0px"><b>também conhecido como</b> - ${ (entry.synonyms||[]).join(', ') }</p>`+
                                `</div>`;
      })
      .finally(e => {
        this.$data.loading.retrieving = false;
      });

    axios.get(`/contents/${this.$route.params.id}?version=2`)
      .then(e => {
        this.parent = e.data.data
        if (this.items[1]) {
          this.items[1].text = this.parent.name
        }
      })
  },

  methods: {
    back: function(){
      router.push('/entries');
    },
    remove: function() {
      this.removeDialog.loading = true;

      axios
        .delete(`/contents/${this.$route.params.id}/revisions/${this.$route.params.revision}`)
        .then(e => {
          this.removeDialog.show = false;
          router.push(`/entries/${this.$route.params.id}/revisions`);
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(e => {
          this.removeDialog.loading = false;
        });
    },
    edit: function() {
      router.push(`/entries/${this.$route.params.id}/revisions/${this.$route.params.revision}/edit`)
    },
    publish: function() {
      //this.loading.removing = true;
      this.publishDialog.loading = true;

      axios
        .patch(`/contents/${this.$route.params.id}`, {op: 'publish', version: this.entry.version})
        .then(e => {
          this.publishDialog.show = false;
          this.$dialog.notify.info('Conteúdo publicado com sucesso!', {
            position: 'top-right',
            timeout: 5000
          });

          router.push(`/entries/${this.$route.params.id}/revisions`);
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(e => {
          this.publishDialog.loading = false;
        });
    }
  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];
    next();
  }
};
</script>
<style lang="scss"> 
    #editor{

    .smartphone {
        position: relative;
        width: 360px;
        height: 640px;
        margin: auto;
        border: 16px black solid;
        border-top-width: 60px;
        border-bottom-width: 60px;
        border-radius: 36px;
      }

      /* The horizontal line on the top of the device */
      .smartphone:before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 10px;
      }

      /* The circle on the bottom of the device */
      .smartphone:after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 50%;
      }

      /* The screen (or content) of the device */
      .smartphone .content {
        width: 100%;
        height: 100%;
        background: white;
      }


      /* The device with borders */
      .tablet {
        position: relative;
        width: 768px;
        height: 1024px;
        margin: auto;
        border: 16px black solid;
        border-top-width: 60px;
        border-bottom-width: 60px;
        border-radius: 36px;
      }

      /* The horizontal line on the top of the device */
      .tablet:before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 10px;
      }

      /* The circle on the bottom of the device */
      .tablet:after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 50%;
      }

      /* The screen (or content) of the device */
      .tablet .content {
        overflow: auto;
        width: 100%;
        height: 100%;
        background: white;
        margin: -1px;
      }


      #content {
        h1 {
          padding-bottom: 15px;
        }
        h2 {
          padding-bottom: 15px;
        }
        h3 {
          padding-bottom: 15px;
        }

        ul {
          li {
            padding-bottom: 5px;
          }
          padding-bottom: 15px;
        }
        ol {
          li {
            padding-bottom: 5px;
          }
          padding-bottom: 15px;
        }

        p {
          padding-bottom: 15px;
        }

        table {
          p {
            padding-bottom: 0px;
            margin-bottom: 0px !important;
          }
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 20px;
        }

        tr {
            border-bottom: 1px solid #ddd;
        }
        th, td {
          text-align: left;
          padding: 8px;
          background-color: #f2f2f2
        }

        img {
          max-width: 100%;
          display:block;
          margin-left:auto;
          margin-right:auto;
        }
      }
    
    }

 </style>