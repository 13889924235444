<template>
  <v-container id="editor">

    <div v-if="loading">
      <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="article">
      </v-skeleton-loader>
      <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="article">
      </v-skeleton-loader>
       <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="article">
      </v-skeleton-loader>
      <br>
      <br>
      <div style="display:flex;isplay: flex;justify-content: flex-end">
        <v-skeleton-loader
            width="20%"
            :loading="true"
            :transition="'fade-transition'"
            type="text">
        </v-skeleton-loader>
      </div>
      <br>
      <br>
      <v-skeleton-loader
          :loading="true"
          :transition="'fade-transition'"
          type="sentences">
      </v-skeleton-loader>
      <br>
      <br>
      <div style="display:flex; justify-content: flex-end">
          <v-skeleton-loader
            style="margin: 5px"
            :loading="true"
            :transition="'fade-transition'"
            type="button">
          </v-skeleton-loader>
          <v-skeleton-loader
            style="margin: 5px"
            :loading="true"
            :transition="'fade-transition'"
            type="button">
          </v-skeleton-loader>
      </div>
      <br>
      <br>
    </div>
    
    <div v-if="!loading">
      
      <h2>{{user.name}}</h2>
      <p class="overline">{{user.type == 2? "autor" : "revisor"}}</p>
      <br>
      <br>
      <p v-html="user.curriculum"></p>

      <br>
      <br>
      <br>
      <v-row align="end">
        <v-col class="text-right">
          <v-btn text width="120px" @click="edit()">EDITAR</v-btn>
          <v-btn text width="120px" @click="back()">VOLTAR</v-btn>
        </v-col>
      </v-row>
      <br>      
    </div>

  </v-container>
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import { mask } from 'vue-the-mask'
import { diff } from "deep-diff";
import { tokens } from 'vue-the-mask'


export default {
  directives:  {
  },
  data: () => ({
    loading: true,
    user: {
      name: ''
    },
  }),
  
  computed: {
  },

  watch: {
    options: {
      async handler () {
       
      },
      deep: true
    }
  },

  mounted: function () {
    this.$root.title = "Currículo";
    this.$data.loading = true;

    axios
      .get(`/users/${this.$route.params.id}`)
      .then(e => {
        this.$data.user = e.data.data;
      })
      .catch(e => {

      })
      .finally(e => {
        this.$data.loading = false;
      });
  },

  methods: {
      back: function(){
        router.push('/users');
      },

      edit: function() {
        router.push(`/users/${this.$data.user.id}/curriculum/edit`)
      }
  }
};
</script>
<style lang="scss"> 
    #editor{
      
      #content {
        h1 {
          padding-bottom: 15px;
        }
        h2 {
          padding-bottom: 15px;
        }
        h3 {
          padding-bottom: 15px;
        }

        ul {
          li {
            padding-bottom: 5px;
          }
          padding-bottom: 15px;
        }
        ol {
          li {
            padding-bottom: 5px;
          }
          padding-bottom: 15px;
        }

        p {
          padding-bottom: 15px;
        }

        table {
          p {
            padding-bottom: 0px;
            margin-bottom: 0px !important;
          }
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 20px;
        }

        tr {
            border-bottom: 1px solid #ddd;
        }
        th, td {
          text-align: left;
          padding: 8px;
          background-color: #f2f2f2
        }

        img {
          max-width: 100%;
          display:block;
          margin-left:auto;
          margin-right:auto;
        }
      }

    }
 </style>