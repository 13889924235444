var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%"}},[(_vm.loader.opening)?_c('v-card',[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start"}},_vm._l((16),function(i){return _c('v-skeleton-loader',{key:i,staticStyle:{"margin":"2px","width":"45px"},attrs:{"loading":true,"transition":'fade-transition',"type":"button"}})}),1),_c('v-divider'),_c('v-skeleton-loader',{attrs:{"loading":true,"transition":'fade-transition',"type":"article"}}),_c('v-skeleton-loader',{attrs:{"loading":true,"transition":'fade-transition',"type":"article"}}),_c('v-skeleton-loader',{attrs:{"loading":true,"transition":'fade-transition',"type":"article"}}),_c('br'),_c('br'),_c('div',{staticStyle:{"display":"flex","isplay":"flex","justify-content":"flex-end"}},[_c('v-skeleton-loader',{attrs:{"width":"20%","loading":true,"transition":'fade-transition',"type":"text"}})],1),_c('br'),_c('br'),_c('v-skeleton-loader',{attrs:{"loading":true,"transition":'fade-transition',"type":"sentences"}}),_c('br'),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('v-skeleton-loader',{staticStyle:{"margin":"5px"},attrs:{"loading":true,"transition":'fade-transition',"type":"button"}}),_c('v-skeleton-loader',{staticStyle:{"margin":"5px"},attrs:{"loading":true,"transition":'fade-transition',"type":"button"}})],1),_c('br'),_c('br')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loader.opening),expression:"!loader.opening"}],staticStyle:{"height":"100%"}},[_c('editor',{ref:"editor",staticStyle:{"height":"calc(100% - 100px)"},attrs:{"api-key":"no-api-key","disabled":_vm.loader.saving,"initial-value":_vm.user.curriculum,"init":{
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
        alignleft aligncenter alignright alignjustify | table image | \
        bullist numlist outdent indent | removeformat | help',
      images_upload_url: 'postAcceptor.php'
     }},on:{"input":_vm.onChange}}),_c('br'),_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"disabled":!_vm.changed,"loading":_vm.loader.saving,"text":"","width":"120px"},on:{"click":function($event){return _vm.save()}}},[_vm._v("SALVAR")]),_c('v-btn',{attrs:{"text":"","width":"120px"},on:{"click":function($event){return _vm.back()}}},[_vm._v("VOLTAR")])],1)],1),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }