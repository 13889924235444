<template>
  <v-card>
    <v-card-title> Aplicar Voucher </v-card-title>
    <v-container>
        <v-card-subtitle class="mt-2">Escolha o voucher para vincular ao usuário <b>{{ user.name }}</b>. Abaixo estão os vouchers que você pode selecionar para este usuário.</v-card-subtitle>
                  
        <v-form v-model="valid" ref="form">
          <v-col cols="12">

              <v-autocomplete
                v-model="voucher"
                :items="vouchers"
                item-text="name"
                item-value="id"
                return-object
                :loading="loading"
                :search-input.sync="search.vouchers"
                label="Vouchers"
                no-data-text="Nenhum voucher encontrado"
                :rules="rules.voucher"
              >
                <template slot="selection" scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.code"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <template slot="item" scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.code"></v-list-item-title>
                    <v-list-item-subtitle>{{formatType(data.item.type)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
          </v-col>
        </v-form>

        <v-card-subtitle class="px-2">VOUCHERS DESTE USUÁRIO</v-card-subtitle>

        <v-card-subtitle class="px-2">
          Aqui são os vouchers que este usuário já possui.
        </v-card-subtitle>

        <v-data-table
          :headers="myVouchers.headers"
          :items="myVouchers.data"
          :loading="myVouchers.loading"
          :hide-default-footer="true"
          no-data-text="Usuário sem vouchers"
        >
          <template v-slot:[`item.type`]="{ item }">
            <span>{{ formatType(item.type) }}</span>
          </template>
          <template v-slot:[`item.endsAt`]="{ item }">
            <span>{{ format(item.endsAt) }}</span>
          </template>
        
        </v-data-table>
  
    </v-container>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        :loading="saving"
        @click="save()"
        :disabled="!valid || saving"
        width="100"
        >Usar</v-btn
      >
      <v-btn text color="primary" @click="$emit('close')" width="100"
        >Fechar</v-btn
      >
    </v-card-actions>

    <v-snackbar v-model="snackbar" :timeout="2000">Token copiado</v-snackbar>
    

  </v-card>
  
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  components: {
  },

  props: ['show', 'user'],

  data: () => ({
    
    loading: false,
    valid: false,
    snackbar: false,

    search: {
      content: "",
    },

    
    voucher: {},
    vouchers: {},
    
    myVouchers: {
      headers: [
        { text: 'TIPO', value: 'type', width: '160px' },
        { text: 'FIM', value: 'endsAt', width: '100px', sortable: false },
        { text: 'CÓDIGO', value: 'code' }
      ],
      data: [],
      loading: false
    },
    
    saving: false,

    types: [
      { value: 1, name: "Duração Fixa" },
      { value: 2, name: "Duração Relativa" }
    ],

    rules: {
      voucher: [(v) => !!v || "O voucher é obrigatório"],
    },
  }),

  watch: {
    "show": { 
          immediate: true,
          handler(newVal, oldVal) {

            this.voucher = null;
            this.myVouchers.data = [];
            
            if (newVal){
              this.vouchers.loading = true;
              this.loading = true;
              
              Promise.all([axios.get(`/vouchers?version=1&limit=1000`), axios.get(`/vouchers?version=2&user=${this.user.id}`)])
                      .then(e => {
                        this.vouchers = e[0].data.data;
                        this.myVouchers.data = e[1].data.data;
                      })
                      .catch((e) => {})
                      .finally((e) => {
                        this.loading = false;
                        this.myVouchers.loading = false;
                      });
            }
          }
    },
    "search.vouchers": _.debounce(function (value) {
      if (value) {
        this.loading = true;

        axios
          .get(`/vouchers?version=1&code=${value}`)
          .then((e) => {
            this.vouchers = e.data.data;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }),
  },


  updated: function () {
    this.$nextTick(function () {
   
    })
  },

  mounted: function () {
    
    // this.loadingInstallations = true;
    // this.loadingCategories = true;
    
    // Promise.all([axios.get(`/categories?limit=1000`), axios.get(`/users/installations?user=${this.user.id}`)])
    //        .then(e => {
    //          this.categories = e[0].data.data;
    //          this.installation.data = e[1].data.data;
    //        })
    //       .catch((e) => {})
    //       .finally((e) => {
    //         this.loadingInstallations = false;
    //         this.loadingCategories = false;
    //       });

    // this.dialog = true;
  },

  computed: {
  },

  methods: {
    format(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    formatType(type){
      return this.types.filter(e=> e.value==type)[0].name
    },
    
    save: function(wait) {
      this.saving = true;
      
      //add the user
      //this.voucher = this.user.id;
      axios
        .patch(`vouchers/${this.voucher.id}`, {op: 'use', user: this.user.id})
        .then(e => {
          this.snackbar = true; 
          this.$emit('close');
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.saving = false;
        });      
    }
  },
};
</script>

<style>
</style>