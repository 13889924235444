<template>
  <v-container style="postion:relative">
    <br />
    <br />
    <v-data-table
      :loading="loading"
      loading-text="Aguarde..."
      :headers="headers"
      :items="categories"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      @click:row="category"
      :footer-props="{
         'items-per-page-options': [5, 10, 20, 30, 40, 50]
         }"
    >
      <template v-slot:[`item.id`]="{ item }">
        <div
          class="copyable"
          v-on:click.stop="$event.stopPropagation(); copy(item.id)"
        >{{ item.id }}</div>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <v-avatar size="36">
          <img v-bind:src="`${url}/resources/` + item.image" />
        </v-avatar>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div>{{ formatType(item.type) }}</div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-1"
          @click.stop="changeAccess(item.id, 'premium')"
        >mdi-lock</v-icon>
        <v-icon
          small
          class="mr-1"
          @click.stop="changeAccess(item.id, 'freemium')"
        >mdi-lock-open-outline</v-icon>
        <v-icon
          small
          color="red"
          class="mr-1"
          @click="$event.stopPropagation(); remove(item.id, true)"
          :disabled="!$root.canAccess('DELETE', 'categories')"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="2000">Id copiado</v-snackbar>
    <v-snackbar v-model="snackbars.create" :timeout="2000">Categoria criada!</v-snackbar>

    <v-dialog v-model="removeDialog.show" width="500px" height="200px">
      <v-card>
        <v-card-title class>Remover Categoria</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p style="padding-bottom:0px">Ao remover a categoria, não será mais possível restaurá-la. Tem certeza que deseja remover esta categoria?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="error"
            :loading="removeDialog.loading"
            @click="remove(removeDialog.id, false)"
            width="100"
          >Confirmar</v-btn>
          <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createDialog.show"
      width="600px"
    >
      <v-card>
        <v-card-title>Nova categoria</v-card-title>
        
        <v-card-text>
          <v-form v-model="form">
          <v-subheader class="px-0">Preencha os campos abaixo para criar uma nova categoria.</v-subheader>          
            <v-row class="py-0">
              <v-col class="py-0">
                <v-text-field
                  label="Nome"
                  v-model="newCategory.name"
                  autocomplete="off"
                  :rules="rules.name"
                />
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  label="Peso"
                  type="number"
                  autocomplete="off"
                  :rules="rules.weight"
                  v-model="newCategory.weight"
                />
              </v-col>                
            </v-row>
            <v-row class="py-0">
              <v-col class="py-0">
                <v-combobox
                    v-model="newCategory.parent"
                    :items="parents.data"
                    :loading="parents.loading"
                    hide-no-data
                    item-text="name"
                    item-value="id"
                    :return-object="true"
                    @update:search-input="searchParents($event)"
                    label="Pai"
                  ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0">
                <v-select
                  label="Tipo"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  v-model="newCategory.type"
                  :rules="rules.required"
                >
                </v-select>
              </v-col>
              <v-col cols="3" class="py-0">
                <v-select
                  label="Ativa"
                  :items="selectItems"
                  item-text="name"
                  item-value="value"
                  v-model="newCategory.active"
                >
                </v-select>
              </v-col>
              <v-col class="py-0">
                <v-select
                  label="Possui Especialidades"
                  :items="selectItems"
                  item-text="name"
                  item-value="value"
                  v-model="newCategory.specialties"
                >
                </v-select>
              </v-col>            
            </v-row>

            <v-subheader>MODO CLARO</v-subheader>
            <v-row>
              <v-col cols="8" class="py-0" >
                <v-file-input
                  accept="image/*"
                  @change="upload($event, 'light')"
                  :rules="rules.image"
                  counter
                  show-size
                >
                  <template v-slot:prepend-inner>
                    <v-avatar size="24">
                      <img v-if="lightImage.url" :src="lightImage.url">
                      <v-progress-circular size="24" indeterminate width="2" v-if="lightImage.file"></v-progress-circular>
                    </v-avatar>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Cor"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="newCategory.color"
                      :rules="rules.color"
                    >
                      <template v-slot:prepend-inner>
                        <v-avatar :color="newCategory.color || 'primary'" size="16" class="mr-4"> 
                        </v-avatar>
                      </template>
                    </v-text-field>
                  </template>
                  <v-color-picker
                    mode="hexa"
                    v-model="newCategory.color"
                  >
                  </v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-subheader>MODO ESCURO</v-subheader>
            <v-row>
              <v-col cols="8" class="py-0">
                <v-file-input
                  accept="image/*"
                  @change="upload($event, 'dark')"
                  :rules="rules.image"
                  counter
                  show-size
                >
                  <template v-slot:prepend-inner>
                    <v-avatar size="24">
                      <img v-if="darkImage.url" :src="darkImage.url">
                      <v-progress-circular size="24" indeterminate width="2" v-if="darkImage.file"></v-progress-circular>
                    </v-avatar>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Cor"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="newCategory.colorDark"
                      :rules="rules.color"
                    >
                      <template v-slot:prepend-inner>
                        <v-avatar :color="newCategory.colorDark || 'primary'" size="16" class="mr-4"> 
                        </v-avatar>
                      </template>
                    </v-text-field>
                  </template>
                  <v-color-picker
                    mode="hexa"
                    v-model="newCategory.colorDark"
                  >
                  </v-color-picker>
                </v-menu>
              </v-col>
            </v-row> 
          </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :disabled="!form" @click="create()" :loading="createDialog.loading">CRIAR</v-btn>
          <v-btn text color="primary" @click="createDialog.show=false">CANCELAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="600px" v-model="accessDialog.show">
      <access-confirmation v-on:close="accessDialog.show = false" v-on:save="saveAccess()" :category="accessDialog.category" :type="accessDialog.type" :key="$root.componentKey"></access-confirmation>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import pick from "object.pick";
import moment from "moment";
import * as storage from '../libs/storage'
import AccessConfirmation from '../components/Access.Confirmation.vue'

Vue.use(VueLodash);

export default {
  components: {
    AccessConfirmation
  },

  data: () => ({
    name: "",
    snackbar: false,
    snackbars: {
      create: false,
    },

    partnerTypes: {
      1: "Padrão",
      2: "Secad",
      3: "Empresa"
    },

    accessDialog: {
      show: false,
      category: '',
      type: ''
    },

    parents: {
      loading: false,
      data: []
    },

    newCategory: {
      name: '',
      type: 0,
      weight: "0",
      active: false,
      specialties: false,
      parent: null,
      color: '',
      colorDark:''
    },

    lightImage: {},
    darkImage: {},

    selectItems: [
      {value: true, name: 'Sim'},
      {value: false, name: 'Não'}
    ],

    types: [
      {value: 1, name: "Padrão"},
      {value: 2, name: "Artigos/Notícias"},
      {value: 3, name: "Calculadoras"},
      {value: 4, name: "Orientações Medicamentosas"},
      {value: 5, name: "CID"},
    ],

    dialog: false,
    removeDialog: { show: false, loading: false, id: null },
    createDialog: { show: false, loading: false, id: null },
    saving: false,
    loading: false,
    subscriptions: [],

    rules: {
      name: [
        v => !!v || "Nome é obrigatório",
        v => (v && v.length <= 64) || "O nome deve ter no máximo 64 caracteres"
      ],
      image: [
        v => !!v || "A imagem é obrigatória",
        v => (v && v.size < 1000000) || "O tamanho máximo é 1 MB."
      ],
      color: [
        v => !!v || 'A cor é obrigatória.'
      ],
      weight: [
        v => !!v || 'Obrigatório.',
        v => (v && v <= 255) || "Max. 255",
        v => (v && v >= 0) || "Min. 0"
      ],
      required: [
        v => !!v || 'Campo obrigatório',
      ]
    },

    form: false,

    categories: [],
    plans: [],

    options: {
      page: 1,
      itemsPerPage: 10
    },

    total: 0,

    headers: [
      { text: "", value: "image", width: "48px", sortable: false },
      { text: "NOME", value: "name", sortable: false },
      { text: "TIPO", value: "type", width: "60px", sortable: false },
      { text: "PESO", value: "weight", sortable: false },
      { text: "ID", value: "id", width: "320px", sortable: false },
      { text: "AÇÕES", value: "action", width: "100px", sortable: false }
    ]
  }),

  watch: {
    
    'createDialog.show': {
      async handler() {
        this.searchParents();
      },
      deep: true
    },
    options: {
      async handler() {
        this.load();
      },
      deep: true
    },
  },

  computed: {
    selectedPlans: function() {
      return this.plans.filter(e => this.newPartnership.plans.includes(e.id));
    },
  },

  mounted: function() {
    this.$root.title = "Categorias";
    this.$root.show({ create: true });
    this.$root.actions.create.disable = !this.$root.canAccess('POST', '/categories/');
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(
      this.$root.actions.create.event.subscribe(e => {
        this.open();
      })
    );

    this.load();
  },

  methods: {
    dateFormat: function(str) {
      return moment(str).format("DD/MM/YYYY, H:mm");
    },

    category: function(item) {
      router.push(`/categories/${item.id}`);
    },

    open: function() {
      this.createDialog.show = true  
    },

    copy: async function(item) {
      await this.$copyText(item);
      this.snackbar = true;
    },

    formatType(type) {
      if (type) {
        const obj = this.types.filter(e => e.value == type)[0]
        return obj.name
      }
      return "-"
    },

    remove: function(id, dialog) {
      if (dialog) {
        this.removeDialog.show = true;
        this.removeDialog.id = id;
      } else {
        this.removeDialog.loading = true;

        axios
          .delete(`/categories/${id}`)
          .then(e => {
            this.removeDialog.show = false;
            this.load();
          })
          .catch(e => {})
          .finally(e => {
            this.removeDialog.loading = false;
          });
      }
    },

    create() {
      this.createDialog.loading = true
      const image = uuid()
      const imageDark = uuid()

      const lightform = new FormData()
      lightform.append("resource", this.lightImage.file);
      lightform.append("name", this.lightImage.file.name);

      const darkform = new FormData()
      darkform.append("resource", this.darkImage.file);
      darkform.append("name", this.darkImage.file.name);

      Promise.all([
        axios.put(`resources/${image}`, lightform, {headers: {'Content-Type': 'multipart/form-data'}}),
        axios.put(`resources/${imageDark}`, darkform, {headers: {'Content-Type': 'multipart/form-data'}})
      ])
        .then(res => {
          Object.assign(this.newCategory, {
            id: uuid(),
            parent: (this.newCategory && this.newCategory.id) || null,
            image: image,
            imageDark: imageDark,
            color: this.newCategory.color.slice(0, 7),
            colorDark: this.newCategory.colorDark.slice(0, 7),
            weight: parseInt(this.newCategory.weight),
          })
          axios.post(`categories`, this.newCategory)
            .then(res =>  {
              this.snackbars.create = true
              this.createDialog.show = false
            })
            .catch(err => {
              this.$dialog.notify.error(this.$codes(err), {
                position: "top-right",
                timeout: 5000
              });
            })
            .finally(() => {
              this.createDialog.loading = false
            })
        })
    },

    debounce: _.debounce(function() {
      this.load();
    }, 500),

    searchParents: _.debounce(function(e) {
      if (e) {
        this.parents.loading = true;
        axios
          .get(`/categories?version=1&name=${e}&limit=20`)
          .then(e => {
            this.parents.data = e.data.data;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000
            });
          })
          .finally(() => {
            this.parents.loading = false;
          });
      }
    }, 500),
    
    load: function() {
      this.loading = true;
      const start = (this.options.page - 1) * this.options.itemsPerPage
      const limit = this.options.itemsPerPage

      axios
        .get(`/categories?version=1&start=${start}&limit=${limit}`)
        .then(e => {
          this.categories = e.data.data;
          this.total = e.data.total;
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.loading = false;
        });
    },

    getPlans: function() {
      axios
        .get(`plans?sort=-created`)
        .then(e => {
          this.plans = e.data.data;
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",

            timeout: 5000
          });
        });
    },

    formatPlan: function(item) {
      return `${item.name} (${item.sku})`;
    },

    upload(file, type) {
      if (type == 'light') Object.assign(this.lightImage, {file: file, url: ''})
      else Object.assign(this.darkImage, {file: file, url: ''})

      if (file) {
        const reader = new FileReader()

        reader.readAsArrayBuffer(file)
        reader.onload = (e) => {
          const result = reader.result
          const base = new Uint8Array(result)
            .reduce((data, byte) => data + String.fromCharCode(byte), "")

          const str = btoa(base)
          const url = `data:image/jpg;charset=utf-8;base64, ${str}`

          if (type == 'light') Object.assign(this.lightImage, {url: url})
          else Object.assign(this.darkImage, {url: url})
        }
      }
    },

    changeAccess: function(category, type) {
      this.accessDialog.type = type
      this.accessDialog.category = category
      this.accessDialog.show = true
    },

    saveAccess: function() {
      this.$root.openSnackbar('Acesso da categoria alterado com sucesso.')
      this.accessDialog.show = false
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("plans", JSON.stringify(this.$data));
    next();
  },

};
</script>

<style lang="scss">
.copyable {
  &:hover {
    color: rebeccapurple;
    cursor: pointer;
  }
}
</style>