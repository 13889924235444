<template>
  <v-expansion-panels flat class="mb-6" v-model="panel">
    <v-expansion-panel @click.prevent="toggle()">
      <v-form>
        <v-expansion-panel-header>
          <v-row style="padding: 0px 20px 0px 0px">
            <v-col class="flex-grow-1 flex-shrink-1">
              <v-text-field
                v-model="filters.name"
                prepend-icon="mdi-magnify"
                label="Busca"
                persistent-hint
                :hint="panel === undefined ? description : ''"
                clearable
                @input="reload()"
                @click.stop
              ></v-text-field>
            </v-col>
            <v-col class="flex-grow-0 flex-shrink-0" style="min-width: 200px">
              <v-select
                height="32"
                label="Pesquisa"
                v-model="filters.mode"
                :items="modeOptions"
                item-text="name"
                item-value="value"
                @input="reload()"
                @click.stop
                @click.native.stop
              ></v-select>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-btn icon @click.stop="toggle()">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-container style="padding: 0px">
            <v-row>
              <v-col class="py-0">
                <v-select
                  height="32"
                  label="Categorias"
                  clearable
                  v-model="filters.category"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :loading="loaders.categories"
                >
                  <template slot="selection" scope="data">
                    <v-list-item-avatar size="16">
                      <img v-bind:src="`${url}/resources/` + data.item.image" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                      <img v-bind:src="`${url}/resources/` + data.item.image" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="width: 1%"
                        v-text="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <v-col class="py-0">
                <v-select
                  height="32"
                  label="Especialidade"
                  clearable
                  v-model="filters.specialty"
                  :loading="loaders.specialties"
                  :items="specialties"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                ></v-select>
              </v-col>
              <v-col class="py-0">
                <v-autocomplete
                  v-model="filters.author"
                  :items="authors"
                  :search-input.sync="search.author"
                  item-text="name"
                  item-value="id"
                  no-data-text="Nenhum autor encontrado"
                  label="Autores"
                >
                  <template slot="selection" scope="data">
                    <v-chip close @click:close="deleteAuthor()">
                      <v-avatar left>
                        <img v-if="data.item.picture" v-bind:src="`${url}/resources/${data.item.picture}`"/>
                        <img v-else src="./../assets/user.png">
                      </v-avatar>
                      {{data.item.name}}
                    </v-chip>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                        <img v-if="data.item.picture" v-bind:src="`${url}/resources/${data.item.picture}`"/>
                        <img v-else src="./../assets/user.png">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                </v-autocomplete>
              </v-col>              
            </v-row>
            <v-row>
              <v-col class="py-0" cols="2">
                <v-select
                  height="32"
                  label="Conteúdo"
                  v-model="filters.empty"
                  :items="emptyOptions"
                  clearable
                  item-text="name"
                  item-value="value"
                  @change="reload"
                ></v-select>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-select
                  height="32"
                  label="Rascunhos"
                  v-model="filters.draft"
                  :items="draftsOptions"
                  clearable
                  item-text="name"
                  item-value="value"
                  @change="reload"
                ></v-select>
              </v-col>
              <v-col class="py-0" cols="2">
                <v-select
                  height="32"
                  label="Premium"
                  v-model="filters.premium"
                  :items="premiumOptions"
                  clearable
                  item-text="name"
                  item-value="value"
                  @change="reload"
                ></v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <v-select
                  height="32"
                  label="Contrato"
                  clearable
                  v-model="filters.contract"
                  :loading="loaders.contracts"
                  :items="contracts"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                ></v-select>
              </v-col>    
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-form>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import axios from "axios";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
const omitEmpty = require("omit-empty");

export default {
  name: "content-filter",

  props: {
    filters: {
      name: "",
      author: "",
      category: "",
      specialty: "",
      empty: "",
      draft: "",
      mode: "",
      premium: "",
    },
  },

  data: () => ({
    panel: undefined,

    loaders: {
      categories: false,
      specialties: false,
      authors: false,
    },

    categories: [],
    specialties: [],
    contracts: [],
    authors: [],

    search: {
      author: "",
    },


    description: "",

    modeOptions: [
      { name: "Pesquisa App", value: 1 },
      { name: "Pesquisa Título", value: 2 },
      { name: "Pesquisa Conteúdo", value: 3 },
    ],

    emptyOptions: [
      { name: "Vazio", value: true },
      { name: "Não vazio", value: false },
    ],

    draftsOptions: [
      { name: "Com rascunhos", value: true },
      { name: "Sem rascunhos", value: false },
    ],

    premiumOptions: [
      { name: "Sim", value: true },
      { name: "Não", value: false },
    ],
  }),

  computed: {
    url: function () {
      return axios.defaults.baseURL;
    },
  },

  watch: {
    panel: function (value) {
      this.description = this.createDescription();
    },

    filters: {
      deep: true,
      handler: function () {
        this.reload();
      },
    },

    "search.author": _.debounce(function (value) {
      if (value) {
        this.loadingContents = true;

        axios
          .get(`/users?version=3&name=${value}&type=2,3&limit=20`)
          .then((e) => {
             this.authors = e.data.data;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loadingContents = false;
          });
      }
    }),
  },

  mounted: function () {
    this.loaders.categories = true;
    this.loaders.specialties = true;
    this.loaders.contracts = true;
    this.loaders.authors = true;

    Promise.all([
      axios
        .get(`/categories?limit=1000`)
        .then((e) => (this.categories = e.data.data)),
      axios
        .get(`/specialties?limit=1000`)
        .then((e) => (this.specialties = e.data.data)),
      axios
        .get(`/contracts?limit=1000`)
        .then((e) => (this.contracts = e.data.data)),
      axios
        .get(`/users?type=2&version=3&limit=20`)
        .then((e) => (this.authors = e.data.data)),        
    ])
      .then(() => {
        this.description = this.createDescription();
      })
      .finally((e) => {
        this.loaders.categories = false;
        this.loaders.specialties = false;
        this.loaders.contracts = false;
        this.loaders.authors = false;
      });
  },

  methods: {
    reload: _.debounce(function () {
      const filters = omitEmpty(this.filters);
      this.$emit("requestChange", uuid());
      this.$emit("reload", filters);
    }, 500),

    createDescription: function () {
      var descriptions = [];

      var empty = (value) =>
        value === undefined || value === "" || value === null;

      if (
        !empty(this.filters.category) &&
        this.categories.some((e) => e.id == this.filters.category)
      ) {
        descriptions.push(
          this.categories.filter((e) => e.id == this.filters.category)[0].name
        );
      }
      if (
        !empty(this.filters.specialty) &&
        this.specialties.some((e) => e.id == this.filters.specialty)
      ) {
        descriptions.push(
          this.specialties.filter((e) => e.id == this.filters.specialty)[0].name
        );
      }
      if (!empty(this.filters.empty)) {
        descriptions.push(
          this.filters.empty ? "apenas vazios" : "apenas com conteúdo"
        );
      }
      if (!empty(this.filters.draft)) {
        descriptions.push(
          this.filters.draft ? "apenas com rascunhos" : "apenas sem rascunhos"
        );
      }
      if (
        !empty(this.filters.contract) &&
        this.contracts.some((e) => e.id == this.filters.contract)
      ) {
        descriptions.push(
          this.contracts.filter((e) => e.id == this.filters.contract)[0].name
        );
      }

      return descriptions.join(", ");
    },

    deleteAuthor() {
      this.filters.author = null
    },        

    toggle() {
      //this will toggle only by icon click. at the same time, will prevent toggle by clicking on header.
      const curr = this.panel;
      this.panel = curr === undefined ? 0 : undefined;
    },
  },
};
</script>

<style>
</style>