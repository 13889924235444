<template>

  <v-container id="editor">
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <v-tabs fixed-tabs>
      <v-tab key=web v-if="current && (current.type==1 || current.type==2 || current.type==4)">Web</v-tab>
      <v-tab key=smartphone v-if="current && (current.type==1 || current.type==2 || current.type==4)">Smartphone</v-tab>
      <v-tab key=edit>Cadastro</v-tab>
      <v-tab key=links>Links</v-tab>
      <v-tab key=history>Histórico</v-tab>
      
      <v-tab-item key=web v-if="current && (current.type==1 || current.type==2 || current.type==4)">
        <v-container>
          <p id="content" v-html="entry.html"></p>
        </v-container>          
      </v-tab-item>

      <v-tab-item key=smartphone v-if="current && (current.type==1 || current.type==2 || current.type==4)">
        <div class="smartphone" style="margin-top:40px">
          <div class="content" id="content">
            <p v-html="entry.html" style="width:100%;border:none;height:100%"></p>
          </div>
        </div>
      </v-tab-item>

      
      <v-tab-item key=edit>

        <p class="text--secondary text-justify" style="padding-left:5px; padding-top:36px; padding-bottom:20px">Essas informações serão mudadas direto em PRODUÇÃO. Alterações feitas aqui não terão nenhuma REVISÃO e não serão versionadas. As modificações feitas aqui e salvas afetarão as buscas feitas pelo aplicativo ou editor de forma imediata.</p>

        <v-form ref="form" v-model="valid">
          <v-container>
            
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  label="Liberação"
                  :items="liberations"
                  item-value="value"
                  item-text="name"
                  v-model="current.premium"
                  :loading="loadingForm"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Pesquisável"
                  :items="searchables"
                  item-value="value"
                  item-text="name"
                  v-model="current.searchable"
                  :loading="loadingForm"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="current.type == 1">
             
              <v-col cols="12" sm="6">
                <v-select
                  height="41"
                  label="Especialidades"
                  v-model="current.specialties"
                  :items="specialties"
                  clearable
                  multiple
                  :item-text="item=>item.name"
                  :item-value="item=>item.id"
                  :loading="loadingForm"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  label="Categorias"
                  v-model="current.category"
                  :items="categories"                  
                  :item-text="item=>item.name"
                  :item-value="item=>item.id"
                  :loading="loadingForm"
                >
                  <template slot="selection" scope="data">
                    <v-list-item-avatar size="20">
                      <img v-bind:src="`${url}/resources/`+data.item.image"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                      <img v-bind:src="`${url}/resources/`+data.item.image"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="[1, 4].indexOf(current.type) >= 0">
              <v-col cols="12" md="12">
                <v-select
                  height="32"
                  label="Contrato"
                  v-model="current.contract"
                  :items="contracts"
                  persistent-hint
                  hint="Note que ao mudar o contrato, a baseline será mudada para a revisão mais recente publicada."
                  :item-text="item=>item.name"
                  :item-value="item=>item.id"
                  :loading="loadingForm"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-combobox
                  ref="synonymsBox"
                  v-model="current.synonyms"
                  :items="synonyms"
                  label="Sinônimo"
                  chips
                  deletable-chips
                  multiple
                  append-outer-icon="mdi-open-in-new"
                  @click:append-outer="open('synonyms')"
                  :loading="loadingForm"
                ></v-combobox>
              </v-col>          
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="current.links"
                  :items="links"
                  :item-text="item => item.name"
                  :item-value="item => item.id"
                  :loading="loadingForm || loading.links"
                  
                  @update:search-input="searchlinks($event)"
                  
                  :no-filter="true"
                  hide-selected
                  hide-no-data


                  label="Página relacionada"
                  no-data-text="Nenhuma página encontrada"
                  multiple
                >
                 <template slot="selection" scope="data">
                    <v-chip close @click:close="deleteLink(data.item)">
                      <v-avatar left>
                        <img v-if="categoryImage(data.item)" v-bind:src="`${url}/resources/`+categoryImage(data.item)"/>
                        <!-- <img v-else src="../assets/user.png"/> -->
                      </v-avatar>
                      {{data.item.name}}
                    </v-chip>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                        <img v-if="categoryImage(data.item)" v-bind:src="`${url}/resources/`+categoryImage(data.item)"/>
                        <!-- <img v-else src="../assets/user.png"/> -->
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-combobox
                  ref="keywordsBox"
                  v-model="current.keywords"
                  :items="keywords"
                  label="Palavra-chave"
                  chips
                  deletable-chips
                  multiple
                  append-outer-icon="mdi-open-in-new"
                  @click:append-outer="open('keywords')"
                  :loading="loadingForm"
                ></v-combobox>
              </v-col>
            </v-row>
             <v-row v-if="current.type == 4">
              <v-col cols="12" md="6" style="padding-left:10px;padding-right:10px">
                <v-combobox
                  v-model="current.substance1"
                  :items="substances1"
                  :loading="loading.substances1 || loadingForm"
                  :disabled="true"
                  hide-no-data
                  item-text="name"
                  item-value="name"
                  :return-object="false"
                  label="Primeria Substância"
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" md="6"  style="padding-left:10px;padding-right:10px">
                  <v-combobox
                    v-model="current.substance2"
                    :items="substances2"
                    :loading="loading.substances2 || loadingForm"
                    :disabled="true"
                    hide-no-data
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    label="Segunda Substância"
                  >
                  </v-combobox>
              </v-col>
            </v-row>
            <v-row v-if="current.type==3">
              <v-col cols="12">
                <v-autocomplete
                  v-model="current.parent"
                  :items="parents"
                  :loading="loading.parents || loadingForm"
                  hide-no-data
                  hide-selected
                  :disabled="true"
                  item-text="name"
                  item-value="id"
                  label="Nível superior"
                >
                </v-autocomplete>
              </v-col>          
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="current.authors"
                  :items="authors"
                  :disabled="true"
                  :item-text="item=>item.name"
                  :item-value="item=>item.id"
                  label="Autores"
                  multiple
                  :loading="loadingForm"
                >
                  <template slot="selection" scope="data">
                    <v-chip>
                      <v-avatar left>
                        <img v-if="data.item.picture" v-bind:src="`${url}/resources/${data.item.picture}`"/>
                        <img v-else src="./../assets/user.png">
                      </v-avatar>
                      {{data.item.name}}
                    </v-chip>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                        <img v-if="data.item.picture" v-bind:src="`${url}/resources/${data.item.picture}`"/>
                        <img v-else src="./../assets/user.png">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                </v-autocomplete>
              </v-col>          
            </v-row>

          </v-container>
        </v-form>

      </v-tab-item>
  
      <v-tab-item key=links>
         <p class="text--secondary text-justify" style="padding-left:5px; padding-top:36px; padding-bottom:20px">Aqui são apresentados todos os conteúdos que apontam para este conteúdo aqui. Todo conteúdo que tiver, em seu CADASTRO, uma página relacionada que aponte para este conteúdo aqui, ele sera apresentado nesta lista abaixo.</p>

         <v-data-table
            :loading="loading.reloading" 
            loading-text="Aguarde..."
            :headers="headers"
            :items="linksIn"
            no-data-text="Sem resultados"

            
            :options.sync="options"
            :server-items-length="total"
        
            @click:row="view"

            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            
        >
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="view(item)">mdi-eye</v-icon>
        </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item key="history">
        <v-container>
          <p class="text--secondary text-justify" style="padding-left:5px; padding-top:36px; padding-bottom:20px">Aqui aparecem todas as alterações feitas nesta revisão do verbete.</p>
          <v-data-table
            :loading="loading.reloading"
            :headers="historyHeaders"
            :items="history"
            no-data-text="Sem resultados"
            :options.sync="historyOptions"
            :server-items-length="historyTotal"
            :footer-props="{
              'items-per-page-options': [5, 10, 20, 30, 40, 50]
            }"
          >
            <template v-slot:[`item.action`]="{ item }">
              <span>{{ actionTypes[item.action] }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <span>{{ moment(item.date).format('DD/MM/YYYY - HH:mm') }}</span>
            </template>
            <template v-slot:[`item.user`]="{ item }">
              <span>{{ item.user.name }}</span>
            </template>
            <template v-slot:[`item.value`]="{ item }">
              <v-icon
                v-if="item.value"
                small
                @click="openHistoryDialog(item)"
              >mdi-eye</v-icon>
            </template>
          </v-data-table>
        </v-container>
      </v-tab-item>
    </v-tabs>

    <v-dialog
        v-model="draftDialog.show"
        width="500px"
        height="200px"
        >
        <v-card>
            <v-card-title class="">Rascunho</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao confirmar, um novo rascunho será gerado.</p> 
              <p style="padding-bottom:0px;padding-top:0px" v-if="entry.drafts && entry.drafts.length > 1">Este verbete já tem {{entry.drafts.length}} rascunhos.</p> 
              <p style="padding-bottom:0px;padding-top:0px" v-if="entry.drafts && entry.drafts.length == 1">Este verbete tem um rasunho no momento.</p> 
              <p style="padding-bottom:0px">Tem certeza que deseja criar esse rascunho?</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn text color="blue"  :loading="draftDialog.loading" @click="draft()" width="180">Criar Rascunho</v-btn>
              <v-btn text color="primary" @click="draftDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Remover</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover o conteúdo, não será mais possível restaurar. Note que aqui será removido o <b>VERBETE</b> e não uma revisão.</p>
              <p style="padding-bottom:0px">Tem certeza que deseja remover este conteúdo? </p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove()" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.show"
        width="500px"
        height="1900px"
        >
        <v-card>
          <v-card-title class="">
              {{dialog.title}}
          </v-card-title>
            <v-card-text style="padding-bottom:0px">
            <p style="padding-bottom:0px">Aqui os itens são separados por nova linha. Cada novo item adicionado ou removido daqui será alterado no campo original.</p>
            </v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row class="mx-2">
                  <v-col cols="12">
                        <v-textarea
                          rows="10"
                          label="Itens"
                          v-model="dialog.current"
                          multiline
                          prepend-icon="mdi-calendar-text"
                          />
                  </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-card-actions>
              <v-spacer/>
              <v-btn text :disabled="!hasDialogChanged" @click="dialog.save()" width="100">Salvar</v-btn>
              <v-btn text color="primary" @click="dialog.show = false" width="100">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="historyDialog.show" :width="dialogWidth">
      <changes-history :key="$root.componentKey" :changes="historyDialog.changes" :user="historyDialog.user" v-on:close="historyDialog.show = false"></changes-history>
    </v-dialog>

  </v-container>
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import { mask } from 'vue-the-mask'
import { diff } from "deep-diff";
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import ChangesHistory from '../components/Changes.History.vue'
import masker from 'vue-the-mask/src/masker';
import { tokens } from 'vue-the-mask'

function unmask (masked, mask) {
  return masker(masked, mask, false, tokens)
}

export default {
  directives:  {
    mask,
  },
  components: {
    ChangesHistory
  },
  data: () => ({

    loadingForm: false,

    valid: false,
    items: [],

    iframe: {
      style: null
    },

    dialog: { show: false, save: function(){}, title: '', current: [], old: []},
    removeDialog: { show: false, loading: false },
    draftDialog: { show: false, object: {}, loading: false },
    
    subscriptions: [],

    history: [],
    historyHeaders: [
      {text: 'USUÁRIO', value: 'user', sortable: false},
      {text: 'AÇÃO', value: 'action', sortable: false, width: '150px'},
      {text: 'DATA', value: 'date', sortable: false, width: '200px'},
      {text: 'VALOR', value: 'value', sortable: false, align: 'center', width: '50px'},
    ],

    old: {},

    current: {
      
      category: {
        name: ""
      },
      specialties: [],
      contract: {
        name: ""
      },
      synonyms: [],
      keywords: [],
      
      authors: [],

    },

    specialties: [],
    categories: [],
    contracts: [],
    synonyms: [],
    keywords: [],
    links: [],
    authors: [],
    parents: [],
    substances1: [],
    substances2: [],


    loading: {
      retrieving: false,
      removing: false,
      reloading: false,
      links: false,
    },
    entry: { },
    linksIn: [],

    options: {
      page: 1,
      itemsPerPage: 10
    },
    total: 0,
    historyTotal: 0,
    headers: [      
      { text: 'NOME', value: 'name' },
      { text: 'CATEGORIA', width: '180px', value: 'category' },
      { text: 'ESPECIALIDADE', width: '180px', value: 'specialties' },
      { text: 'CONTEÚDO', width: '80px', align: 'left', sortable: false, value: 'empty'},
      { text: 'ID', width: '300px', align: 'left', sortable: false, value: 'id'},
      { text: 'AÇÕES', value: 'action', width: '120px', sortable: false },
    ],

    liberations: [
      {value: true, name: 'Premium'},
      {value: false, name: 'Freemium'},
    ],
    
    searchables: [
      {value: true, name: 'Deve vir na pesquisa'},
      {value: false, name: 'Não deve vir na pesquisa'},
    ],

    actionTypes: {
      1: 'Criação', 
      2: 'Atualização', 
      3: 'Remoção'
    },

    historyDialog: {
      show: false,
      changes: {},
      user: undefined,
    },

    historyOptions: {
      page: 1,
      itemsPerPage: 10
    }
  }),
  
  computed: {
    dialogWidth: function() {
      return this.historyDialog.changes && this.historyDialog.changes.value ? "800px" : "400px"
    },

    moment() {
      return moment
    },

    hasChanged() {
      var changes = diff(this.old, this.current);
      return !!changes;
    },

    hasDialogChanged() {
      var changes = diff(this.dialog.old, this.dialog.current);
      return !!changes;
    },
 
  },

  watch: {
    options: {
      async handler () {
        this.reload();
      },
      deep: true
    },
    historyOptions: {
      async handler () {
        this.reload();
      },
      deep: true
    },
    hasChanged: { 
      handler(changed){
        this.$root.actions.save.disable = !changed;    
      }, 
      deep: true
    },
  },

  mounted: function () {
        
    this.$root.title = "Verbete";
    this.loading.retrieving = true;

    this.$root.show({save: true, editnew: true, remove: true, history: true, download: true});
    this.$root.actions.editnew.disable = !this.$root.canAccess('PATCH', '/contents/1');
    this.$root.actions.remove.disable = !this.$root.canAccess('DELETE', '/contents/1');
    this.$root.actions.save.disable = true;
    this.url = axios.defaults.baseURL;

    window.addEventListener('beforeunload', this.unsubscribe)
    this.subscriptions.filter(e => e).forEach(e => {e.unsubscribe()});
    //console.log(this.$root.actions.save)
    this.subscriptions.push(
      !this.$root.actions.save.event.observers.length ? this.$root.actions.save.event.subscribe(e => { this.save() }) : null,
      !this.$root.actions.editnew.event.observers.length ? this.$root.actions.editnew.event.subscribe(e => { this.draftDialog.show = true }) : null,
      !this.$root.actions.remove.event.observers.length ? this.$root.actions.remove.event.subscribe(e => { this.removeDialog.show=true }) : null,
      !this.$root.actions.history.event.observers.length ? this.$root.actions.history.event.subscribe(e => { this.revisions() }) : null,
      !this.$root.actions.download.event.observers.length ? this.$root.actions.download.event.subscribe(e => { this.download() }) : null,
    )

    this.reload();
},

  methods: {
    back: function(){
      router.push('/entries');
    },
    openHistoryDialog: function(item) {
      this.historyDialog.show = true
      this.historyDialog.user = item.user.name
      this.historyDialog.changes = JSON.parse(item.value)
    },
    categoryImage: function(item) {
      let category = this.categories.filter(e => e.id == item.category)[0]
      if (!category) category = this.categories.filter(e => e.name == item.category)[0]
      return (category && category.image) || null
    },
    remove: function() {
      this.removeDialog.loading = true;

      axios
        .delete(`/contents/${this.$route.params.id}`)
        .then(e => {
          this.removeDialog.show = false;
          router.push('/entries');
        })
        .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              timeout: 5000
            });
        })
        .finally(e => {
          this.removeDialog.loading = false;
        });
    },
    edit: function() {
      router.push(`/entries/${this.$data.entry.id}/edit`)
    },
    view: function(item) {
      router.push(`/entries/${item.id}/view`);
    },
    revisions: function() {
      router.push(`/entries/${this.$data.entry.id}/revisions`)
    },
    draft: function(){
        this.draftDialog.loading = true;
        var id = uuid();

        axios
          .patch(`/contents/${this.$route.params.id}`, {op: 'draft', id: id})
          .then(e => {
            this.$dialog.notify.info('Nova revisão para rascunho criada', {
              position: 'top-right',
              timeout: 5000
            });
            this.draftDialog.show = false;
            //route to new draft
            router.push(`/entries/${this.$route.params.id}/revisions/${id}/edit`);

          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              timeout: 5000
            });
          })
          .finally(e => {
            this.draftDialog.loading = false;
          });
      },
      reload: function(){
        var sort = "";
        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(start, limit, sort);
      },

      unsubscribe() {
        this.subscriptions.filter(e => e).forEach(e => e.unsubscribe())
      },      
      
      load: function(start, limit, sort){
        this.loading.reloading = true;
        this.loadingForm = true

        const history = {
          start: (this.historyOptions.page - 1) * this.historyOptions.itemsPerPage,
          limit: this.historyOptions.itemsPerPage
        }

        Promise.all([
          axios.get(`/contents/${this.$route.params.id}?version=2`),
          axios.get(`/categories?limit=1000`),
          axios.get(`/specialties?limit=1000`),
          axios.get(`/contracts?limit=1000`),
          axios.get(`/contents/${this.$route.params.id}/changes?version=2&start=${history.start}&limit=${history.limit}`)
        ])
        .then(e => {
          e[0].data.data.authors = e[0].data.data.authors || [];
          e[0].data.data.synonyms = e[0].data.data.synonyms || [];
          e[0].data.data.keywords = e[0].data.data.keywords || [];
          e[0].data.data.value = e[0].data.data.value || "";
          e[0].data.data.links = e[0].data.data.links || [];
          
          var entry = this.clone(e[0].data.data);
          this.entry = entry;

          this.current = e[0].data.data;
          
          this.current.category = this.current.category && this.current.category.id;
          this.current.contract = this.current.contract && this.current.contract.id;
          this.current.parent = this.current.parent && this.current.parent.id;
          this.current.specialties = this.current.specialties && this.current.specialties.map(e => e.id);
          this.current.authors = this.current.authors.map(e => e.id);

          this.current.substance1 = this.current.substance1 && this.current.substance1.name;
          this.current.substance2 = this.current.substance2 && this.current.substance2.name; 
            
          this.onSubstanceChange();

          this.categories = e[1].data.data;
          this.specialties = e[2].data.data;
          this.contracts = e[3].data.data;
          this.history = e[4].data.data
          this.historyTotal = e[4].data.total

          this.old = this.clone(this.current)
          this.current = this.clone(this.old);

          // this.old.category = this.current.category;
          // this.old.specialty = this.current.specialty;

          this.$root.actions.save.disable = true;

          this.items= [{
              text: 'Verbetes',
              disabled: false,
              href: '/entries',
            },
            {
              text: entry.name,
              disabled: true,
            }];

          this.entry.html = entry.value;

          this.loading.links = true;
          this.loading.authors = true;
          this.loading.parents = true;

          return Promise.all([
            axios.get(`/contents?id=${this.current.links.concat('-1').join(',')}&limit=1000&empty=true,false`),
            axios.get(`/users?id=${this.current.authors.concat('-1').join(',')}&limit=1000`),
            axios.get(`/contents?version=1&id=${this.current.parent}&limit=1&empty=true,false&type=3`)
          ]);
        
        })
        .then(e => {
            this.loading.links = false
            this.loading.authors = false
            this.loading.parents = false

            this.links = e[0].data.data;
            var users = e[1].data.data;

            this.authors = this.current.authors.map(a => users.filter(u => u.id==a)[0] ) //preserve the order
            this.parents = e[2].data.data;

            this.current.links = e[0].data.data.map(e => e.id);
            this.current.authors = this.authors.map(e => e.id);

            this.old = this.clone(this.current)
        })      
        .catch(e => {

        })
        .finally(e => {
          this.$data.loading.retrieving = false;
          this.loading.links = false;
          this.loading.authors = false;
          this.loadingForm = false
        });

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/contents/${this.$route.params.id}/links?version=2&direction=in&start=${start}&limit=${limit}${filter('sort', sort)}`)
          .then(e => {
            this.linksIn = e.data.data.map(e => Object.assign(e, {empty: e.empty?'-':'Sim'}));
            this.total = e.data.total;
          })
          .catch(e => {

          })
          .finally(e => {
            this.loading.reloading = false;
          });
      },
      download: function() {
        var app = (localStorage.getItem('app') && JSON.parse(localStorage.getItem('app') || '{}')) || (router.push('/login'));
        var win = window.open(`${axios.defaults.baseURL}/contents/${this.$data.entry.id}?version=5&x-app=${app.code}`,"_blank");
        win.focus();
      },
      open: function(type){
          if (type == 'keywords'){
            this.dialog.show = true;
            this.dialog.title = 'Palavras-chave';
            this.dialog.current = this.current.keywords.join('\n');
            this.dialog.old = this.dialog.current;
            this.dialog.save = ()=> {
              this.$refs.keywordsBox.setValue(this.dialog.current.split('\n'))
              this.dialog.show = false;
            }
          } else if (type == 'synonyms') {
            this.dialog.show = true;
            this.dialog.title = 'Conhecido como';
            this.dialog.current = this.current.synonyms.join('\n');
            this.dialog.old = this.dialog.current;
            this.dialog.save = ()=> {
              this.$refs.synonymsBox.setValue(this.dialog.current.split('\n'))
              this.dialog.show = false;
            }
          }
      },
      deleteAuthor(item) {
        var object = this.current.authors.filter(e => e == item.id);
        const index = this.current.authors.indexOf( object[0] )
        this.current.authors.splice(index, 1)
      },

      deleteLink(item) {
        var object = this.current.links.filter(e => e == item.id);
        const index = this.current.links.indexOf( object[0] )
        this.current.links.splice(index, 1)   
      },
      save: function() {

        this.$root.actions.save.loading = true;
        
        let obj = {op: "update"}
        const changes = diff(this.old, this.current) || []
        const keys = Array.from(
          new Set(
            changes
              .filter(e => e.kind != 'D')
              .map(e => e.path[0])
          )
        )
        keys.forEach((e) => {
          Object.assign(obj, { [e]: this.current[e] });
        });

        axios
        .patch(`/contents/${this.$route.params.id}`, obj)
        .then(e => {
          this.changed = false;

          this.old = this.clone(this.current)
          this.current = this.clone(this.old);

          this.$dialog.notify.info('Conteúdo salvo com sucesso', {
            position: 'top-right',
            timeout: 2000
          });
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(e => {
          this.$root.actions.save.loading = false;
          this.reload()
        });
    },

    searchlinks: _.debounce(function(e) {
      if (e) {
        this.loading.links = true;
        axios
          .get(`/contents?version=1&name=${e}`)
          .then(e => {
            
            if (this.current.links) {
              let remove = e.data.data.filter(e => this.current.links.includes(e.id))
              remove.forEach((i) => e.data.data.splice(e.data.data.indexOf(i), 1))

              remove = this.links.filter(e => !this.current.links.includes(e.id))
              remove.forEach((i) => this.links.splice(this.links.indexOf(i), 1))
            }

            this.links = e.data.data.map(o => ({id: o.id, name: o.name, category: o.category})).concat(this.links);
            
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              timeout: 5000
            });
          })
          .finally(() => {
            this.loading.links = false;
          });
      }
    }, 300),    
    
    clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    onSubstanceChange(){
      //if (this.current.type == 4) this.current.name = `Interação entre ${this.current.substance1 || "-"} e ${this.current.substance2 || "-"}` 
    },
  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions
      .filter(e => e)
      .forEach(e => {e.unsubscribe()});

    this.$data.subscriptions = [];
    next();
  }


};
</script>
<style lang="scss"> 
    #editor{

    .smartphone {
        position: relative;
        width: 360px;
        height: 640px;
        margin: auto;
        border: 16px black solid;
        border-top-width: 60px;
        border-bottom-width: 60px;
        border-radius: 36px;
      }

      /* The horizontal line on the top of the device */
      .smartphone:before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 10px;
      }

      /* The circle on the bottom of the device */
      .smartphone:after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 50%;
      }

      /* The screen (or content) of the device */
      .smartphone .content {
        overflow: auto;
        width: 100%;
        height: 100%;
        background: white;
      }


      /* The device with borders */
      .tablet {
        position: relative;
        width: 768px;
        height: 1024px;
        margin: auto;
        border: 16px black solid;
        border-top-width: 60px;
        border-bottom-width: 60px;
        border-radius: 36px;
      }

      /* The horizontal line on the top of the device */
      .tablet:before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 10px;
      }

      /* The circle on the bottom of the device */
      .tablet:after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 50%;
      }

      /* The screen (or content) of the device */
      .tablet .content {
        overflow: auto;
        width: 100%;
        height: 100%;
        background: white;
        margin: -1px;
      }

      #content {
        h1 {
          padding-bottom: 15px;
        }
        h2 {
          padding-bottom: 15px;
        }
        h3 {
          padding-bottom: 15px;
        }

        ul {
          li {
            padding-bottom: 5px;
          }
          padding-bottom: 15px;
        }
        ol {
          li {
            padding-bottom: 5px;
          }
          padding-bottom: 15px;
        }

        p {
          padding-bottom: 15px;
        }

        table {
          p {
            padding-bottom: 0px;
            margin-bottom: 0px !important;
          }
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 20px;
        }

        tr {
            border-bottom: 1px solid #ddd;
        }
        th, td {
          text-align: left;
          padding: 8px;
          background-color: #f2f2f2
        }

        img {
          max-width: 100%;
          display:block;
          margin-left:auto;
          margin-right:auto;
        }
      }

    }
 </style>