<template>
  <v-card>
    <v-card-title>Criar Credencial</v-card-title>

    <v-card-text>
      <v-form v-model="form">
        <v-row>
          <v-col>
            <v-text-field
              label="Email"
              v-model="alias.email"
              :rules="rules.required"
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Nome de usuário"
              v-model="credentials.username"
              :rules="rules.required"
            />
          </v-col>
          <v-col>
            <password-input v-model="credentials.password" :rules="rules.required" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="create()" :loading="loading" :disabled="!form">CRIAR</v-btn>
      <v-btn text color="primary" @click="$emit('close')">CANCELAR</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import PasswordInput from "./Password.Input";
import { v4 as uuid } from 'uuid';

export default {
  name: "credential-create",

  components: {
    PasswordInput
  },

  props: {
    user: Object
  },

  data: () => ({
    credentials: {},
    form: false,
    loading: false,
    rules: {
      required: [v => !!v || "Campo obrigatório."]
    },
    alias: {
        email: '',
    }
  }),

  methods: {
    async create() {
      this.loading = true;

      const cred = this.credentials;
      const id = uuid()
      Object.assign(cred, {id: id})
      
      const al = this.alias
      Object.assign(al, {
          name: this.user.name,
          source: {
            id: id,
            name: 'cmr'
          }
      })

      await axios.post(`credentials?version=2`, cred)
      axios
        .post(`users/${this.user.id}/aliases`, al)
        .then(res => {
          this.$emit("success");
          this.$emit("close");
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>