<template>
   <v-container style="postion:relative">
      <div>
         <v-spacer></v-spacer>
         <v-row>
            <v-col cols="12" md="12">
               <v-text-field
                  v-model="name"
                  prepend-icon="mdi-magnify"
                  label="Busca"
                  v-on:input="debounce"
                  ></v-text-field>
            </v-col>
         </v-row>
      </div>
      <br><br>
      <v-data-table
         :loading="loading" 
         loading-text="Aguarde..."
         :headers="headers"
         :items="partnerships"
         no-data-text="Sem resultados"
         :options.sync="options"
         :server-items-length="total"
         @click:row="partnership"
         :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"   
         >

         <template v-slot:[`item.id`]="{ item }">
            <div class="copyable" v-on:click.stop="$event.stopPropagation(); copy(item.id)">{{ item.id }}</div>
         </template>
         <template v-slot:[`item.created`]="{ item }">
            <div>{{ dateFormat(item.created) }}</div>
         </template>
         <template v-slot:[`item.default`]="{ item }">
            <div>{{ item.default ? 'Sim' : '-' }}</div>
         </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-chip
              v-if="item.active"
              small
              color="green"
              text-color="white"
              label
            >ATIVA</v-chip>
            <v-chip
              v-else
              x-small
              small
              label 
            >INATIVA</v-chip>
         </template>
         <template v-slot:[`item.type`]="{ item }">
            <div>{{ partnerTypes[item.type] }}</div>
         </template>
         <template v-slot:[`item.action`]="{ item }">
            <v-icon 
              small
              class="mr-2"
              color="red"
              @click="$event.stopPropagation(); remove(item.id, true)"
            >
              mdi-delete
            </v-icon>
         </template>

         
      </v-data-table>
      <v-snackbar v-model="snackbar" :timeout="2000">
         Id copiado
      </v-snackbar>
      <v-dialog
        :key="dialogID"
         v-model="dialog"
         width="600px"
         >

        <v-card>

          <v-stepper v-model="step" alt-labels>
            <v-card-title>Nova Parceria</v-card-title>
            
            <v-stepper-header style="box-shadow:none">
              <v-stepper-step :complete="step > 1" step="1">Nome</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">Tipo</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">Plano</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 4" step="4">Campo</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 5" step="5">Voucher</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 6" step="6">Resumo</v-stepper-step>
            </v-stepper-header>
            
             <v-stepper-items>

              <v-stepper-content step="1">
                  <v-card>

                    <v-form v-model="nameForm">
                      <v-row>
                          <v-col cols="9">
                            <v-text-field
                              label="Nome"
                              v-model="newPartnership.name"
                              prepend-icon="text_format"
                              :rules="rules.name"
                            />
                          </v-col>

                          <v-col>
                            <v-select
                              label="Parceria ativa"
                              v-model="newPartnership.active"
                              :items="activeOptions"
                              item-text="name"
                              item-value="value"
                              :rules="rules.active"
                            ></v-select>
                          </v-col>                        
                      </v-row>

                      <v-row>
                          <v-col cols="12">
                          <v-text-field
                            label="Descrição"
                            type="text"
                            v-model="newPartnership.description"
                            prepend-icon="description"
                            :rules="rules.description"
                          />
                        </v-col>
                      </v-row>
                    </v-form>

                    <div style="display: flex; justify-content: flex-end">
                      <v-btn color="primary" @click="step=nextStep(1, newPartnership.type)" :disabled="!nameForm" style="margin: 0 12px">Próximo</v-btn>
                      <v-btn text @click="dialog=false">Cancelar</v-btn>                    
                    </div>
                    
                  </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card>
                  
                  <p class="text--secondary text-justify">O TIPO da PARCERIA define quais campos serão necessários. O TIPO 1 é o PADRÃO, onde os planos sem nenhuma parceria estão.</p> 
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        label="Tipo"
                        type="number"
                        :items="form.types"
                        :item-value="'value'"
                        :item-text="'name'"
                        v-model="newPartnership.type"
                        prepend-icon="local_offer"
                        :rules="rules.type"
                      ></v-select>
                    </v-col>                      
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-select
                        label="Planos"
                        type="text"
                        :items="plans"
                        :item-text="formatPlan"
                        :item-value="'id'"
                        multiple  
                        v-model="newPartnership.plans"
                        prepend-icon="mdi-comment-account"
                        :rules="rules.plans"
                        chips
                        deletable-chips
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <div style="display: flex; justify-content: flex-end">

                    <v-btn color="primary" @click="step=nextStep(step, newPartnership.type)" :disabled="!(!!newPartnership.plans.length && !!newPartnership.type)" style="margin: 0 12px">Próximo</v-btn>
                    <v-btn text @click="step=backStep(step, newPartnership.type)">Voltar</v-btn>    

                  </div>
                </v-card>

              </v-stepper-content>

              <v-stepper-content step="3">
                
                <v-card>
                  <v-row>

                    <v-col cols="12">

                      <p class="text--secondary text-justify">Abaixo está o PLANO EM DESTAQUE, que é, dentre os planos selecionados anteriormente, aquele que será apresentado com o usuário como o RECOMENDADO.</p> 

                      <v-select
                        label="Plano destaque"
                        :items="selectedPlans"
                        :item-text="formatPlan"
                        :item-value="'id'"
                        v-model="newPartnership.featured"
                        prepend-icon="mdi-comment-account"
                        :rules="rules.featured">
                      </v-select>

                    </v-col>

                  </v-row>

                  <div style="display: flex; justify-content: flex-end">
                   
                    <v-btn color="primary" @click="step=nextStep(step,newPartnership.type)" :disabled="!(newPartnership.featured)" style="margin: 0 12px">Próximo</v-btn>
                    <v-btn text @click="step=backStep(step,newPartnership.type)">Voltar</v-btn>    

                  </div>
                </v-card>

              </v-stepper-content>              

              <v-stepper-content step="4">
               
                <v-card>
                  <p class="text--secondary text-justify">O CAMPO é o valor que aparecerá para o usuário no APPLICATIVO. Esse campo irá orientar o que o usuário deve digitar.</p> 
                  <v-row>

                    <v-col cols="12">
                      <v-text-field
                       label="Campo"
                        v-model="newPartnership.field"
                        prepend-icon="text_format"
                        :rules="rules.field"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <div style="display: flex; justify-content: flex-end">
                    <v-btn color="primary" @click="step=nextStep(step,newPartnership.type)" style="margin: 0 12px" :disabled="!(!!newPartnership.field)">
                      Próximo
                    </v-btn>
    
                    <v-btn text @click="step=backStep(step, newPartnership.tyep)">Voltar</v-btn>
                  </div>
                </v-card>

              </v-stepper-content> 

              <v-stepper-content step="5">
                
                <v-card>
                
                  <v-row>
                    
                    <v-col cols="12">
                      <v-text-field
                        label="Voucher"
                        v-model="newPartnership.code"
                        prepend-icon="local_offer"
                        :rules="rules.voucher"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <div style="display: flex; justify-content: flex-end">
                    <v-btn color="primary" @click="step=nextStep(step,newPartnership.type)" style="margin: 0 12px" :disabled="!(newPartnership.code)"> Próximo</v-btn>
                    <v-btn text @click="step = backStep(step,newPartnership.type)">Voltar</v-btn>
                  </div>
                </v-card>

              </v-stepper-content>    
                            

              <v-stepper-content step="6">
                <v-card>
                
                  <v-row>
                    <v-col cols="12">
                      <p class="text--secondary text-justify" v-if="newPartnership.type==1">Será criada uma PARCERIA PADRÃO. Essa parceria define os planos padrões para quando um usuário seleciona deve selecionar um plano. Os planos nesta parceria são apresentados sem o usuário selecionar nada.</p> 
                      <p class="text--secondary text-justify" v-if="newPartnership.type==2">Será criada uma PARCERIA SECAD. Nesta parceria, o usuário deve ser um usuário do SECAD e seu acesso será realizado pelo seu CPF.</p> 
                      <p class="text--secondary text-justify" v-if="newPartnership.type==3">Será criada uma PARCERIA EMPRESARIAL. Nesta parceria, foi definido um CÓDIGO de voucher para que o usuário digite e tenha planos promocionais.</p> 
                      <p class="text--secondary text-justify" v-if="newPartnership.type==4">Será criada uma PARCERIA DE INDICAÇÃO. Nesta parceria, ela é utilizada para definir os planos no PROGRAMA DE INDICAÇÃO.</p> 
                      <br>
                      <p class="text--secondary text-justify">Para confirmar a criação, clique em CRIAR.</p> 
                    </v-col>
                  </v-row>

                  <div style="display: flex; justify-content: flex-end">
                    <v-btn
                      color="primary"
                      @click="save()"
                      :loading="saving"
                      style="margin: 0 12px">
                      Criar
                    </v-btn>
    
                    <v-btn text @click="step =backStep(step, newPartnership.type)">Voltar</v-btn>
                  </div>
                </v-card>

              </v-stepper-content>    

            </v-stepper-items>
          </v-stepper>          
        </v-card>

      </v-dialog>

      <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Remover Plano</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover o plano, não será mais possível restaurar. Tem certeza que deseja remover este plano?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove(removeDialog.id, false)" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

   </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import * as storage from '../libs/storage'

Vue.use(VueLodash)

export default {
  data: () => ({
    
    name: '',
    snackbar: false,
    nameForm: false,
    
    step: 1,

    partnerTypes: {
      1: 'Padrão',
      2: 'Secad',
      3: 'Empresa',
      4: 'Programa de indicação'
    },

    newPartnership: {
      type: 0,
      description: '',
      plans: [],
      featured: '',
      code: '',
    },

    valid: false,
    dialog: false,
    dialogID: 0,
    removeDialog: { show: false, loading: false, id: null },
    saving: false,
    loading: false,
    subscriptions: [],

    rules:{
      name: [
        v => !!v || 'Nome é obrigatório',
        v => (v && v.length <= 64) || 'O nome deve ter no máximo 64 caracteres',
      ],
      field: [
        v => !!v || 'O campo é obrigatório',
        v => (v && v.length <= 32) || 'O campo deve ter no máximo 32 caracteres',
      ],
      voucher: [
        v => !!v || 'O voucher é obrigatório',
        v => (v && v.length <= 32) || 'O voucher deve ter no máximo 32 caracteres',
      ],
      description: [
        v => !!v || 'Descrição é obrigatória',
        v => (v && v.length <= 1024) || 'A descrição deve ter no máximo 1024 caracteres',
      ],
      plans: [
        v => !!v.length || 'Pelo menos um plano é obrigatório',
      ],
      type: [
        v => !!v || 'Tipo é obrigatório',
      ],
      featured: [
        v => !!v || 'Plano destaque é obrigatório',
      ],
      code: [
        v => !!v || 'Código é obrigatório'
      ],
      active: [
        v => typeof v == 'boolean' || "Campo obrigatório"
      ]
    },

    form: {
      types: [
        {value: 1, name: 'Padrão'},
        {value: 2, name: 'Secad'},
        {value: 3, name: 'Empresa'},
        {value: 4, name: 'Programa de indicação'}
      ]
    },
  
    partnerships: [],
    plans: [],
    activeOptions:[
      {name: 'Sim', value: true},
      {name: 'Não', value: false}
    ],

    options: {
       page: 1,
       itemsPerPage: 10
    },
    total: 0,
    headers: [
      { text: 'NOME', value: 'name', sortable: false },
      { text: 'PADRÃO', value: 'default', width: '60px', sortable: false },
      { text: 'TIPO', value: 'type', width: '180px', sortable: false },
      { text: 'CRIADO', value: 'created', sortable: false },
      { text: 'ID', value: 'id', width: '320px', align: 'left', sortable: false},
      { text: '', value: 'active', width: '80px', align: 'left', sortable: false},
      { text: 'AÇÕES', value: 'action', width: '60px', sortable: false }
    ]
  }),
  
  watch: {
    options: {
      async handler () {
        this.reload();
      },
      deep: true
    },

    dialog(val) {
      if (!val)
        this.dialogID ++
    }
  },

  computed: {
    selectedPlans: function() {
      return this.plans.filter(e => this.newPartnership.plans.includes(e.id))
    }
  },


  mounted: function () {
    this.$root.title = "Parcerias";
    this.$root.show({create: true});
    
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.create.event.subscribe(e => {
      this.open();
    }));

    Object.assign(this.$data, pick(JSON.parse(storage.getItem('plans'))  || {},['name', 'options']));
    this.load(this.$data.code, 0, this.$data.options.itemsPerPage);
  },

  methods: {

    nextStep(actual, type){
      switch(actual){
        case 1:
          return 2;
        case 2:
          return 3;
        case 3:
          if (type==1){
            return 6;
          }
          if (type==4){
            return 6;
          }
          return 4;
        case 4:
          if (type==3){
            return 5;
          }
          return  6;
        case 5:
          return 6;
      }
      return actual;
    },

    backStep(actual, type){
      switch(actual){
        case 1:
          return 1;
        case 2:
          return 1;
        case 3:
          return 2;
        case 4:
          return 3;
        case 5:
          return 4;
        case 6:
          if (type==1){
            return 3;
          }
          if (type==2){
            return 4;
          }
          if (type==3){
            return 5;
          }
          if (type==4){
            return 3;
          }
          return 5;
      }
      return actual;
    },

    dateFormat: function(str) {
      return moment(str).format('DD/MM/YYYY, H:mm')
    },

    partnership: function(item) {
      router.push(`/partnerships/${item.id}`);
    },

    open: function(){
      this.getPlans()
      
      this.plan = {name: null, sku: '', description: ''};
      this.newPartnership = {type: 0,description: '', plans: [],featured: '',code: ''};
      this.dialog = true;
    },

    copy: async function(item){
      await this.$copyText(item);
      this.snackbar = true;
    },
    
    remove: function(id, dialog) {
      
      if (dialog){
        this.removeDialog.show = true;
        this.removeDialog.id = id;
      } else {

        //this.loading.removing = true;
        this.removeDialog.loading = true;

        axios
          .delete(`/partnerships/${id}`)
          .then(e => {
              this.removeDialog.show = false;
              this.reload();
          })
          .catch(e => {

          })
          .finally(e => {
            this.removeDialog.loading = false;
          });

      } 
    },
    save: function() {

      this.saving = true;

      const partnership = this.newPartnership
      if (partnership.type == 1) {
        Object.assign(partnership, {
          name: 'Padrão',
          default: true
        })

        delete partnership.code
      } else if (partnership.type == 2) {
        Object.assign(partnership, {
          name: 'Secad',
          default: false,
          field: 'cpf'     
        })

        delete partnership.code
      } else if (partnership.type == 3) {
        Object.assign(partnership, {
          default: false,
          field: 'Código'
        })
      } else if (partnership.type == 4) {
        Object.assign(partnership, {
          default: false,
        })
      }

      axios
        .post(`/partnerships`, partnership)
        .then(e => {
          this.$dialog.notify.info('Parceria criada!', {
            position: 'top-right',
            timeout: 2000
          });
          this.dialog = false;
          this.step = 1
          this.reload();
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            timeout: 5000
          });
        })
        .finally(e => {
          this.saving = false;
        });

      },
      debounce: _.debounce(function () { 
        this.reload();
      }, 500),
      reload: function(){
        var name = this.name;
        var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(name, start, limit, sort);
      },
      load: function(name, start, limit, sort){
        
        this.loading = true;

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/partnerships?version=1&start=${start}&limit=${limit}&sort=-created`)
          .then(e => {
            this.partnerships = e.data.data;
            this.total = e.data.total;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',
              //outlined: true,
              timeout: 5000
            });
          })
          .finally(e => {
            this.loading = false;
          });
      },

      getPlans: function() {
        axios
          .get(`plans?sort=-created`)
          .then(e => {
            this.plans = e.data.data;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: 'top-right',

              timeout: 5000
            });
          })
      },

      formatPlan: function(item) {
        return `${item.name} (${item.sku})`
      },

  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem('plans', JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
 
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>