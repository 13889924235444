<template>
   <v-container style="postion:relative">
     
      <br><br>
      <v-data-table
         :loading="loading" 
         loading-text="Aguarde..."
         :headers="headers"
         :items="entries"
         no-data-text="Sem resultados"
         :options.sync="options"
         :server-items-length="total"
         :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"   
         >

         
         <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click="download(item)">mdi-cloud-download</v-icon>
         </template>
         <template v-slot:[`item.id`]="{ item }">
            <div class="copyable" v-on:click.stop="copy(item)">{{ item.id }}</div>
         </template>
         <template v-slot:[`item.apps`]="{ item }">
            <div>{{ apps[item.apps] }}</div>
         </template>
         <template v-slot:[`item.date`]="{ item }">
            <div>{{ dateformat(item.date) }}</div>
         </template>

      </v-data-table>
      <v-snackbar v-model="snackbar" :timeout="2000">
         Id copiado
      </v-snackbar>
     
   </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import * as storage from '../libs/storage'
import moment from 'moment';

Vue.use(VueLodash)

export default {
  data: () => ({
    loading: false,
    name: '',
    snackbar: false,
    valid: false,
    dialog: false,
    saving: false,
    subscriptions: [],
    
    apps:{
      1: 'C+R',
      2: 'PSI'
    },

    entries: [],

    options: {
       page: 1,
       itemsPerPage: 10
    },
    total: 0,
    headers: [      
      { text: 'DATA', value: 'date', sortable: false },
      { text: 'ID', width: '320px', align: 'left', sortable: false, value: 'id'},
      { text: 'APP', width: '120px', value: 'apps', sortable: false },
      { text: 'AÇÕES', value: 'action', width: '50px', sortable: false },

    ]
  }),
  
  watch: {
    options: {
      async handler () {
        this.reload();
      },
      deep: true
    },
    category: {
      async handler(){
        this.reload();
      }
    },
    empty: {
      async handler(){
        this.reload();
      }
    }
  },

  mounted: function () {
    this.$root.title = "Direitos Autorais";
    this.$root.show({create: false});
    
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.create.event.subscribe(e => {
      this.open();
    }));

    Object.assign(this.$data, pick(JSON.parse(storage.getItem('files'))  || {},['name', 'options']));
    this.load(this.$data.name, 0, this.$data.options.itemsPerPage);
  },

  methods: {
      dateformat: function(item){
        return moment(item).format("DD/MM/YYYY");
      },
      open: function(){
        this.dialog = true;
      },
      copy: async function(item){
        await this.$copyText(item.id);
        this.snackbar = true;
      },
      upload: function(){
        var data = this.$data.file;
        var form = new FormData();
        form.append("resource", data.resource);
        form.append("name", data.name);

        this.$data.saving = true;
        axios.put('resources/' + uuid(), form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(e => {
            this.dialog=false;
            this.file.name = '';
            this.file.resource = '';
            this.reload();
        })
        .finally(e => {
            this.saving = false;
        });;
      },
      onFileChange: function(event){
        var d = event;
        this.file.name = this.file.name || event.name;
      },
      debounce: _.debounce(function () { 
        this.reload();
      }, 500),
      reload: function(){
        var name = this.name;
        var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

        var limit = this.options.itemsPerPage;
        var start = (this.options.page - 1) * limit;

        this.load(name, start, limit, sort);
      },
      load: function(name, start, limit, sort){
        this.loading = true;

        var filter = function(field, value){
          return value===null || value===undefined? "": "&"+field+"="+value;
        }

        axios
          .get(`/copyright?version=1&start=${start}&limit=${limit}`)
          .then(e => {
            this.entries = e.data.data;
            this.total = e.data.total;
          })
          .catch(e => {

          })
          .finally(e => {
            this.loading = false;
          });
      },
      download: async function(item) {

        var date = moment(item.date).format("YYYYMMDD");
        var app = (localStorage.getItem('app') && JSON.parse(localStorage.getItem('app') || '{}')) || (router.push('/login'));
       
        var win = window.open(`${axios.defaults.baseURL}/copyright/${date}?version=2&app=${app.code}`, '_blank');
        win.focus();

        // await axios.get(`/resources/${item.id}`).then((response)=>{
        //   var blob = new Blob([response.data]);
        //   var url = window.URL.createObjectURL(blob);
        //   var link = document.createElement('a');
        //   link.href = url;
        //   link.setAttribute('download', item.name || item.id); //or any other extension
        //   document.body.appendChild(link);
        //   link.click();
        // })
      },

  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem('files', JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
 
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>