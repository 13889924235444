var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" Nova Mensagem ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-format-color-text","label":"Nome","counter":"128","rules":[_vm.rules.required],"autocomplete":"off"},model:{value:(_vm.page.name),callback:function ($$v) {_vm.$set(_vm.page, "name", $$v)},expression:"page.name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-label-outline","label":"Slug","counter":"256","rules":[_vm.rules.required, _vm.rules.slug],"placeholder":"Ex.: pagina-exemplo","autocomplete":"off"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==32)return null;return _vm.format()}},model:{value:(_vm.page.slug),callback:function ($$v) {_vm.$set(_vm.page, "slug", $$v)},expression:"page.slug"}})],1),_c('v-col',[_c('editor',{ref:"editor",attrs:{"api-key":"no-api-key","disabled":_vm.saving,"init":{
            height: '500',
            menubar: false,
            plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
            'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
            alignleft aligncenter alignright alignjustify | table image | \
            bullist numlist outdent indent | removeformat | code | help',
            images_upload_url: 'postAcceptor.php'
          }},model:{value:(_vm.page.text),callback:function ($$v) {_vm.$set(_vm.page, "text", $$v)},expression:"page.text"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.saving,"disabled":!_vm.valid || !this.page.text,"width":"100"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Enviar")]),_c('v-btn',{attrs:{"text":"","color":"primary","width":"100"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Fechar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }