<template>
  <v-card>
      <v-card-title>
        Novo Link
      </v-card-title>

      <v-card-text>
        Aqui são apresentados os verbetes publicados para se gerar um link. Selecione um verbete abaixo.
        <v-row>
          <v-col>
            <v-form v-model="form">
              <v-autocomplete
                label="Verbete"
                item-text="name"
                item-value="id"
                :items="items"
                no-data-text="Nenhum conteúdo encontrado"
                @keydown="search($event.target.value)"
                :loading="loading"
                v-model="content"
                :rules="rules.content"
              >
              </v-autocomplete>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer>
        </v-spacer>

        <v-btn text @click="save()" :disabled="!form">Criar Link</v-btn>
        <v-btn text color="secondary" @click="$emit( 'close' )">Cancelar</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
    name:"editor-link-dialog",

    data: () => ({
      loading: false,
      items: [],
      content: '',
      form: false,

      rules: {
        content: [
          v => !!v || 'O verbete é obrigatório.'
        ]
      }
    }),

    methods: {
      search: _.debounce(function(e) {
        if (e) {
          this.loading = true
            axios.get(`contents?name=${e}&empty=true,false`)
            .then(res => {
              this.items = res.data.data
            })
            .catch(err => {
            })
            .finally(() => {this.loading = false})
        }
      }, 300),

      save: function() {

        var app = (localStorage.getItem('app') && JSON.parse(localStorage.getItem('app') || '{}')) || (router.push('/login'));
        const base = app.code==1?'https://clinicamedica.consultamaisrapida.com.br/conteudo/':'https://psiquiatria.consultamaisrapida.com.br/conteudo/';

        const link = base + this.content;
        
        this.$emit('save', link)
        this.$emit('close')
      }
    }
}
</script>

<style>

</style>