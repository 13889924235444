<template>
  <v-container style="height: 100%" id="edit">
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <div v-show="current" style="height: 100%">
      <v-tabs fixed-tabs style="height: 100%" @change="generatePreview()">
        <v-tab
          key="editor"
          v-if="
            current &&
            (current.type == 1 || current.type == 2 || current.type == 4)
          "
          >Editor</v-tab
        >
        <v-tab key="more" v-if="current">Cadastro</v-tab>
        <v-tab key="general">Cadastro Geral</v-tab>
        <v-tab
          key="preview"
          v-if="
            current &&
            (current.type == 1 || current.type == 2 || current.type == 4)
          "
          >Preview</v-tab
        >
        <v-tab key="history">Histórico</v-tab>

        <v-tab-item
          key="editor"
          v-if="
            current &&
            (current.type == 1 || current.type == 2 || current.type == 4)
          "
        >
          <v-container style="height: calc(100vh - 230px)">
            <v-textarea
              v-if="current && current.type == 2"
              height="calc(100% - 10px)"
              filled
              label="Código"
              v-model="current.value"
              hint="Código da calculadora"
            >
            </v-textarea>

            <editor
              v-if="current && (current.type == 1 || current.type == 4)"
              style="height: calc(100% - 10px)"
              id="app-editor"
              ref="editor"
              api-key="no-api-key"
              v-on:onInit="onEditorInit"
              v-on:input="onChange"
              :disabled="loading.saving"
              :init="initialMCE"
            >
            </editor>
          </v-container>
        </v-tab-item>

        <v-tab-item key="more">
          <p
            class="text--secondary text-justify"
            style="padding-left: 5px; padding-top: 36px; padding-bottom: 20px"
          >
            Estes dados serão versionados e irão para PRODUÇÃO somente após a
            revisão ser publicada. Enquanto o conteúdo não for publicado, ele
            ficará apenas aqui nesta REVISÃO e não impactará as consultas
            realizadas pelo aplicativo ou aqui no editor.
          </p>

          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="current.name"
                    label="Nome"
                    required
                    :loading="loadingForm"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="current.type == 4">
                <v-col
                  cols="12"
                  md="6"
                  style="padding-left: 10px; padding-right: 10px"
                >
                  <v-combobox
                    v-model="current.substance1"
                    :items="substances1"
                    :loading="loading.substances1 || loadingForm"
                    hide-no-data
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    :rules="rules.substances1"
                    @input.native="current.substance1 = $event.srcElement.value"
                    @update:search-input="searchSubstance1($event)"
                    label="Primeria Substância"
                  >
                  </v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  style="padding-left: 10px; padding-right: 10px"
                >
                  <v-combobox
                    v-model="current.substance2"
                    :items="substances2"
                    :loading="loading.substances2 || loadingForm"
                    hide-no-data
                    item-text="name"
                    item-value="name"
                    :return-object="false"
                    :rules="rules.substances2"
                    @input.native="current.substance2 = $event.srcElement.value"
                    @update:search-input="searchSubstance2($event)"
                    label="Segunda Substância"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row v-if="current.type == 3">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="current.parent"
                    :items="parents"
                    :loading="loading.parents || loadingForm"
                    hide-selected
                    clearable
                    item-text="name"
                    item-value="id"
                    @update:search-input="searchParents($event)"
                    label="Nível superior"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="current.authors"
                    :items="authors"
                    @update:search-input="searchAuthors($event)"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    no-data-text="Nenhum autor encontrado"
                    label="Autores"
                    multiple
                    :loading="loading.authors"
                  >
                    <template slot="selection" scope="data">
                      <v-chip close @click:close="deleteAuthor(data.item)">
                        <v-avatar left>
                          <img
                            v-if="data.item.picture"
                            v-bind:src="`${url}/resources/${data.item.picture}`"
                          />
                          <img v-else src="./../assets/user.png" />
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template slot="item" scope="data">
                      <v-list-item-avatar>
                        <img
                          v-if="data.item.picture"
                          v-bind:src="`${url}/resources/${data.item.picture}`"
                        />
                        <img v-else src="./../assets/user.png" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>

        <v-tab-item
          key="preview"
          v-if="
            current &&
            (current.type == 1 || current.type == 2 || current.type == 4)
          "
        >
          <div class="smartphone" style="margin-top: 40px">
            <div class="content">
              <p
                v-html="html"
                style="width: 100%; border: none; height: 100%"
              ></p>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="general">
          <p
            class="text--secondary text-justify"
            style="padding-left: 5px; padding-top: 36px; padding-bottom: 20px"
          >
            Essas informações serão mudadas direto em PRODUÇÃO. Alterações
            feitas aqui não terão nenhuma REVISÃO e não serão versionadas. As
            modificações feitas aqui e salvas afetarão as buscas feitas pelo
            aplicativo ou editor de forma imediata.
          </p>

          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    label="Liberação"
                    :items="liberations"
                    item-value="value"
                    item-text="name"
                    v-model="currentParent.premium"
                    :loading="loadingForm"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    label="Pesquisável"
                    :items="searchables"
                    item-value="value"
                    item-text="name"
                    v-model="currentParent.searchable"
                    :loading="loadingForm"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row v-if="current.type == 1">
                <v-col cols="12" sm="6">
                  <v-select
                    height="41"
                    label="Especialidades"
                    v-model="currentParent.specialties"
                    :items="specialties"
                    clearable
                    multiple
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    :loading="loadingForm"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    label="Categorias"
                    v-model="currentParent.category"
                    :items="categories"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    :loading="loadingForm"
                  >
                    <template slot="selection" scope="data">
                      <v-list-item-avatar size="20">
                        <img
                          v-bind:src="`${url}/resources/` + data.item.image"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template slot="item" scope="data">
                      <v-list-item-avatar>
                        <img
                          v-bind:src="`${url}/resources/` + data.item.image"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="[1, 4].indexOf(current.type) >= 0">
                <v-col cols="12" md="12">
                  <v-select
                    height="32"
                    label="Contrato"
                    v-model="currentParent.contract"
                    :items="contracts"
                    persistent-hint
                    hint="Note que ao mudar o contrato, a baseline será mudada para a revisão mais recente publicada."
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    :loading="loadingForm"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-combobox
                    ref="synonymsBox"
                    v-model="currentParent.synonyms"
                    :items="synonyms"
                    label="Sinônimos"
                    chips
                    deletable-chips
                    multiple
                    append-outer-icon="mdi-open-in-new"
                    @click:append-outer="open('synonyms')"
                    :loading="loadingForm"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="currentParent.links"
                    :items="links"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    :loading="loading.links || loadingForm"
                    @update:search-input="searchlinks($event)"
                    :no-filter="true"
                    hide-selected
                    hide-no-data
                    label="Página relacionada"
                    no-data-text="Nenhuma página encontrada"
                    multiple
                  >
                    <template slot="selection" scope="data">
                      <v-chip close @click:close="deleteLink(data.item)">
                        <v-avatar left>
                          <img
                            v-if="categoryImage(data.item)"
                            v-bind:src="
                              `${url}/resources/` + categoryImage(data.item)
                            "
                          />
                          <!-- <img v-else src="../assets/user.png"/> -->
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template slot="item" scope="data">
                      <v-list-item-avatar>
                        <img
                          v-if="categoryImage(data.item)"
                          v-bind:src="
                            `${url}/resources/` + categoryImage(data.item)
                          "
                        />
                        <!-- <img v-else src="../assets/user.png"/> -->
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-combobox
                    ref="keywordsBox"
                    v-model="currentParent.keywords"
                    :items="keywords"
                    label="Palavra-chave"
                    chips
                    deletable-chips
                    multiple
                    append-outer-icon="mdi-open-in-new"
                    @click:append-outer="open('keywords')"
                    :loading="loadingForm"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>

        <v-tab-item key="history">
          <v-container>
            <p
              class="text--secondary text-justify"
              style="padding-left: 5px; padding-top: 36px; padding-bottom: 20px"
            >
              Aqui aparecem todas as alterações feitas nesta revisão do verbete.
            </p>
            <v-data-table
              :headers="historyHeaders"
              :items="history"
              no-data-text="Sem resultados"
              :options.sync="historyOptions"
              :server-items-length="historyTotal"
              :footer-props="{
                'items-per-page-options': [5, 10, 20, 30, 40, 50],
              }"
            >
              <template v-slot:[`item.action`]="{ item }">
                <span>{{ actionTypes[item.action] }}</span>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <span>{{
                  moment(item.date).format("DD/MM/YYYY - HH:mm")
                }}</span>
              </template>
              <template v-slot:[`item.user`]="{ item }">
                <span>{{ item.user.name }}</span>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <v-icon v-if="item.value" small @click="openHistoryDialog(item)"
                  >mdi-eye</v-icon
                >
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
      </v-tabs>

      <br />
      <br />
    </div>

    <v-dialog v-model="dialog.show" width="500px" height="1900px">
      <v-card>
        <v-card-title class="">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text style="padding-bottom: 0px">
          <p style="padding-bottom: 0px">
            Aqui os itens são separados por nova linha. Cada novo item
            adicionado ou removido daqui será alterado no campo original.
          </p>
        </v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form">
            <v-row class="mx-2">
              <v-col cols="12">
                <v-textarea
                  rows="10"
                  label="Itens"
                  v-model="dialog.current"
                  multiline
                  prepend-icon="mdi-calendar-text"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            :disabled="!hasDialogChanged"
            @click="dialog.save()"
            width="100"
            >Salvar</v-btn
          >
          <v-btn text color="primary" @click="dialog.show = false" width="100"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      width="600"
      v-model="customLink"
      @click:outside="$root.tinyMCE.customLink = false"
    >
      <editor-link-dialog
        v-on:close="$root.tinyMCE.customLink = false"
        v-on:save="newCustomLink($event)"
      ></editor-link-dialog>
    </v-dialog>

    <v-dialog v-model="historyDialog.show" :width="dialogWidth">
      <changes-history
        :key="$root.componentKey"
        :changes="historyDialog.changes"
        :user="historyDialog.user"
        v-on:close="historyDialog.show = false"
      ></changes-history>
    </v-dialog>

    <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Deletar Verbete</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover o conteúdo, não será mais possível restaurar. Tem certeza que deseja remover este conteúdo?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove()" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import router from "../router";
import Editor from "@tinymce/tinymce-vue";
import EditorLinkDialog from "../components/EditorLinkDialog";
import ChangesHistory from "../components/Changes.History.vue";
import { diff } from "deep-diff";
import * as moment from "moment";
import config from "../plugins/tinymce/config/config";

import VueLodash from "vue-lodash";

Vue.use(VueLodash);

export default {
  components: {
    editor: Editor,
    EditorLinkDialog,
    ChangesHistory,
  },
  data: () => ({
    loadingForm: false,

    valid: false,
    items: [],
    url: "",
    entry: {},
    html: "",
    oldParent: {},
    currentParent: {},

    tinyInited: false,
    changed: false,
    subscriptions: [],

    dialog: {
      show: false,
      save: function () {},
      title: "",
      current: [],
      old: [],
    },
    removeDialog: { show: false, loading: false },
    publishDialog: { show: false, loading: false },

    loading: {
      opening: false,
      loading: true,
      authors: false,
      links: false,
      substances1: false,
      substances2: false,
      saving: false,
      removing: false,
    },

    liberations: [
      { value: true, name: "Premium" },
      { value: false, name: "Freemium" },
    ],

    searchables: [
      { value: true, name: "Deve vir na pesquisa" },
      { value: false, name: "Não deve vir na pesquisa" },
    ],

    history: [],

    historyLoading: false,
    historyHeaders: [
      { text: "USUÁRIO", value: "user", sortable: false },
      { text: "AÇÃO", value: "action", sortable: false, width: "150px" },
      { text: "DATA", value: "date", sortable: false, width: "200px" },
      {
        text: "VALOR",
        value: "value",
        sortable: false,
        align: "center",
        width: "50px",
      },
    ],

    options: {
      page: 1,
      itemsPerPage: 10,
    },
    historyOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    historyTotal: 0,
    contracts: [],

    old: {},

    current: {
      name: "",
      substanece1: "",
      substanece2: "",
      category: {
        name: "",
      },
      specialties: [],
      synonyms: [],
      keywords: [],
      links: [],
      authors: [],
    },

    historyDialog: {
      show: false,
      item: undefined,
      changes: {},
    },

    specialties: [],
    categories: [],
    authors: [],
    synonyms: [],
    keywords: [],
    links: [],
    substances1: [],
    substances2: [],
    parents: [],

    rules: {
      name: [
        (v) => !!v || "O nome é obrigatório",
        (v) =>
          (v && v.length <= 100) || "O nome deve ter menos de 100 caracteres",
      ],
      substance1: [(v) => !!v || "A primeira substância é obrigatória"],
      substance2: [(v) => !!v || "A segunda substância é obrigatória"],
    },

    actionTypes: {
      1: "Criação",
      2: "Atualização",
      3: "Remoção",
    },
  }),

  computed: {
    moment() {
      return moment;
    },

    hasChanged() {
      var changes = diff(this.old, this.current);
      var parentChanges = diff(this.oldParent, this.currentParent);
      return !!changes || !!parentChanges;
    },

    initialMCE: function () {
      return config.config;
    },

    hasDialogChanged() {
      var changes = diff(this.dialog.old, this.dialog.current);
      return !!changes;
    },

    customLink: function () {
      return this.$root.tinyMCE.customLink;
    },

    dialogWidth: function () {
      return this.historyDialog.changes && this.historyDialog.changes.value
        ? "800px"
        : "400px";
    },
  },

  watch: {
    hasChanged: {
      handler(changed) {
        this.$root.actions.save.disable = !changed;
        this.$root.actions.publish.disable =
          changed || !this.$root.canAccess("DELETE", "/contents/1");
      },
      deep: true,
    },
    options: {
      async handler() {
        this.fetchInfo();
      },
      deep: true,
    },
    historyOptions: {
      async handler() {
        this.fetchInfo();
      },
      deep: true,
    },
    "current.substance1": {
      async handler() {
        this.onSubstanceChange();
      },
      deep: true,
    },
    "current.substance2": {
      async handler() {
        this.onSubstanceChange();
      },
      deep: true,
    },

    "historyDialog.show": {
      handler() {
        this.$root.componentKey++;
      },
      deep: true,
    },

    valid: function (e) {},
  },

  mounted: function () {
    this.$root.title = "Verbete";
    this.url = axios.defaults.baseURL;

    this.$root.show({});
    this.loading.opening = true;

    window.addEventListener("beforeunload", this.unsubscribe);
    this.subscriptions
      .filter((e) => e)
      .forEach((e) => {
        e.unsubscribe();
      });

    this.$data.subscriptions.push(
      !this.$root.actions.save.event.observers.length
        ? this.$root.actions.save.event.subscribe((e) => {
            this.save();
          })
        : null,
      !this.$root.actions.publish.event.observers.length
        ? this.$root.actions.publish.event.subscribe((e) => {
            this.publish();
          })
        : null,
      !this.$root.actions.remove.event.observers.length
        ? this.$root.actions.remove.event.subscribe((e) => {
            this.removeDialog.show = true;
          })
        : null
    );

    this.fetchInfo();
  },

  methods: {
    fetchInfo() {
      this.loadingForm = true;
      this.loading.loading = true;

      const history = {
        start:
          (this.historyOptions.page - 1) * this.historyOptions.itemsPerPage,
        limit: this.historyOptions.itemsPerPage,
      };

      Promise.all([
        axios.get(
          `/contents/${this.$route.params.id}/revisions/${this.$route.params.revision}?version=1`
        ),
        axios.get(`/categories?limit=1000`),
        axios.get(`/specialties?limit=1000`),
        axios.get(`/contracts?limit=1000`),
        axios.get(`/contents/${this.$route.params.id}?version=2`),
        axios.get(
          `/contents/${this.$route.params.id}/revisions/${this.$route.params.revision}/changes?version=2&start=${history.start}&limit=${history.limit}`
        ),
      ])
        .then((e) => {
          e[0].data.data.authors = e[0].data.data.authors || [];
          e[0].data.data.synonyms = e[0].data.data.synonyms || [];
          e[0].data.data.keywords = e[0].data.data.keywords || [];
          e[0].data.data.links = e[0].data.data.links || [];

          e[0].data.data.value = e[0].data.data.value || "";

          this.old = e[0].data.data;

          this.old.category = this.old.category && this.old.category.id;
          this.old.specialties =
            this.old.specialties && this.old.specialties.map((e) => e.id);
          this.old.parent = this.old.parent && this.old.parent.id;

          this.old.authors = this.old.authors.map((e) => e.id);
          this.old.substance1 = this.old.substance1 && this.old.substance1.name;
          this.old.substance2 = this.old.substance2 && this.old.substance2.name;

          this.onSubstanceChange();

          this.categories = e[1].data.data;
          this.specialties = e[2].data.data;
          this.contracts = e[3].data.data;
          this.oldParent = e[4].data.data;
          this.history = e[5].data.data;
          this.historyTotal = e[5].data.total;

          this.current = this.clone(this.old);
          this.old = this.clone(this.current);
          this.currentParent = this.clone(this.oldParent);

          this.$root.show({ remove: true, save: true, publish: true });
          this.$root.actions.save.disable = true;
          this.$root.actions.publish.disable = !this.$root.canAccess(
            "DELETE",
            "/contents/1"
          );
          this.$root.actions.remove.disable = !this.$root.canAccess(
            "DELETE",
            "/contents/1/revisions/1"
          );

          this.items = [
            {
              text: "Verbetes",
              disabled: false,
              href: "/entries",
            },
            {
              text: this.currentParent.name,
              disabled: false,
              href: `/entries/${this.$route.params.id}/view`,
            },
            {
              text: "Revisões",
              disabled: false,
              href: `/entries/${this.$route.params.id}/revisions`,
            },
            {
              text: this.current.version,
              disabled: false,
              href: `/entries/${this.$route.params.id}/revisions/${this.$route.params.revision}/view`,
            },
            {
              text: "Edição",
              disabled: false,
            },
          ];

          var curr = this.current;
          //curr.set = false;

          var current = this.current;
          // setTimeout(function(){
          //   if (!current.set && window.tinyMCE && (current.type == 1 || current.type == 4)){
          //       current.set = true;
          //       tinyMCE.activeEditor.setContent(current.value);
          //   }
          // }, 2000)

          // if (curr.type == 1){
          //   setTimeout(function(){
          //     window.tinymce.activeEditor.setContent(curr.value)
          //   }, 2000);
          //}

          return Promise.all([
            axios.get(
              `/contents?id=${this.current.links.join(
                ","
              )}&limit=1000&empty=true,false`
            ),
            axios.get(
              `/users?id=${this.current.authors.join(",")}&type=2,3&limit=1000`
            ),
            axios.get(
              `/contents?version=1&id=${this.current.parent}&limit=1&empty=true,false&type=3`
            ),
          ]);
        })
        .then((e) => {
          this.links = e[0].data.data;
          var users = e[1].data.data;

          this.authors = this.current.authors.map(
            (a) => users.filter((u) => u.id == a)[0]
          ); //preserve the order

          this.parents = e[2].data.data;
        })
        .catch((e) => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally((e) => {
          this.loading.loading = false;
          this.loadingForm = false;
        });
    },

    openHistoryDialog: function (item) {
      this.historyDialog.show = true;
      this.historyDialog.user = item.user.name;
      this.historyDialog.changes = JSON.parse(item.value);
    },

    searchAuthors: _.debounce(function (e) {
      if (e) {
        this.loading.authors = true;
        axios
          .get(`/users?version=3&name=${e}&type=2,3&limit=20`)
          .then((e) => {
            let remove = e.data.data.filter((e) =>
              this.current.authors.includes(e.id)
            );
            remove.forEach((i) =>
              e.data.data.splice(e.data.data.indexOf(i), 1)
            );

            remove = this.authors.filter(
              (e) => !this.current.authors.includes(e.id)
            );
            remove.forEach((i) =>
              this.authors.splice(this.authors.indexOf(i), 1)
            );

            this.authors = this.authors
              .concat(e.data.data)
              .map((o) => ({ id: o.id, name: o.name, category: o.category }));
            let lk = this.authors;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loading.authors = false;
          });
      }
    }, 300),
    searchParents: _.debounce(function (e) {
      if (e) {
        this.loadingParents = true;
        axios
          .get(`/contents?version=1&name=${e}&limit=20&empty=true,false&type=3`)
          .then((e) => {
            this.parents = e.data.data;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loadingParents = false;
          });
      }
    }, 500),
    searchSubstance1: _.debounce(function (e) {
      if (e) {
        this.loadingSubstances1 = true;
        axios
          .get(`/contents/substances?version=1&name=${e}&limit=20`)
          .then((e) => {
            this.substances1 = e.data.data;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loadingSubstances1 = false;
          });
      }
    }, 500),
    searchSubstance2: _.debounce(function (e) {
      if (e) {
        this.loadingSubstances2 = true;
        axios
          .get(`/contents/substances?version=1&name=${e}&limit=20`)
          .then((e) => {
            this.substances2 = e.data.data;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loadingSubstances2 = false;
          });
      }
    }, 500),

    back: function () {
      router.push(-1);
    },
    onEditorInit: function () {
      this.tinyInited = true;
      if (this.current.value)
        tinyMCE.activeEditor.setContent(this.current.value);
    },
    onChange: _.debounce(function (e) {
      if (this.tinyInited)
        this.current.value = window.tinymce.activeEditor.getContent({
          format: "raw",
        });
    }, 500),

    remove: function () {
      this.removeDialog.loading = true;

      axios
        .delete(
          `/contents/${this.$route.params.id}/revisions/${this.$route.params.revision}`
        )
        .then((e) => {
          this.removeDialog.show = false;
          router.push(`/entries/${this.$route.params.id}/revisions`);
        })
        .catch((e) => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally((e) => {
          this.removeDialog.loading = false;
        });
    },
    categoryImage: function (item) {
      let category = this.categories.filter((e) => e.id == item.category)[0];
      if (!category)
        category = this.categories.filter((e) => e.name == item.category)[0];
      return (category && category.image) || null;
    },
    deleteAuthor(item) {
      var object = this.current.authors.filter((e) => e == item.id);
      const index = this.current.authors.indexOf(object[0]);
      this.current.authors.splice(index, 1);
    },

    deleteLink(item) {
      var object = this.current.links.filter((e) => e == item.id);
      const index = this.current.links.indexOf(object[0]);
      this.current.links.splice(index, 1);
    },

    publish: function () {
      this.publishDialog.loading = true;

      axios
        .patch(`/contents/${this.$route.params.id}`, {
          op: "publish",
          version: this.current.version,
        })
        .then((e) => {
          this.publishDialog.show = false;
          this.$dialog.notify.info("Conteúdo publicado com sucesso!", {
            position: "top-right",
            timeout: 5000,
          });

          router.push(`/entries/${this.$route.params.id}/revisions`);
        })
        .catch((e) => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally((e) => {
          this.publishDialog.loading = false;
        });
    },

    newCustomLink: function (link) {
      const content = tinymce.activeEditor.selection.getContent();
      tinymce.activeEditor.selection.setContent(
        `<a href=${link}>${content}</a>`
      );
      this.onChange(tinymce.activeEditor.selection.getContent());
    },

    save: function () {
      this.$root.actions.save.loading = true;
      this.$root.actions.save.loading = true;

      console.log("old", this.old, "current", this.current);

      let obj = { op: "update" };
      const revisionChanges = diff(this.old, this.current) || [];
      const keys = Array.from(
        new Set(
          revisionChanges.filter((e) => e.kind != "D").map((e) => e.path[0])
        )
      );

      keys.forEach((e) => {
        Object.assign(obj, { [e]: this.current[e] });
      });

      const promises = [
        axios.patch(
          `/contents/${this.$route.params.id}/revisions/${this.$route.params.revision}?version=1`,
          obj
        ),
      ];

      const parentChanges = diff(this.oldParent, this.currentParent) || [];

      if (parentChanges.length) {
        const keys = Array.from(
          new Set(
            parentChanges.filter((e) => e.kind != "D").map((e) => e.path[0])
          )
        );

        keys.forEach((e) => {
          Object.assign(obj, { [e]: this.currentParent[e] });
        });

        const promise = axios.patch(`/contents/${this.$route.params.id}`, obj);
        promises.push(promise);
      }

      Promise.all(promises)
        .then((e) => {
          this.changed = false;

          this.$dialog.notify.info("Conteúdo salvo com sucesso", {
            position: "top-right",
            timeout: 2000,
          });

          this.fetchInfo();
        })
        .catch((e) => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally((e) => {
          this.$root.actions.save.loading = false;
        });
    },

    open: function (type) {
      if (type == "keywords") {
        this.dialog.show = true;
        this.dialog.title = "Palavras-chave";
        this.dialog.current =
          (this.currentParent.keywords &&
            this.currentParent.keywords.join("\n")) ||
          "";
        this.dialog.old = this.dialog.current;
        this.dialog.save = () => {
          this.$refs.keywordsBox.setValue(this.dialog.current.split("\n"));
          this.dialog.show = false;
        };
      } else if (type == "synonyms") {
        this.dialog.show = true;
        this.dialog.title = "Conhecido como";
        this.dialog.current =
          (this.currentParent.synonyms &&
            this.currentParent.synonyms.join("\n")) ||
          "";
        this.dialog.old = this.dialog.current;
        this.dialog.save = () => {
          this.$refs.synonymsBox.setValue(this.dialog.current.split("\n"));
          this.dialog.show = false;
        };
      }
    },

    searchlinks: _.debounce(function (e) {
      if (e) {
        this.loading.links = true;
        axios
          .get(`/contents?version=1&name=${e}`)
          .then((e) => {
            if (this.currentParent.links) {
              let remove = e.data.data.filter((e) =>
                this.currentParent.links.includes(e.id)
              );
              remove.forEach((i) =>
                e.data.data.splice(e.data.data.indexOf(i), 1)
              );

              remove = this.links.filter(
                (e) => !this.currentParent.links.includes(e.id)
              );
              remove.forEach((i) =>
                this.links.splice(this.links.indexOf(i), 1)
              );
            }

            this.links = e.data.data
              .map((o) => ({ id: o.id, name: o.name, category: o.category }))
              .concat(this.links);
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loading.links = false;
          });
      }
    }, 300),

    unsubscribe() {
      this.subscriptions.filter((e) => e).forEach((e) => e.unsubscribe());
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    generatePreview() {
      if (this.current) {
        this.html =
          `<h1>${this.current.name}</h1>` +
          `<br><br>` +
          this.current.value +
          `<br><br>`;
      }
    },

    onSubstanceChange() {
      //if (this.current.type == 4) this.current.name = `Interação entre ${this.current.substance1 || "-"} e ${this.current.substance2 || "-"}`
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions
      .filter((e) => e)
      .forEach((e) => {
        e.unsubscribe();
      });
    this.$data.subscriptions = [];
    next();
  },
};
</script>

<style lang=scss>
#edit {
  .smartphone {
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
  }

  /* The horizontal line on the top of the device */
  .smartphone:before {
    content: "";
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }

  /* The circle on the bottom of the device */
  .smartphone:after {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }

  /* The screen (or content) of the device */
  .smartphone .content {
    overflow: auto;
    width: 100%;
    height: 100%;
    background: white;
  }

  .v-skeleton-loader__image {
    height: 80vh !important;
  }

  .tox-tinymce {
    height: calc(100% - 40px) !important;
  }

  .v-textarea {
    height: calc(100% - 10px);

    div {
      height: 100%;
    }
  }
}
</style>
