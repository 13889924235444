import { contentLink } from '../plugins/contentLink/plugin';

const config = {
    select: "#app-editor",
    menubar: false,
    plugins: [
        'advlist autolink lists image charmap print preview anchor link',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount contentLink'
    ],
    toolbar1:
      'fullscreen | undo redo | formatselect | link | contentLink | bold italic underline strikethrough backcolor | superscript subscript |  \
      alignleft aligncenter alignright alignjustify | table image | \
      bullist numlist outdent indent | removeformat | code | help',
    images_upload_url: 'postAcceptor.php',
    contextmenu: 'link contentLink',
    setup: function () {
        window.tinymce.PluginManager.add('contentLink', contentLink);
    }
}

export default { config, contentLink };