import axios from "axios";
//import LocalStorageService from "./services/storage/localstorageservice";
import router from "../router";
var parse = require('json-parse-safe')

//axios.defaults.baseURL = 'http://a25d7eea66b2011ea84450ad1592402b-1930884658.us-east-2.elb.amazonaws.com';
//axios.defaults.baseURL = 'http://api.consultamaisrapida.com.br';

//when running in dev mode - ie running throught 'npm run serve', nginx will not be executed from entrypoint.sh and no replacement will be done
var win = (<any>(window || {}));
var api = win.variables && win.variables.API;
axios.defaults.baseURL = (api == '${API}' || !api)? 'http://api.dev.consultamaisrapida.com.br' : api;

// Add a request interceptor
axios.interceptors.request.use(
   config => {
       const token = parse(<any>localStorage.getItem("token"))

       var app = (localStorage.getItem('app') && JSON.parse(<string>localStorage.getItem('app') || '{}')) || (router.push('/login'));
       config.headers['x-app'] = config.headers['x-app'] || app.code;

       if (token.value) {
           config.headers['Authorization'] = 'Bearer ' + token.value.access;
       }
       // config.headers['Content-Type'] = 'application/json';
       return config;
   },
   error => {
       Promise.reject(error);
   }
);

//Add a response interceptor
axios.interceptors.response.use((response) => {
   return response;
}, function (error) {
   const originalRequest = error.config;

   if (error.request.status == 0){
        return Promise.reject(error);
   }

   if (error.response.status === 401 && !originalRequest.url.includes('/sessions')) {
        router.push('/login');
        return Promise.reject(error);
   }

   if (error.response.status === 419 && !originalRequest._retry) {

       originalRequest._retry = true;
       const token = parse(<any>localStorage.getItem("token"))
                    
       return axios.get(`/sessions/${token.value.session}/access/new?access=${token.value.access}`)
                   .then(res => {
                        
                        if (res.status === 200) {
                            localStorage.setItem("token", JSON.stringify(res.data.data.token));
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.token.access;
                        } 
                        return axios(originalRequest);
                        
                    }).catch(err =>{
                        router.push('/login');
                        return Promise.reject(error);
                    })
   }

   //check error format
   if(error.response.data && 'success' in error.response.data){
        return Promise.reject(error.response.data);    
   }

   return Promise.reject(error);
});