<template>
  <v-card>
    <v-card-title> Nova Mensagem </v-card-title>

    <v-card-text>
      <v-form v-model="valid">
        <v-col>
          <v-text-field
            prepend-icon="mdi-format-color-text"
            label="Nome"
            v-model="page.name"
            counter="128"
            :rules="[rules.required]"
            autocomplete="off"
          />
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-label-outline"
            label="Slug"
            v-model="page.slug"
            v-on:keyup.32="format()"
            counter="256"
            :rules="[rules.required, rules.slug]"
            placeholder="Ex.: pagina-exemplo"
            autocomplete="off"
          />
        </v-col>
        <v-col>
          <editor
            ref="editor"
            api-key="no-api-key"
            v-model="page.text"
            :disabled="saving"
            :init="{
              height: '500',
              menubar: false,
              plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
              'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
              alignleft aligncenter alignright alignjustify | table image | \
              bullist numlist outdent indent | removeformat | code | help',
              images_upload_url: 'postAcceptor.php'
            }"
          ></editor>
        </v-col>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        :loading="saving"
        @click="save()"
        :disabled="!valid || !this.page.text"
        width="100"
        >Enviar</v-btn
      >
      <v-btn text color="primary" @click="close()" width="100">Fechar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { v4 as uuid } from "uuid";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },

  data: () => ({
    page: {},
    valid: false,

    saving: false,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      slug: (slug) => /^[a-z0-9]+(?:(_||-)[a-z0-9]+)*$/.test(slug) || "Slug inválida",
    },
  }),

  watch: {},

  mounted: function () {},

  methods: {
    close: function () {
      this.$emit("close");
    },

    format: function () {
      let slug = this.page.slug;
      slug = slug ? slug.split(" ").join("-") : "";
      this.page.slug = slug
    },

    save: function (wait) {
      this.saving = true;

      this.page.id = uuid();

      axios
        .post("pages", this.page)
        .then((e) => {
            this.$emit('create')
        })
        .catch((err) => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000,
          });
        })
        .finally((e) => {
          this.saving = false;
        });
    },
  },
};
</script>

<style>
</style>