<template>
  <v-card>
    <v-card-title> Novo Push </v-card-title>
    <v-container>
      <div v-if="!waiting">
          <v-card-subtitle class="mt-2" v-if="user">Preencha os campos a seguir para enviar uma mensagem push para <b>{{ user.name }}</b> Lembre que esse envio de push não gera nenhuma mensagem na base de dados. É um envio considerado de testes.</v-card-subtitle>
          
          <v-data-table
            :headers="installation.headers"
            :items="installation.data"
            :loading="loadingInstallations"
            :hide-default-footer="true"
            no-data-text="Sem instalações"
          >

            <template v-slot:[`item.date`]="{ item }">
              <span>{{ format(item.date) }}</span>
            </template>
            <template v-slot:[`item.token`]="{ item }">
              <div class="copyable" v-on:click.stop="copy(item)">{{ item.token.substring(0,40) + (item.token.length>40?"...":"") }}</div>
            </template>
          </v-data-table>

          <v-form v-model="valid" ref="form">
            <v-col cols="12">
              
              <v-select
                v-model="message.type"
                label="Tipo"
                item-text="value"
                item-value="id"
                v-on:change="typeChange()"
                :items="types"
                :rules="rules.type"
                required
              >
              </v-select>

              <div v-if="message.type == 1">
                <p class="text--secondary text-justify">
                  Esta mensagem apenas envia uma notificação de PUSH para os
                  usuários, sem nenhuma ação disponível.
                </p>
              </div>
              <div v-if="message.type == 2">
                <p class="text--secondary text-justify">
                  Esta mensagem apenas envia uma notificação de PUSH para os
                  usuários, porém vincula a uma CATEGORIA, conforme selecionado
                  abaixo.
                </p>
                <v-select
                  height="32"
                  label="Categorias"
                  v-model="message.value"
                  :items="categories"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  :rules="rules.categoryRules"
                >
                  <template slot="selection" scope="data">
                    <v-list-item-avatar size="16">
                      <img
                        v-bind:src="`${$root.url}/resources/` + data.item.image"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                      <img
                        v-bind:src="`${$root.url}/resources/` + data.item.image"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="width: 1%"
                        v-text="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div v-if="message.type == 3">
                <p class="text--secondary text-justify">
                  Esta mensagem apenas envia uma notificação de PUSH para os
                  usuários, porém vincula a um VERBETE, conforme selecionado
                  abaixo.
                </p>
                <v-autocomplete
                  v-model="message.value"
                  :items="contents"
                  item-text="name"
                  item-value="id"
                  :loading="loadingContents"
                  :search-input.sync="search.content"
                  label="Verbete"
                  no-data-text="Nenhum conteúdo encontrado"
                  :rules="rules.contentRules"
                >
                  <template slot="selection" scope="data">
                    <v-list-item-avatar size="16">
                      <img
                        v-bind:src="
                          `${$root.url}/resources/` +
                          categories.filter((e) => e.id === data.item.category)[0]
                            .image
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template slot="item" scope="data">
                    <v-list-item-avatar>
                      <img
                        v-bind:src="
                          `${$root.url}/resources/` +
                          categories.filter((e) => e.id === data.item.category)[0]
                            .image
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
              <div v-if="message.type == 4">
                <p class="text--secondary text-justify">
                  Esta mensagem apenas vincula uma URL ao seu conteúdo.
                </p>
                <v-text-field
                  v-model="message.value"
                  counter="255"
                  label="URL"
                  :rules="rules.newRules"
                ></v-text-field>
              </div>
              <div v-if="message.type == 5">
                <p class="text--secondary text-justify">
                  Esta mensagem vincula um texto formatado ao seu conteúdo.
                </p>
                <div>
                  <editor
                    id="app-editor"
                    ref="editor"
                    api-key="no-api-key"
                    v-model="message.value"
                    v-on:onInit="onEditorInit"
                    :disabled="saving"
                    :init="initialMCE"
                    v-on:input="onEditorChange"
                  >
                  </editor>
                </div>
              </div>

              <br>

              <p class="text--secondary text-justify">
                O <b>Título Push</b> é a informação que será apresentada no
                dispositivo quando o push for apresentado ao usuário.
              </p>
              <v-text-field
                class="pt-0"
                label="Título Push"
                counter="64"
                v-model="message.ptitle"
                :rules="rules.pushTitleRules"
              />

              <v-textarea
                label="Conteúdo"
                v-model="message.content"
                filled
                counter="10240"
                :rules="rules.messageRules"
              />
            </v-col>
          </v-form>
      </div>
      <div v-if="waiting">

        <v-card-subtitle class="mt-2">Aqui são apresentados os resultados do envio para os tokens existentes.</v-card-subtitle>

        <v-data-table
            :headers="result.headers"
            :items="result.data"
            :hide-default-footer="true"
            no-data-text="Sem resultados"
          >
            <template v-slot:[`item.date`]="{ item }">
              <span>{{ format(item.date) }}</span>
            </template>
            <template v-slot:[`item.success`]="{ item }">
              <span>{{ `${item.success?'SIM':'NÃO'}` }}</span>
            </template>
            <template v-slot:[`item.token`]="{ item }">
                <div class="copyable" v-on:click.stop="copy(item)">{{ item.token.substring(0,30) + (item.token.length>30?"...":"") }}</div>
            </template>
          </v-data-table>
      </div>
    

    </v-container>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        :loading="saving"
        @click="save()"
        :disabled="!valid || saving || waiting || (message.type > 1 && !message.value)"
        width="100"
        >Enviar</v-btn
      >
      <v-btn text color="primary" @click="$emit('close')" width="100"
        >Fechar</v-btn
      >
    </v-card-actions>

    <v-snackbar v-model="snackbar" :timeout="2000">Token copiado</v-snackbar>
    

  </v-card>
  
</template>

<script>
import config from "../plugins/tinymce/config/config";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    editor: Editor,
  },

  props: ['show', 'user'],

  data: () => ({
    
    waiting: false,
    message: {},
    valid: false,
    snackbar: false,

    search: {
      content: "",
    },

    installation: {
      headers: [
        { text: 'DEVICE', value: 'device', width: '100px' },
        { text: 'DATA', value: 'date', width: '100px' },
        { text: 'TOKEN', value: 'token', sortable: false }
      ],
      data: []
    },

    result: {
      headers: [
        { text: 'SUCESSO', value: 'success', width: '80px' },
        { text: 'DATA', value: 'date', width: '100px' },
        { text: 'DEVICE', value: 'device', width: '100px' },
        { text: 'TOKEN', value: 'token', sortable: false }
      ],
      data: []
    },
    

    categories: [],
    contents: [],

    saving: false,
    loadingInstallations: false,
    loadingContents: false,
    loadingCategories: false,

    types: [
      { id: 1, value: "Normal" },
      { id: 2, value: "Categoria" },
      { id: 3, value: "Verbete" },
      { id: 4, value: "URL" },
      { id: 5, value: "Text" },
    ],

    rules: {
      type: [
        (v) => !!v || "O tipo é obrigatório",
      ],
      pushTitleRules: [
        (v) => !!v || "O título para o push é obrigatório",
        (v) =>
          (v && v.length <= 65) || "O título deve ter no máximo 65 caracteres",
      ],
      messageRules: [
        (v) => !!v || "A menságem é obrigatória",
        (v) => (v && v.length <= 1024 * 10) || "A mensagem é muito grande",
      ],
      contentRules: [(v) => !!v || "O conteúdo é obrigatório"],
      categoryRules: [(v) => !!v || "A categoria é obrigatória"],
      newRules: [(v) => !!v || "O link é obrigatório"],
    },
  }),

  watch: {
    "show": { 
          immediate: true,
          handler(newVal, oldVal) {
            if (newVal){
              this.loadingInstallations = true;
              this.loadingCategories = true;
            
              Promise.all([axios.get(`/categories?limit=1000`), axios.get(`/users/installations?user=${this.user.id}`)])
                      .then(e => {
                        this.categories = e[0].data.data;
                        this.installation.data = e[1].data.data;
                      })
                      .catch((e) => {})
                      .finally((e) => {
                        this.loadingInstallations = false;
                        this.loadingCategories = false;
                      });
            }
          }
    },
    "search.content": _.debounce(function (value) {
      if (value) {
        this.loadingContents = true;

        axios
          .get(`/contents?version=2&search=${value}`)
          .then((e) => {
            this.contents = e.data.data.map((o) => ({
              id: o.id,
              name: o.name,
              category: o.category,
            }));
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000,
            });
          })
          .finally(() => {
            this.loadingContents = false;
          });
      }
    }),
  },


  updated: function () {
    this.$nextTick(function () {
   
    })
  },

  mounted: function () {
    
    // this.loadingInstallations = true;
    // this.loadingCategories = true;
    
    // Promise.all([axios.get(`/categories?limit=1000`), axios.get(`/users/installations?user=${this.user.id}`)])
    //        .then(e => {
    //          this.categories = e[0].data.data;
    //          this.installation.data = e[1].data.data;
    //        })
    //       .catch((e) => {})
    //       .finally((e) => {
    //         this.loadingInstallations = false;
    //         this.loadingCategories = false;
    //       });

    // this.dialog = true;
  },

  computed: {
    initialMCE: function () {
      return config.config;
    },
  },

  methods: {
    format(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    copy: async function(item) {
      await this.$copyText(item.token);
      this.snackbar = true;
    },

    typeChange: function () {
      this.message.value = "";
    },

    onEditorInit: function () {
      this.tinyInited = true;
    },

    onEditorChange: function () {
      this.$forceUpdate();
    },

    save: function(wait) {
      this.saving = true;
      
      //add the user
      this.message.user = this.user.id;
      axios
        .post("messages?version=2", this.message)
        .then(e => {
          this.snackbar = true;
          this.waiting = true;
          this.result.data = e.data.data.map(e => ({success: e.success, device: e.installation.device, date: e.installation.date, token: e.installation.token}));
          //now start waiting the process
          
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.saving = false;
        });      
    }
  },
};
</script>

<style>
</style>