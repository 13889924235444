<template>
  <v-container class="pr-4 pl-4">
    <v-form v-model="form" ref="form">
      <v-row class="mx-4">
        <v-col cols="3" class="d-flex flex-column">
          <v-slide-y-reverse-transition>
            <v-avatar
              class="link"
              style="margin: 0 auto"
              size="96"
              @mouseover="imghover = true"
              @mouseleave="imghover = false"
              @click="$refs.avatar.click()"
            >
              <v-overlay :value="imghover" absolute>
                <v-icon v-if="imghover" color="white" size="36">add_photo_alternate</v-icon>
              </v-overlay>

              <img v-if="newUser.avatar" :src="newUser.avatar" alt />
              <img v-else src="./../assets/user.png" alt />
            </v-avatar>
          </v-slide-y-reverse-transition>
          <input
            type="file"
            ref="avatar"
            style="display:none"
            accept="image/*"
            @change="imageUpload()"
          />

          <v-slide-y-transition>
            <p
              v-if="oversize"
              style="color: red; text-align: center; font-size:12px"
            >Tamanho máximo: 1MB</p>
          </v-slide-y-transition>
        </v-col>

        <v-col cols="9">
          <v-row>
            <v-col>
              <v-text-field
                v-model="newUser.name"
                label="Nome"
                :rules="rules.name"
                maxlength="250"
              />
            </v-col>
            
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-select
                v-model="newUser.type"
                label="Tipo"
                :items="types"
                :item-text="'name'"
                :item-value="'value'"
                :rules="rules.type"
              ></v-select>
            </v-col>
            <v-col class="py-0">
              <v-text-field v-model="newUser.email" label="Email" :rules="rules.email" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-subheader>PAPÉIS E PERMISSÕES</v-subheader>
      <v-row class="mx-2" align="start">
        <v-col cols="12" class="py-0">
          <v-select
            :items="roles"
            :item-text="'name'"
            :item-value="'id'"
            multiple
            chips
            deletable-chips
            v-model="newUser.roles"
            label="Papeis"
            :loading="loading.roles"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>

    <v-subheader>CREDENCIAIS</v-subheader>
    <user-credentials :user="old"></user-credentials>

    <v-subheader>SEÇÃO PREMIUM</v-subheader>
    <div>
      <v-row>
        <v-col>
          <v-text-field
            label="Manual"
            v-model="manualPremium"
            :disabled="!(old.premium && old.premium.manual)"
          />
        </v-col>
        <v-col>
          <v-text-field
            label="Plano"
            :value="(old.plan && old.plan.name) || 'Sem plano'"
            :hint="(old.plan && old.plan.id) || ''"
            persistent-hint
            :disabled="!(old.premium && old.premium.plan)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Voucher"
            :value="(old.voucher && old.voucher.code) || 'Sem voucher'"
            :hint="(old.voucher && old.voucher.id) || ''"
            persistent-hint
            :disabled="!(old.premium && old.premium.voucher)"
          >
            <template v-slot:append v-if="old.voucher">
              <v-icon small @click="goTo('vouchers', old.voucher.id)">open_in_new</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Parceria"
            :value="(old.partnership && old.partnership.name) || 'Sem parceria'"
            :disabled="!old.partnership"
            persistent-hint
            :hint="(old.partnership && old.partnership.id) || ''"
          >
            <template v-slot:append v-if="old.partnership">
              <v-icon small @click="goTo('partnerships', old.partnership.id)">open_in_new</v-icon>
            </template>
          </v-text-field>
        </v-col>                  
      </v-row>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { v4 as uuid } from "uuid";
import omitEmpty from "omit-empty";
import { diff } from "deep-diff";
import UserCredentials from "../components/User.Credentials";

export default {
  components: {
    UserCredentials
  },

  data: () => ({
    loading: {
      users: false,
      roles: false,
      create: false
    },

    imgfile: null,
    maxsize: 1000000,

    imghover: false,

    showPassword: false,
    subscriptions: [],
    dialogs: {
      create: false,
      edit: {
        show: false,
        user: null
      }
    },

    form: false,
    accessform: true,

    old: {},
    newUser: {},
    roles: [],
    access: false,

    name: "",
    type: "",

    total: 0,

    types: [
      {
        value: 1,
        name: "Usuário"
      },
      {
        value: 2,
        name: "Autor"
      }
    ],

    rules: {
      name: [v => !!v || "Nome é obrigatório"],
      email: [
        v => !!v || "Email é obrigatório",
        v =>
          (v && v.length <= 1024) ||
          "A descrição deve ter no máximo 1024 caracteres",
        v =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || "Email inválido."
      ],
      type: [v => !!v || "Tipo é obrigatório"],
      username: [v => !!v || "O nome de usuário é obrigatório"],
      password: [v => !!v || "A senha é obrigatória"]    
    }
  }),

  mounted: function() {
    this.$root.title = "Usuário";

    this.$root.show({ save: true });
    this.$root.actions.save.disable =
      !this.validate || !this.$root.canAccess("PATCH", "/users/");

    this.$data.subscriptions.push(
      this.$root.actions.save.event.subscribe(e => {
        this.save();
      })
    );

    this.$data.loading.users = true;
    this.load();
  },

  computed: {
    displayAccess: function() {
      if (this.access) {
        this.getRoles();
      }

      return this.access ? "Acesso ao sistema" : "Sem acesso ao sistema";
    },

    manualPremium: function() {
      return (this.old.premium && this.old.premium.manual) ? "Sim" : "Não"
    },

    oversize: function() {
      if (this.imgfile) return this.imgfile.size > this.maxsize;
      return false;
    },

    allTypes: function() {
      return [{ name: "Todos", value: "" }, ...this.types];
    },

    hasChanged: function() {
      const changes = diff(this.newUser, this.old);
      return !!changes;
    }
  },

  watch: {
    hasChanged: {
      handler(changed) {
        this.$root.actions.save.disable = !(
          this.$refs.form.validate() && this.hasChanged
        );
      },
      deep: true
    }
  },

  methods: {
    debounce: _.debounce(function() {
      this.load();
    }, 500),

    load: function() {
      const id = this.$route.params.id;
      axios
        .get(`/users/${id}?version=4`)
        .then(e => {
          this.old = e.data.data;
          this.newUser = this.clone(this.old);

          console.log(this.old)
          
          this.access = !!e.data.data.roles;
        })
        .catch(e => {})
        .finally(e => {});

      this.getRoles();
    },

    getRoles: function() {
      axios.get(`roles?version=1`).then(e => {
        this.roles = e.data.data;
      });
    },

    imageUpload: function() {
      const file = this.$refs.avatar.files[0];
      if (file) {
        this.imgfile = file;
        if (!this.oversize) {
          const reader = new FileReader();

          reader.onload = e => {
            const result = reader.result;
            const base = new Uint8Array(result).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            );

            const str = btoa(base);
            this.newUser.avatar = `data:image/jpg;charset=utf-8;base64, ${str}`;
          };
          reader.readAsArrayBuffer(file);
        }
      }
    },

    typeMap(_type) {
      const type = this.types.filter(e => e.value == _type)[0];
      return type ? type.name : "-";
    },

    clone: function(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    edit(item) {
      this.dialogs.edit.show = true;
      this.dialogs.edit.user = item;
    },
    save() {
      this.$root.actions.save.loading = true;
      this.$refs.form.disabled = false
      let keys = diff(this.newUser, this.old).map(e => e.path[0]);

      let obj = { op: "update" };
      keys.forEach(e => {
        Object.assign(obj, {[e]: this.newUser[e]})
      })

      axios
        .patch(`users/${this.old.id}`, obj)
        .then(res => {
          
          this.old = this.clone(this.newUser);

          this.$dialog.notify.info('Usuário salvo com sucesso', {
            position: 'top-right',
            //outlined: true,
            timeout: 2000
          });
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          });          
        })
        .finally(() => {
          this.$refs.form.disabled = false
          this.$root.actions.save.loading = false
        })

    },

    goTo: function(route, id) {
      router.push(`/${route}/${id}`)
    }
  }
};
</script>
<style>
.link:hover {
  cursor: pointer;
}

.error {
  font-size: 12px;
}
</style>