<template>
  <v-container>
    <v-form v-model="form" ref="form">
      <v-row>
        <v-col>
          <v-text-field
            label="ID"
            v-model="current.id"
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="9" cols="12">
          <v-text-field
            label="Título"
            v-model="current.title"
            :rules="rules.title"
          />
        </v-col>
        <v-col md="3" cols="12">
          <v-select
            label="Tipo"
            v-model="current.type"
            :items="types"
            item-text="name"
            item-value="value"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-text-field
            label="Subtítulo"
            v-model="current.subtitle"
            :rules="rules.subtitle"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" cols="12">
          <v-text-field
            label="URL"
            v-model="current.url"
            autocomplete="off"
            :rules="rules.url"
          />
        </v-col>
        <v-col md="4" cols="12">
          <v-select
            label="Peso"
            :items="weights"
            item-text="name"
            item-value="value"
            v-model="current.weight"
            :rules="rules.required"
          ></v-select>
        </v-col>  
      </v-row>
      <v-subheader class="px-0">IMAGEM</v-subheader>
      <v-row>
        <picture-input 
          ref="pictureInput"
          width="600"
          height="150" 
          margin="12" 
          accept="image/jpeg,image/png" 
          size="1" 
          :prefill="prefill"
          :prefillOptions="{mediaType: 'image/png'}"
          button-class="btn"
          :crop="false"
          :custom-strings="{
            upload: '<small>Selecione um arquivo para subir</small>', // HTML allowed
            drag: '<div class=\'mdi mdi-cloud-upload\' style=\'font-size:20px\' /><br><font size=3>Selecione um arquivo ou arraste-o aqui</font>', // HTML allowed
            tap: '<small>Selecione um arquivo da sua galeria</small>',
            change: 'MUDAR IMAGEM', 
            remove: 'REMOVER IMAGEM'                
          }"
          @change="onImageChange">
        </picture-input>       
      </v-row>
      <v-subheader class="px-0">TEXTO</v-subheader>
      <v-row>
        <editor
          style="height: calc(100% - 100px); min-height: 200px; width: 100%"
          ref="editor"
          api-key="no-api-key"
          v-on:input="onChange"
          :disabled="saving"
          :initial-value="current.text"
          :init="{
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
              alignleft aligncenter alignright alignjustify | table image | \
              bullist numlist outdent indent | removeformat | help',
            images_upload_url: 'postAcceptor.php'
          }"
          >
        </editor>
      </v-row>
    
    </v-form>

    <v-snackbar v-model="snackbars.upload">Imagem alterada!</v-snackbar>
    <v-snackbar v-model="snackbars.patch">Altearções salvas!</v-snackbar>
    <v-snackbar v-model="snackbars.copy">Texto copiado!</v-snackbar>

    <v-dialog v-model="removeDialog.show" width="500px" height="200px">
      <v-card>
        <v-card-title class>Remover Banner</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p style="padding-bottom:0px">Ao remover o banner, não será mais possível restaurá-lo. Tem certeza que deseja remover este banner?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="error"
            :loading="removeDialog.loading"
            @click="remove()"
            width="100"
          >Confirmar</v-btn>
          <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import { diff } from "deep-diff";
import PictureInput from 'vue-picture-input'
import Editor from "@tinymce/tinymce-vue";


Vue.use(VueLodash)

export default {
  data: () => ({

    prefill: null,
    saving: false,
    form: false,
    uploadform: false,

    contents: [],

    subscriptions: [],

    darkmenu: false,
    lightmenu: false,

    types: [
      {value: 1, name: 'Para todos'},
      {value: 2, name: 'Para assinantes'},
      {value: 3, name: 'Para não assinantes'}
    ],

    weights: [
      {value: 1, name: 'Peso 1 (menos importante)'},
      {value: 2, name: 'Peso 2'},
      {value: 3, name: 'Peso 3'},
      {value: 4, name: 'Peso 4'},
      {value: 5, name: 'Peso 5'},
      {value: 6, name: 'Peso 6 (mais importante)'},
    ],


    snackbar: false,
    loading: false,
    total: 0,

    snackbars: {
      upload: false,
      patch: false,
      copy: false, 
    },

    removeDialog: {
      show: false,
      loading: false,
    },

    uploadDialog: {
      show: false,
      loading: false,
      file: null,
    },

    rules: {
      title: [
        v => !!v || "O título é obrigatório",
        v => (v && v.length <= 96) || "O título deve ter no máximo 96 caracteres"
      ],
      subtitle: [
        v => (!v || (v && v.length <= 256)) || "O subtítulo deve ter no máximo 236 caracteres"
      ],
      url: [
        v => (!v || (v && v.length <= 256)) || "A URL deve ter no máximo 236 caracteres"
      ],
      required: [
        v => !!v || 'Campo obrigatório',
      ]
    },

    old: {},
    current: {}

  }),

  components: {
    PictureInput,
    editor: Editor,
  },
  
  computed: {
    hasChanged: function() {
      return !!diff(this.current, this.old)
    },
  },

  watch: {
    hasChanged: { 
      handler(changed){
        this.$root.actions.save.disable = !changed || !this.$refs.form.validate();
      }, 
      deep: true
    }

  },

  mounted: function () {
    this.$root.title = "Banner";
    const id = router.currentRoute.params.id
    this.$root.show({create: false, save: true, remove: true})
    this.$root.actions.save.disable = !this.$root.canAccess('PATCH', '/banners/');
    this.$root.actions.remove.disable = !this.$root.canAccess('DELETE', '/banners/');
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.push(this.$root.actions.save.event.subscribe(e => {
      this.save()
    }));

    this.$data.subscriptions.push(this.$root.actions.remove.event.subscribe(e => {
      this.removeDialog.show = true
    }));    

    this.load()
  },

  methods: {

    onChange: _.debounce(function(e) {
      if (tinyMCE.activeEditor) {
        this.current.text = tinyMCE.activeEditor.getContent({});
      }
    }, 500),

    onImageChange (image) {
      if (image) {
        this.current.file = this.$refs.pictureInput.file;
        this.current.image = uuid();

      } else {
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },

    onEditorInit: function(){
      this.tinyInited = true;
      if (this.current.value) tinyMCE.activeEditor.setContent(this.current.text);
    },

    load() {
      const id = this.$router.currentRoute.params.id
      axios
        .get(`banners/${id}?version=1`)
          .then(async res => {
            this.old = res.data.data;
            this.current = this.clone(this.old);
            //this.$refs.pictureInput.prefill = ""

            //this.prefill = "https://www.learningcontainer.com/wp-content/uploads/2020/08/Large-Sample-png-Image-download-for-Testing.png"

            await axios.get(`/resources/${this.current.image}`, {responseType: 'blob'}).then((response)=>{
              var blob = new Blob([response.data], {type: 'image/png'});
              var file = new File([blob], 'no-file');
              this.prefill = file;
            });
          })
    },

    copy: async function(item){
      await this.$copyText(item);
      this.snackbars.copy = true;
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    download(id) {
      var win = window.open(`${axios.defaults.baseURL}/resources/${id}`, '_blank');
      win.focus();
    },

    save() {
      this.$root.actions.save.loading = true;
      //this.$refs.form.disabled = true;

      let keys = diff(this.current, this.old).map(e => e.path[0])
      
      const obj = {op: 'update'}
      keys.forEach(e => {
        Object.assign(obj, {[e]: this.current[e]});
      });

      var promises = [];
      //if has image, update it

      if (obj.image){
        
        var form = new FormData()
        form.append("resource", this.current.file);
        form.append("name", this.current.file.name);

        promises.push(axios.put(`resources/${obj.image}`, form, {headers: {'Content-Type': 'multipart/form-data'}}));
      }

      this.loading = true;
      promises.push(axios.patch(`banners/${this.old.id}`, obj));

      Promise.all(promises)
             .then(res => {
                this.snackbars.patch = true
                this.load()
             })
             .catch(err => {
                this.$dialog.notify.error(this.$codes(err), {
                  position: 'top-right',
                  timeout: 5000
                });          
             })
             .finally(() => {
               // this.$refs.form.disabled = false
                this.$root.actions.save.loading = false
             });
    },

    remove() {
      this.removeDialog.loading = true;

      axios
        .delete(`banners/${this.current.id}`)
        .then(() => {
          this.$router.back()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
    }
  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    next();
  }
};
</script>
<style lang="scss">

  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>