<template>
  <v-container>
    <div>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="name"
            prepend-icon="mdi-magnify"
            label="Nome"
            v-on:input="debounce"
          ></v-text-field>
        </v-col>
         <v-col cols="4">
          <v-text-field
            v-model="email"
            label="E-mail"
            v-on:input="debounce"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="Tipo"
            v-model="type"
            :items="allTypes"
            item-text="name"
            item-value="value"
            @change="debounce"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    
    <v-data-table
      :loading="loading.users"
      loading-text="Aguarde..."
      :headers="headers"
      :items="farms"
      @click:row="edit"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click.stop="curriculum(item)">
          <v-icon small>mdi-content-paste</v-icon>
        </v-btn>
        <v-btn icon @click.stop="message(item)">
          <v-icon small>mdi-message</v-icon>
        </v-btn>
        <v-btn icon @click.stop="voucher(item)">
          <v-icon small>mdi-tag</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.type`]="{ item }">{{ typeMap(item.type) }}</template>
    </v-data-table>

    <v-dialog v-model="dialogs.create" width="600">
      <v-card>
        <v-card-title>Criar usuário</v-card-title>

        <v-container class="pr-4 pl-4">
          <v-form v-model="form">
            <v-row class="mx-4">
              <v-col cols="3" class="d-flex flex-column">
                <v-slide-y-reverse-transition>
                  <v-avatar
                    class="link"
                    style="margin: 0 auto"
                    size="96"
                    @mouseover="imghover = true"
                    @mouseleave="imghover = false"
                    @click="$refs.avatar.click()"
                  >
                    <v-overlay :value="imghover" absolute>
                      <v-icon v-if="imghover" color="white" size="36">add_photo_alternate</v-icon>
                    </v-overlay>

                    <img v-if="newUser.avatar" :src="newUser.avatar" alt />
                    <img v-else src="./../assets/user.png" alt />
                  </v-avatar>
                </v-slide-y-reverse-transition>
                <input
                  type="file"
                  ref="avatar"
                  style="display:none"
                  accept="image/*"
                  @change="imageUpload()"
                />

                <v-slide-y-transition>
                  <p
                    v-if="oversize"
                    style="color: red; text-align: center; font-size:12px"
                  >Tamanho máximo: 1MB</p>
                </v-slide-y-transition>
              </v-col>

              <v-col cols="9">
                <v-row>
                  <v-text-field
                    v-model="newUser.name"
                    label="Nome"
                    :rules="rules.name"
                    maxlength="250"
                  />
                </v-row>
                <v-row>
                  <v-text-field v-model="newUser.email" label="Email" :rules="rules.email" />
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mx-2">
              <v-col class="py-0">
                <v-select
                  v-model="newUser.type"
                  label="Tipo"
                  :items="types"
                  :item-text="'name'"
                  :item-value="'value'"
                  :rules="rules.type"
                ></v-select>
              </v-col>
            </v-row>

            <v-col cols="6" class="py-0 mx-2">
              <v-switch v-model="newUser.access" :label="displayAccess"></v-switch>
            </v-col>
          </v-form>

          <v-form v-model="accessform">
            <v-expand-transition>
              <div v-if="newUser.access">
                <div>
                  <v-subheader>USUÁRIO E SENHA</v-subheader>
                  <v-row class="mx-2" align="start">
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        label="Usuário"
                        v-model="newUser.username"
                        :rules="rules.username"
                        maxlength="32"
                      />
                    </v-col>

                    <v-col class="py-0" cols="6">
                      <v-text-field
                        label="Senha"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="newUser.password"
                        @click:append="showPassword = !showPassword"
                        maxlength="32"
                        :rules="rules.password"
                      />
                    </v-col>
                  </v-row>
                </div>

                <div>
                  <v-subheader>PAPÉIS E PERMISSÕES</v-subheader>
                  <v-row class="mx-2" align="start">
                    <v-col cols="12" class="py-0">
                      <v-select
                        :items="roles"
                        :item-text="'name'"
                        :item-value="'id'"
                        multiple
                        chips
                        deletable-chips
                        v-model="newUser.roles"
                        label="Selecione..."
                        :loading="loading.roles"
                        :rules="rules.roles"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-expand-transition>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="primary"
              :loading="loading.create"
              @click="create()"
              :disabled="!validateForms"
              width="100"
            >Confirmar</v-btn>
            <v-btn text color="primary" @click="dialogs.create = false" width="100">Cancelar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog width="600" v-model="dialogs.edit.show">
      <user-edit :user="dialogs.edit.user" v-on:close="dialogs.edit.show = false"></user-edit>
    </v-dialog>

    <v-dialog width="600" v-model="dialogs.message.show">
      <push-create :key="$root.componentKey" v-on:close="dialogs.message.show = false" :user="dialogs.message.user" :show="dialogs.message.show"></push-create>
    </v-dialog>

    <v-dialog width="600" v-model="dialogs.voucher.show">
      <voucher-use :key="$root.componentKey" v-on:close="dialogs.voucher.show = false" :user="dialogs.voucher.user" :show="dialogs.voucher.show"></voucher-use>
    </v-dialog>

    <v-snackbar v-model="snackbar" v-if="dialogs.message.user">Mensagem enviada a {{ dialogs.message.user.name }}.</v-snackbar>

  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import pick from "object.pick";
import { v4 as uuid } from "uuid";
import omitEmpty from "omit-empty";
import * as storage from "../libs/storage";
import UserEdit from "../components/User.Edit";
import PushCreate from "../components/Push.Create";
import VoucherUse from "../components/Voucher.Use";

export default {
  components: {
    UserEdit,
    'voucher-use': VoucherUse,
    'push-create': PushCreate
  },

  data: () => ({
    loading: {
      users: false,
      roles: false,
      create: false
    },

    snackbar: false,

    imgfile: null,
    maxsize: 1000000,

    imghover: false,
    options: {
      page: 1,
      itemsPerPage: 10
    },

    showPassword: false,
    subscriptions: [],
    dialogs: {
      create: false,
      edit: {
        show: false,
        user: null
      },
      message: {
        show: false
      },
      voucher: {
        show: false
      }
    },

    form: false,
    accessform: true,

    newUser: {},
    roles: [],

    name: "",
    type: "",
    email: "",

    total: 0,
    headers: [
      { text: "NOME", value: "name", sortable: false },
      { text: "TIPO", value: "type", sortable: false },
      { text: "E-MAIL", value: "email", sortable: false },
      {
        text: "ID",
        align: "left",
        width: "300px",
        sortable: false,
        value: "id"
      },
      { text: "AÇÕES", value: "action", width: "150px", sortable: false }
    ],
    farms: [],

    types: [
      {
        value: 1,
        name: "Usuário"
      },
      {
        value: 2,
        name: "Autor"
      }
    ],

    rules: {
      name: [
        v => !!v || "Nome é obrigatório"
        // v => (v && v.length >=4 && v.length<=32) || 'O código deve ter entre 4 e 32 caracteres',
      ],
      email: [
        v => !!v || "Email é obrigatório",
        v =>
          (v && v.length <= 1024) ||
          "A descrição deve ter no máximo 1024 caracteres",
        v =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || "Email inválido."
      ],
      type: [
        v => !!v || "Tipo é obrigatório"
        // v => (v && v.length <= 1024) || 'A descrição deve ter no máximo 1024 caracteres',
      ],
      username: [v => !!v || "O nome de usuário é obrigatório"],
      password: [v => !!v || "A senha é obrigatória"],
      roles: [v => !!v.length || "Pelo menos um papel é obrigatório"]
    }
  }),

  watch: {
    options: {
      async handler() {
        this.load();
      },
      deep: true
    },

    'dialogs.message.show': function(open) {
      if (!open) this.$root.componentKey ++
    }
  },

  mounted: function() {
    this.$root.title = "Usuários";
    this.$root.show({ create: true });

    this.$data.subscriptions.push(
      this.$root.actions.create.event.subscribe(e => {
        this.dialogs.create = true;
      })
    );

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("users")) || {}, ["options","filters","name"])
    );

    this.$data.loading.users = true;
    this.load();
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("users", JSON.stringify(this.$data));
    next();
  },

  computed: {
    displayAccess: function() {
      if (this.newUser.access) {
        this.getRoles();
      }

      return this.newUser.access
        ? "Acesso ao sistema"
        : "Sem acesso ao sistema";
    },

    validateForms: function() {
      if (this.newUser.access) {
        return !!(this.form && this.accessform);
      }
      return !!this.form;
    },

    oversize: function() {
      if (this.imgfile) return this.imgfile.size > this.maxsize;
      return false;
    },

    allTypes: function() {
      return [{ name: "Todos", value: "" }, ...this.types];
    }
  },

  methods: {
    debounce: _.debounce(function() {
      this.load();
    }, 500),

    curriculum: function(item) {
      router.push(`/users/${item.id}/curriculum`);
    },    

    load: function() {
      const limit = this.options.itemsPerPage || 10;
      const start = (this.options.page - 1) * limit;

      this.$data.loading.users = true;
      axios
        .get(
          `/users?version=3&start=${start}&limit=${limit}&name=${this.name}&email=${this.email}&type=${this.type}&sort=-created`
        )
        .then(e => {
          this.$data.farms = e.data.data;
          this.$data.total = e.data.total;
        })
        .catch(e => {})
        .finally(e => {
          this.$data.loading.users = false;
        });
    },

    getRoles: function() {
      axios.get(`roles?version=1`).then(e => {
        this.roles = e.data.data;
      });
    },

    imageUpload: function() {
      const file = this.$refs.avatar.files[0];
      if (file) {
        this.imgfile = file;
        if (!this.oversize) {
          const reader = new FileReader();

          reader.onload = e => {
            const result = reader.result;
            const base = new Uint8Array(result).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            );

            const str = btoa(base);
            this.newUser.avatar = `data:image/jpg;charset=utf-8;base64, ${str}`;
          };
          reader.readAsArrayBuffer(file);
        }
      }
    },

    message: function(user) {
      this.dialogs.message.show = true
      this.dialogs.message.user = user
    },

    voucher: function(user) {
      this.dialogs.voucher.show = true
      this.dialogs.voucher.user = user
    },

    typeMap(_type) {
      const type = this.types.filter(e => e.value == _type)[0];
      return type ? type.name : "-";
    },

    create: async function() {
      this.loading.create = true;
      const form = new FormData();

      if (this.imgfile && !this.oversize) {
        form.append("resource", this.imgfile);
        form.append("name", this.newUser.name);
      }

      const credential = uuid();

      const user = omitEmpty({
        id: uuid(),
        name: this.newUser.name,
        email: this.newUser.email,
        type: this.newUser.type,
        roles: this.newUser.roles
      });

      if (this.newUser.access) {
        const user = omitEmpty({
          id: uuid(),
          name: this.newUser.name,
          email: this.newUser.email,
          type: this.newUser.type,
          roles: this.newUser.roles,
          source: {
            id: uuid(),
            name: "cmr"
          }
        });

        const cred = {
          id: user.source.id,
          username: this.newUser.username,
          password: this.newUser.password
        };

        const resource = uuid();

        if (this.imgfile && !this.oversize) {
          await axios.put(`resources/${resource}`, form, {
            headers: { "Content-Type": "multipart/form-data" }
          });
          Object.assign(user, {picture: resource})
        }
        axios
          .post(`credentials?version=2`, cred)
          .then(() => axios.post(`users?version=1`, user))
          .then(() => {
            this.loading.create = false;
            this.$dialog.notify.info("Usuário criado!", {
              position: "top-right",
              timeout: 2000
            });

            this.dialogs.create = false;
            this.load();
          })
          .catch(err => {
            this.$dialog.notify.error(this.$codes(err), {
              position: "top-right",
              timeout: 5000
            });

            this.loading.create = false;
          });
      } else {
        const user = omitEmpty({
          id: uuid(),
          name: this.newUser.name,
          email: this.newUser.email,
          type: this.newUser.type
        });

        const resource = uuid();

        if (this.imgfile && !this.oversize) {
          await axios.put(`resources/${resource}`, form, {
            headers: { "Content-Type": "multipart/form-data" }
          });
          Object.assign(user, {picture: resource})
        }
        axios
          .post(`users?version=2`, user)
          .then(e => {
            this.loading.create = false;
            this.$dialog.notify.info("Usuário criado!", {
              position: "top-right",
              timeout: 2000
            });

            this.dialogs.create = false;
            this.load();
          })
          .catch(err => {});
      }
    },

    edit(item) {
      this.$router.push(`/users/${item.id}`)
    }
  }
};
</script>
<style>
.link:hover {
  cursor: pointer;
}

.error {
  font-size: 12px;
}
</style>