<template>
  <v-container class="pr-4 pl-4">
    <v-form v-model="form">
      <v-row class="mx-4">
        <v-col cols="3" class="d-flex flex-column">
          <v-slide-y-reverse-transition>
            <v-avatar
              class="link"
              style="margin: 0 auto"
              size="96"
              @mouseover="imghover = true"
              @mouseleave="imghover = false"
              @click="$refs.avatar.click()"
            >
              <v-overlay :value="imghover" absolute>
                <v-icon v-if="imghover" color="white" size="36">add_photo_alternate</v-icon>
              </v-overlay>

              <img v-if="newUser.avatar" :src="newUser.avatar" alt />
              <img v-else src="./../assets/user.png" alt />
            </v-avatar>
          </v-slide-y-reverse-transition>
          <input
            type="file"
            ref="avatar"
            style="display:none"
            accept="image/*"
            @change="imageUpload()"
          />

          <v-slide-y-transition>
            <p
              v-if="oversize"
              style="color: red; text-align: center; font-size:12px"
            >Tamanho máximo: 1MB</p>
          </v-slide-y-transition>
        </v-col>

        <v-col cols="9">
          <v-row>
            <v-col>
              <v-text-field
                v-model="newUser.name"
                label="Nome"
                :rules="rules.name"
                maxlength="250"
              />
            </v-col>
            <v-col>
              <v-text-field v-model="newUser.email" label="Email" :rules="rules.email" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-select
                v-model="newUser.type"
                label="Tipo"
                :items="types"
                :item-text="'name'"
                :item-value="'value'"
                :rules="rules.type"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-form v-model="accessform">
      <v-expand-transition>
        <div v-if="newUser.access">
          <div>
            <v-subheader>USUÁRIO E SENHA</v-subheader>
            <v-row class="mx-2" align="start">
              <v-col cols="6" class="py-0">
                <v-text-field
                  label="Usuário"
                  v-model="newUser.username"
                  :rules="rules.username"
                  maxlength="32"
                />
              </v-col>

              <v-col class="py-0" cols="6">
                <v-text-field
                  label="Senha"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="newUser.password"
                  @click:append="showPassword = !showPassword"
                  maxlength="32"
                  :rules="rules.password"
                />
              </v-col>
            </v-row>
          </div>

          <div>
            <v-subheader>PAPÉIS E PERMISSÕES</v-subheader>
            <v-row class="mx-2" align="start">
              <v-col cols="12" class="py-0">
                <v-select
                  :items="roles"
                  :item-text="'name'"
                  :item-value="'id'"
                  multiple
                  chips
                  deletable-chips
                  v-model="newUser.roles"
                  label="Selecione..."
                  :loading="loading.roles"
                  :rules="rules.roles"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-expand-transition>
    </v-form>
  </v-container>
</template>

<script>
import { diff } from "deep-diff";

export default {
  name: "user-edit",

  props: {
    user: Object
  },

  data: () => ({
    form: false,
    accessform: false,

    imghover: false,
    newUser: {},
    
    rules: {
      name: [v => !!v || "Nome é obrigatório"],
      email: [
        v => !!v || "Email é obrigatório",
        v =>
          (v && v.length <= 1024) ||
          "A descrição deve ter no máximo 1024 caracteres",
        v => {
          const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          return regex.test(v) || "Email inválido.";
        }
      ],
      type: [v => !!v || "Tipo é obrigatório"],
      username: [v => !!v || "O nome de usuário é obrigatório"],
      password: [v => !!v || "A senha é obrigatória"],
      roles: [v => !!v.length || "Pelo menos um papel é obrigatório"]
    },

    types: [
      {
        value: 1,
        name: "Usuário"
      },
      {
        value: 2,
        name: "Autor"
      }
    ],

    loading: {}
  }),

  computed: {
    hasChanged: function() {
      const changes = diff(this.newUser, this.user);
      return !!changes;
    },

    validate: function() {
      return this.hasChanged && this.form;
    }
  },

  watch: {
    user: {
      deep: true,
      handler: function(val) {
        this.newUser = this.clone(val);
      }
    }
  },

  methods: {
    oversize: function() {
      if (this.imgfile) return this.imgfile.size > this.maxsize;
      return false;
    },

    clone: function(obj) {
      return JSON.parse(JSON.stringify(obj));
    }
  },

  mounted: function() {
    this.newUser = this.clone(this.user);
  }
};
</script>

<style>
</style>