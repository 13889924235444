<template>
  <v-card>
    <v-card-title>ALTERAR DE ACESSO</v-card-title>

    <v-card-text>
        <p>Ao confirmar a execução, todos os verbetes vinculados a esta categoria passarão a ser do tipo <b>{{ parseType }}</b>. Tem certeza que deseja alterar o acesso desta categoria?</p>
    </v-card-text>

    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')" :disabled="loading">CANCELAR</v-btn>
        <v-btn text @click="save()" :loading="loading">CONFIRMAR</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        category: String,
        type: String
    },

    data: () => ({
        loading: false
    }),

    computed: {
        parseType: function() {
            return this.type ? this.type.toUpperCase() : ""
        }
    },

    methods: {
        save() {
            this.loading = true

            const obj = {
                op: 'access',
                premium: this.type.toLowerCase() == 'premium',
                category: this.category
            }

            axios
                .patch(`contents/`, obj)
                .then(() => {
                    this.$emit('save')
                })
                .finally(() => this.loading = false)
        }
    }
}
</script>

<style>

</style>