<template>
    
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-row align="center" justify="center" style="margin:0px">
        <v-col cols="10" sm	="8" md="6" lg="3">
          <v-row align="center" justify="center">
            <img v-bind:src="require('../assets/' + app.logo)" style="height: 120px; margin:60px; object-fit:contain"/>
          </v-row>
          <v-row align="center" justify="center" style="height: 300px;" >
  
            <v-form ref="form" v-model="valid" lazy-validation style="width:100%" @keyup.native.enter="valid && login($event)" >

              <v-text-field ref="username" prepend-inner-icon="person_pin" filled v-model="username" :counter="50" :rules="rules.username" label="Usuário" required :disabled="loading"></v-text-field>
              <v-text-field ref="password" prepend-inner-icon="security" filled v-model="password" :rules="rules.password" label="Senha" required :disabled="loading" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :type="show ? 'text' : 'password'"></v-text-field>

              <v-select
                required
                label="Aplicativo"
                :items="apps"
                v-model="app"
                item-text="name"
                item-value="code"
                @input="changeLogo"
                filled
              >
                <template slot="selection" scope="data">
                    <v-list-item-avatar size="16">
                      <img v-bind:src="require('../assets/' + data.item.icon)"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <template slot="item" scope="data">
                    <v-list-item-avatar>
                      <img v-bind:src="require('../assets/' + data.item.icon)"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="width: 1%" v-text="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                </template>
              </v-select>

              <v-btn block text large color="primary" style="height:56px; margin-top:10px" :disabled="empty() || !valid || loading" :loading="loading" @click="login()">Realizar Login</v-btn>

            </v-form>

            

          </v-row>
        </v-col>
      </v-row>
      
  </v-container>

</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import * as jwt from "jwt-decode";

export default {
  data: () => ({
    username: '',
    password: '',
    app: {logo:'logo-app-1.png'},
    loading: false,
    valid: true,
    show: false,
    apps: [{
      name: 'Consulta +Rápida',
      code: 1,
      color: '#9a009d',
      icon: 'app-1.png',
      logo: 'logo-app-1.png'
    },{
      name: 'Psiquiatria +Rápida',
      code: 2,
      color: '#5e9939',
      icon: 'app-2.png',
      logo: 'logo-app-2.png'
    }],
    rules: {
      username: [
        v => !!v || 'O usuário é obrigatório',
        v => (v && v.length <= 50) || 'O usuário deve ter menos de 10 caracteres',
      ],
      password: [
        v => !!v || 'A senha é obrigatória'
      ] 
    }    
  }),

  mounted: function () {
    localStorage.removeItem("token");
    
    this.changeLogo(1);
    this.$nextTick(function () {
      
      //axios.r
      //this.$refs.form.validate();
    })
  },

  methods: {
    changeLogo(code){
      this.app = this.apps.filter(e=> e.code == code)[0];
      localStorage.setItem('app', JSON.stringify(this.app));
    },
    empty(){
      return !this.$data.username || !this.$data.password; 
    },
    login() {
      this.$data.loading = true;
      axios
        .post('/sessions', { username: this.$data.username, password: this.$data.password })
        .then(e => {
          localStorage.setItem('token', JSON.stringify(e.data.data.token));

          var data = jwt(e.data.data.token.access);
          return axios.get(`/users/${data.id}`)
        })
        .then(e => {
          
          this.$root.user = e.data.data;
          localStorage.setItem('user', JSON.stringify(e.data.data));
          
          router.push("/");
        })
        .catch(e => {
           this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            //outlined: true,
            timeout: 5000
          });
          this.$data.loading = false;
        });

     
    },
    validate () {
      if (this.$refs.form.validate()) {
        ;
      }
    },
    reset () {
      this.$refs.form.reset();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style lang="scss">
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }
</style>