import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Entries from "../views/Entries.vue";
import EntryView from "../views/Entry.View.vue";
import EntryRevisionView from "../views/Entry.Revision.View.vue";
import EntryEdit from "../views/Entry.Edit.vue";
import EntryLinks from "../views/Entry.Links.vue";
import EntryEditMore from "../views/Entry.EditMore.vue";
import Login from "../views/Login.vue";
import Users from "../views/Users.vue";
import Files from "../views/Files.vue";
import Messages from "../views/Messages.vue";
import Message from "../views/Message.vue";
import Vouchers from "../views/Vouchers.vue";
import Voucher from "../views/Voucher.vue";
import Privacity from "../views/Privacity.vue";
import Terms from "../views/Terms.vue";
import ReferralTerms from "../views/ReferralTerms.vue";
import Authors from "../views/Authors.vue";
import Imports from "../views/Imports.vue";
import CurriculumEdit from "../views/Curriculum.Edit.vue";
import CurriculumView from "../views/Curriculum.View.vue";
import EntryRevisions from "../views/Entry.Revisions.vue";
import Plans from "../views/Plans.vue";
import Partnerships from "../views/Partnerships.vue";
import Partnership from "../views/Partnership.vue";
import Specialties from "../views/Specialties.vue";
import Specialty from "../views/Specialty.vue";
import Categories from "../views/Categories.vue";
import Category from "../views/Category.vue";
import Banners from "../views/Banners.vue";
import Banner from "../views/Banner.vue";
import Import from "../views/Import.vue";
import User from "../views/User.vue";
import Copyright from "../views/Copyright.vue";
import Pages from "../views/Pages.vue";
import Page from "../views/Page.vue";
import Views from "../views/Views.vue";
import Substance from "../views/Substance.vue";
import Substances from "../views/Substances.vue";
import Referrals from "../views/Referrals.vue";
import Compares from "../views/Compares.vue";
import Compare from "../views/Compare.vue";

//the route guard
const guard = function (to: any, from: any, next: any) {
    if (!localStorage.getItem("token")) next("/login");
    else next();
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/home",
        alias: "/",
        name: "home",
        component: Home,
        beforeEnter: guard
    },
    {
        path: "/entries",
        name: "entries",
        component: Entries,
        beforeEnter: guard
    },
    {
        path: "/files",
        name: "files",
        component: Files,
        beforeEnter: guard
    },
    {
        path: "/plans",
        name: "plans",
        component: Plans,
        beforeEnter: guard
    },
    {
        path: "/messages/:id",
        name: "message",
        component: Message,
        beforeEnter: guard
    },
    {
        path: "/messages",
        name: "messages",
        component: Messages,
        beforeEnter: guard
    },
    {
        path: "/vouchers",
        name: "vouchers",
        component: Vouchers,
        beforeEnter: guard
    },
    {
        path: "/vouchers/:id",
        name: "voucher",
        component: Voucher,
        beforeEnter: guard
    },
    {
        path: "/entries/:id/view",
        name: "entry.view",
        component: EntryView,
        beforeEnter: guard
    },
    {
        path: "/entries/:id/revisions/:revision/view",
        name: "entry.revison.view",
        component: EntryRevisionView,
        beforeEnter: guard
    },
    {
        path: "/entries/:id/revisions",
        name: "entry.revisions",
        component: EntryRevisions,
        beforeEnter: guard
    },
    {
        path: "/entries/:id/links",
        name: "entry.links",
        component: EntryLinks,
        beforeEnter: guard
    },
    {
        path: "/entries/:id/revisions/:revision/edit",
        name: "entry.edit",
        component: EntryEdit,
        beforeEnter: guard
    },
    {
        path: "/users/:id/curriculum",
        name: "curriculum.view",
        component: CurriculumView
    },
    {
        path: "/users/:id/curriculum/edit",
        name: "curriculum.edit",
        component: CurriculumEdit,
        beforeEnter: guard
    },
    {
        path: "/entries/:id/editMore",
        name: "entry.editMore",
        component: EntryEditMore,
        beforeEnter: guard
    },
    {
        path: "/users",
        name: "users",
        component: Users,
        beforeEnter: guard
    },
    {
        path: "/users/:id",
        name: "user",
        component: User,
        beforeEnter: guard
    },
    {
        path: "/compares",
        name: "compares",
        component: Compares,
        beforeEnter: guard
    },
    {
        path: "/compare",
        name: "compare",
        component: Compare,
        beforeEnter: guard
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/privacity",
        name: "privacity",
        component: Privacity
    },
    {
        path: "/terms",
        name: "terms",
        component: Terms
    },
    {
        path: "/terms",
        name: "referral_terms",
        component: ReferralTerms
    },
    {
        path: "/authors",
        name: "authors",
        component: Authors
    },
    {
        path: "/views/:slug",
        name: "views",
        component: Views
    },
    {
        path: "/imports",
        name: "imports",
        component: Imports,
        beforeEnter: guard
    },
    {
        path: "/imports/:id",
        name: "import",
        component: Import,
        beforeEnter: guard
    },
    {
        path: "/partnerships",
        name: "partnerships",
        component: Partnerships,
        beforeEnter: guard
    },
    {
        path: "/partnerships/:id",
        name: "partnership",
        component: Partnership,
        beforeEnter: guard
    },
    {
        path: "/specialties",
        name: "specialties",
        component: Specialties,
        beforeEnter: guard
    },
    {
        path: "/specialties/:id",
        name: "specialty",
        component: Specialty,
        beforeEnter: guard
    },
    {
        path: "/categories",
        name: "categories",
        component: Categories,
        beforeEnter: guard
    },
    {
        path: "/categories/:id",
        name: "category",
        component: Category,
        beforeEnter: guard
    },
    {
        path: "/banners",
        name: "banners",
        component: Banners,
        beforeEnter: guard
    },
    {
        path: "/banners/:id",
        name: "banner",
        component: Banner,
        beforeEnter: guard
    },
    {
        path: "/copyright",
        name: "copyright",
        component: Copyright,
        beforeEnter: guard
    },
    {
        path: "/pages",
        name: "pages",
        component: Pages,
        beforeEnter: guard
    },
    {
        path: "/pages/:id",
        name: "page",
        component: Page,
        beforeEnter: guard
    },
    {
        path: "/substances",
        name: "substances",
        component: Substances,
        beforeEnter: guard
    },
    {
        path: "/substances/:id",
        name: "substance",
        component: Substance,
        beforeEnter: guard
    },
    {
        path: "/referrals",
        name: "referrals",
        component: Referrals,
        beforeEnter: guard
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
