<template>
  <v-container>
    <v-form v-model="form" ref="form">
      <v-row>
        <v-col>
          <v-text-field
            label="Nome"
            v-model="specialty.name"
            :rules="rules.name"
            counter="128"
          />
        </v-col>
      
        <v-col>
          <v-text-field
            label="ID"
            v-model="specialty.id"
            readonly
          />
        </v-col>        
      
      </v-row>
    </v-form>
    <v-subheader>Verbetes vinculados</v-subheader>
    <v-row>
      <v-col>
        <v-data-table
          :items="contents"
          :headers="headers"
          :loading="loading.contents"
          :server-items-length="total"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
        >
          <template v-slot:[`item.id`] ="{ item }">
            <div class="copyable" @click="copy(item.id)">{{ item.id }}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbars.copy">Texto Copiado!</v-snackbar>
    <v-snackbar v-model="snackbars.patch">Especialidade atualizada!</v-snackbar>
    <v-snackbar v-model="snackbars.remove">Especialidade removida.</v-snackbar>

    <v-dialog 
      v-model="removeDialog.show"
      width="500"
      height="200"
    >
      <v-card>
        <v-card-title class="">Remover Especialidade</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p style="padding-bottom:0px">Ao remover a especialidade, não será mais possível restaurá-la. Tem certeza que deseja remover esta especialidade?</p>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn text color="error"  :loading="removeDialog.loading" @click="remove()" width="100">Confirmar</v-btn>
            <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import { diff } from "deep-diff";

Vue.use(VueLodash)

export default {
  data: () => ({
    specialty: {},
    old: {},

    form: false,

    contents: [],
    
    removeDialog: {
      show: false,
      loading: false
    },

    subscriptions: [],
    snackbars: {
      copy: false,
      patch: false,
      remove: false,
    },

    loading: {
      contents: false,
      view: false
    },

    options: {
      page: 1,
      itemsPerPage: 10,
    },

    total: 0,

    headers: [
      { text: 'NOME', value: 'name', sortable: false },
      { text: 'ID', value: 'id', width: '320px', sortable: false },
      { tet: 'AÇÕES', value: 'actions', width: '60px', sortable: false }
    ],
    
    rules: {
      name: [
        v => !!v || 'O nome é obrigatório',
        v => (v && v.length < 128) || 'O nome possui tamanho máximo de 128 letras.'
      ]
    },    
  }),
  
  computed: {
    hasChanged() {
      const changes = diff(this.specialty, this.old)
      return !!changes
    }
  },

  watch: {
    options: {
      deep: true,
      handler: function() {
        this.getContents()
      }
    },

    hasChanged: {
      deep: true,
      handler: function(changed) {
        this.$root.actions.save.disable = !changed || !this.$refs.form.validate()
      }
    }
  },

  mounted: function () {
    this.$root.title = "Especialidade";

    this.$root.show({create: false, save: true, remove: true});
    this.$root.actions.save.disable = !this.$root.canAccess('PATCH', '/specialties/');
    this.$root.actions.remove.disable = !this.$root.canAccess('DELETE', '/specialties/');

    this.subscriptions.push(this.$root.actions.save.event.subscribe(() => {
      this.save()
    }));

    this.subscriptions.push(this.$root.actions.remove.event.subscribe(() => {
      this.removeDialog.show = true
    }));

    this.load()
    this.getContents()
  },

  methods: {
    load() {
      const id = this.$router.currentRoute.params.id     
      this.loading.view = true
      axios
        .get(`specialties/${id}?version=1`)
          .then(res => {
            this.old = res.data.data
            this.specialty = this.clone(this.old)
            this.loading.view = false
          })      
    },

    copy: async function(text) {
      await this.$copyText(text)
      this.snackbars.copy = true
    },

    getContents() {
      const start = (this.options.page - 1) * this.options.itemsPerPage
      const limit = this.options.itemsPerPage

      this.loading.contents = true
      const id = this.$router.currentRoute.params.id
      axios
        .get(`contents?version=1&specialty=${id}&start=${start}&limit=${limit}`)
        .then(res => {
          this.contents = res.data.data
          this.loading.contents = false

          this.total = res.data.total
        })
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    content(id) {
      this.$router.replace({path: `entries/${id}/view`})
    },

    save() {
      this.$root.actions.save.loading = true
      const keys = diff(this.specialty, this.old)
        .map(e => e.path)
      
      const obj = {op: 'update'}
      keys.forEach(e => {
        Object.assign(obj, {[e]: this.specialty[e]})
      })

      axios
        .patch(`specialties/${this.specialty.id}`, obj)
        .then(() => {
          this.snackbars.patch = true
          this.load()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          })
        })
        .finally(() => {
          this.$root.actions.save.loading = false
        })
    },

    remove() {
      this.removeDialog.loading = true

      axios
        .delete(`specialties/${this.specialty.id}`)
        .then(() => {
          this.snackbars.remove = true
          this.$router.back()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          });
        })
    }
  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    next();
  }
};
</script>
<style lang="scss">
  
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>