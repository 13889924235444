<template>
  <v-container>
    <div v-if="loading.retrieving">
      <v-skeleton-loader
        :loading="true"
        :transition="'fade-transition'"
        type="article"
      >
      </v-skeleton-loader>
      <v-skeleton-loader
        :loading="true"
        :transition="'fade-transition'"
        type="article"
      >
      </v-skeleton-loader>
      <v-skeleton-loader
        :loading="true"
        :transition="'fade-transition'"
        type="article"
      >
      </v-skeleton-loader>
      <br />
      <br />
      <div style="display: flex; isplay: flex; justify-content: flex-end">
        <v-skeleton-loader
          width="20%"
          :loading="true"
          :transition="'fade-transition'"
          type="text"
        >
        </v-skeleton-loader>
      </div>
      <br />
      <br />
      <v-skeleton-loader
        :loading="true"
        :transition="'fade-transition'"
        type="sentences"
      >
      </v-skeleton-loader>
      <br />
      <br />
    </div>

    <div v-if="!loading.retrieving">
      <v-form ref="form">
        <v-row>
          <v-col cols>
            <v-text-field
              label="Título App"
              v-model="current.title"
              :rules="rules.required"
              counter="128"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Título PUSH"
              counter="64"
              :value="current.ptitle"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Conteúdo"
              disabled
              rows="1"
              :value="current.content"
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field label="ID" v-model="current.id" disabled />
          </v-col>
          <v-col>
            <v-text-field
              label="Data"
              :value="dateformat(current.date)"
              disabled
            />
          </v-col>
          <v-col>
            <v-select
              label="Tipo"
              :items="types"
              item-text="value"
              item-value="id"
              v-model="current.type"
              :rules="rules.required"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="current.type == 2">
          <v-col>
            <p class="text--secondary text-justify">
              Esta mensagem apenas envia uma notificação de PUSH para os
              usuários, porém vincula a uma CATEGORIA, conforme selecionado
              abaixo.
            </p>
            <v-select
              label="Categoria"
              item-value="id"
              item-text="name"
              v-model="current.value"
              :items="categories"
              :loading="loadingCategories"
              :rules="rules.required"
            >
              <template slot="selection" scope="data">
                <v-list-item-avatar size="16">
                  <img
                    v-bind:src="`${$root.url}/resources/` + data.item.image"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="data.item.name || data.item"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template slot="item" scope="data">
                <v-list-item-avatar>
                  <img
                    v-bind:src="`${$root.url}/resources/` + data.item.image"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    style="width: 1%"
                    v-text="data.item.name || data.item"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="current.type == 3">
          <v-col>
            <p class="text--secondary text-justify">
              Esta mensagem apenas envia uma notificação de PUSH para os
              usuários, porém vincula a um VERBETE, conforme selecionado abaixo.
            </p>
            <v-autocomplete
              v-model="current.value"
              :items="contents"
              item-text="name"
              item-value="id"
              :loading="loadingContents"
              :search-input.sync="search.content"
              label="Verbete"
              no-data-text="Nenhum conteúdo encontrado"
              :rules="rules.required"
            >
              <template slot="selection" scope="data" v-if="contents.length">
                <v-list-item-avatar size="16">
                  <img
                    v-bind:src="`${$root.url}/resources/` + getContentCategory(data.item)"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template slot="item" scope="data">
                <v-list-item-avatar>
                  <img
                    v-bind:src="
                      `${$root.url}/resources/` + getContentCategory(data.item)"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="current.type == 4">
          <v-col>
            <p class="text--secondary text-justify">
              Esta mensagem apenas envia uma notificação de PUSH para os
              usuários, porém vincula a uma LINK DE NOTÍCIA, conforme informado
              abaixo.
            </p>
            <v-text-field
              v-model="current.value"
              counter="255"
              label="Notícia"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="current.type == 5">
          <v-col>
            <p class="text--secondary text-justify">
              Esta mensagem apenas vincula uma URL ao seu conteúdo.
            </p>
            <v-text-field
              v-model="current.value"
              counter="255"
              label="URL"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="current.type == 6">
          <v-col>
            <p class="text--secondary text-justify">
              Esta mensagem vincula um texto formatado ao seu conteúdo.
            </p>
            <editor
              id="app-editor"
              ref="editor"
              api-key="no-api-key"
              v-model="current.value"
              v-on:onInit="onEditorInit"
              :init="initialMCE"
              v-on:input="onEditorChange"
            >
            </editor>
          </v-col>
        </v-row>
      </v-form>

      <v-subheader class="px-0">INSTALAÇÕES & USUÁRIOS</v-subheader>
      <p>Aqui são apresentados todos os usuários que receberem a mensagem. Foram enviadas <b>{{ current.sent }}</b> mensagens, com um total de sucesso de <b>{{ current.sent - current.failed }}</b> mensagens.</p>
      <v-data-table
      :headers="headers"
      :items="installations"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.success`]="{ item }">
        <span>{{ item.success ? "Sim" : "Não" }}</span>
      </template>

      <template v-slot:[`item.user`]="{ item }">
          <span>{{ (item.user && item.user.name) || '-' }}</span>
      </template>

      <template v-slot:[`item.error`]="{ item }">
        <span>{{ item.error || " - " }}</span>
      </template>      

      <template v-slot:[`item.id`]="{ item }">
        <div class="copyable" v-on:click.stop="copy(item.id)">{{ item.id }}</div>
      </template>
    </v-data-table>
    </div>
    <v-snackbar v-model="snackbar">Mensagem atualizada!</v-snackbar>
    <v-snackbar v-model="copyable">Texto copiado!</v-snackbar>
  </v-container>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { diff } from "deep-diff";
import * as moment from "moment";
import { tokens } from "vue-the-mask";
import config from "../plugins/tinymce/config/config";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },

  data: () => ({
    removeDialog: { show: false, loading: false },
    subscriptions: [],
    snackbar: false,
    copyable: false, 

    loadingCategories: false,
    loadingContents: false,

    loading: {
      retrieving: false,
      removing: false,
    },

    search: {
      content: "",
    },

    options: {
      page: 1,
      itemsPerPage: 5
    },
    total: 0,

    installations: [],

    categories: [],
    contents: [],

    message: {},

    current: {},
    old: {},

    rules: {
      required: [(v) => !!v || "Campo obrigatório."],
      string: [(v) => (!!v && typeof v == "string") || "Campo inválido."],
    },

    headers: [
      { text: 'ID', value: 'id', width: '320px', sortable: false },
      { text: 'USUÁRIO', value: 'user', sortable: false },
      { text: 'SUCESSO', value: 'success', width: '100px', sortable: false },
      { text: 'CÓDIGO', value: 'code', sortable: false },
      { text: 'ERRO', value: 'error', sortable: false }
    ],

    types: [
      { id: 1, value: "Normal" },
      { id: 2, value: "Categoria" },
      { id: 3, value: "Verbete" },
      { id: 4, value: "Notícia" },
      { id: 5, value: "URL" },
      { id: 6, value: "Texto" },
    ],
  }),

  computed: {
    hasChanged: function () {
      return !!diff(this.current, this.old);
    },

    initialMCE: function () {
      return config.config;
    },
  },

  watch: {
    hasChanged: {
      deep: true,
      handler(changes) {
        this.$root.actions.save.disable =
          !changes || !this.$refs.form.validate();
      },
    },

    options: {
      handler() {
        this.getInstallations();
      },
      deep: true
    },      

    "current.type": function () {
      if (this.current.type == 2 || 3 && !this.categories.length) {
        this.getCategories()
      }
    },

    "search.content": _.debounce(function (value) {
      if (value) {
        this.getContents(value);
      }
    }, 500),
  },

  mounted: function () {
    this.$root.title = "Mensagem";
    this.loading.retrieving = true;
    this.$root.show({ save: true });

    this.subscriptions.push(
      this.$root.actions.save.event.subscribe((e) => {
        this.save();
      })
    );

    this.getMessage()
    this.getInstallations()
  },

  methods: {
    dateformat(datestring) {
      return moment(datestring).format("DD/MM/YYYY h:mm");
    },

    onEditorInit: function () {
      this.tinyInited = true;
    },

    onEditorChange: function () {
      this.$forceUpdate();
    },

    copy: async function(text) {
      await this.$copyText(text);
      this.copyable = true;
    },

    getContentCategory: function(content) {
      const category = this.categories.filter((e) => e.id === content.category)[0]
      return (category && category.image)
    },

    getInstallations: function() {
      console.log('OPTIONS: ', this.options)

      const start = (this.options.page - 1) * this.options.itemsPerPage
      const limit = this.options.itemsPerPage

      axios
        .get(`messages/${this.$route.params.id}/installations?version=2&start=${start}&limit=${limit}`)
        .then(res => {
          this.installations = res.data.data
          this.total = res.data.total || 10
        })
        .catch(err => {})
    },

    getMessage: function () {
      axios
        .get(`/messages/${this.$route.params.id}?version=2`)
        .then((e) => {
          this.old = e.data.data;
          this.current = this.clone(this.old);
        })
        .catch((e) => {})
        .finally((e) => {
          this.loading.retrieving = false;
        });
    },

    getCategories: function () {
      this.loadingCategories = true;
      axios
        .get(`categories?version=1`)
        .then((res) => {
          this.categories = res.data.data;
        })
        .finally(() => {
          this.loadingCategories = false;
        });
    },

    getContents: function (search) {
      search = search || "a";
      this.loadingContents = true;
      axios
        .get(`/contents?version=2&search=${search}`)
        .then((res) => {
          this.contents = res.data.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingContents = false;
        });
    },

    back: function () {
      router.push("/messages");
    },

    clone: function (obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    save: function () {
      this.$root.actions.save.loading = true;
      let obj = { op: "update" };

      diff(this.current, this.old)
        .map((e) => e.path && e.path[0])
        .filter((e) => e)
        .forEach((e) => Object.assign(obj, { [e]: this.current[e] }));

      axios
        .patch(`messages/${this.current.id}`, obj)
        .then((res) => {
          this.snackbar = true;
          this.getMessage()
        })
        .catch((err) => {})
        .finally(() => {
          this.$root.actions.save.loading = false;
        });
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach((e) => e.unsubscribe());
    this.$data.subscriptions = [];
    next();
  },
};
</script>