var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"px-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-format-color-text","label":"Nome","counter":"128","rules":[_vm.rules.required],"autocomplete":"off","loading":_vm.loading},model:{value:(_vm.current.name),callback:function ($$v) {_vm.$set(_vm.current, "name", $$v)},expression:"current.name"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"copyable",attrs:{"prepend-icon":"mdi-label-outline","label":"Slug","counter":"256","rules":[_vm.rules.required, _vm.rules.slug],"placeholder":"Ex.: pagina-exemplo","autocomplete":"off","loading":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==32)return null;return _vm.format()},"click":function($event){return _vm.$root.copy(_vm.current.slug)}},model:{value:(_vm.current.slug),callback:function ($$v) {_vm.$set(_vm.current, "slug", $$v)},expression:"current.slug"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"copyable",attrs:{"prepend-icon":"mdi-barcode","label":"ID","autocomplete":"off","readonly":"","loading":_vm.loading},on:{"click":function($event){return _vm.$root.copy(_vm.current.id)}},model:{value:(_vm.current.id),callback:function ($$v) {_vm.$set(_vm.current, "id", $$v)},expression:"current.id"}})],1)],1),_c('v-row',[_c('v-col',[_c('editor',{ref:"editor",attrs:{"api-key":"no-api-key","disabled":_vm.saving,"init":{
            height: '500',
            menubar: false,
            plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
            'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
            alignleft aligncenter alignright alignjustify | table image | \
            bullist numlist outdent indent | removeformat | code | help',
            images_upload_url: 'postAcceptor.php'
          }},model:{value:(_vm.current.text),callback:function ($$v) {_vm.$set(_vm.current, "text", $$v)},expression:"current.text"}})],1)],1)],1),_c('v-snackbar',{model:{value:(_vm.snackbars.save),callback:function ($$v) {_vm.$set(_vm.snackbars, "save", $$v)},expression:"snackbars.save"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }