import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
var parse = require('json-parse-safe')
import './assets/css/main.css';
import axios from "axios";

import "./plugins/axios";
import "./plugins/vuetify-dialog";
import "./plugins/codes";
import "./plugins/auth-image";
import "./plugins/clipboard"
import "./plugins/moment"
import { Subject } from 'rxjs';
import jwtDecode from 'jwt-decode';


Vue.config.productionTip = false;

export default new Vue({
  router,
  vuetify,
  render: h => h(App),
  data: ({
    url: axios.defaults.baseURL,
    title: '-',
    user: {},
    snackbar: {
      show: false,
      message: ''
    },
    componentKey: 0,
    actions: {
      download: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      publish:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      history:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      import:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      create:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      remove:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      edit:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      editnew:{
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      save: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
      compare: {
        visible: false,
        disable: false,
        loading: false,
        event: new Subject()
      },
    },
    tinyMCE: {
      customLink: false
    }
  }),
  methods: {
    show: function(action: any){
      this.actions.download.visible = !!action.download;
      this.actions.publish.visible = !!action.publish;
      this.actions.history.visible = !!action.history;
      this.actions.import.visible = !!action.import;
      this.actions.create.visible = !!action.create;
      this.actions.remove.visible = !!action.remove;
      this.actions.editnew.visible = !!action.editnew;
      this.actions.edit.visible = !!action.edit;
      this.actions.save.visible = !!action.save;
      this.actions.compare.visible = !!action.compare;
    },

    canAccess: function(method: string, path:string){
      if (!method && !path) return true;

      var token = localStorage.getItem('token');
      if (token){
        var decoded : any = jwtDecode(token);
        if (decoded){
          var access = decoded.permissions && decoded.permissions.some((e:any) => e.method.toUpperCase().includes(method) && new RegExp(e.path).test(path)  );
          return access;
        }
      }
      return false;
    },

    copy: async function(text: string) {
      await this.$copyText(text)
      this.openSnackbar('Texto copiado!')
    },

    openSnackbar: function(message: string) {
      this.snackbar.show = true
      this.snackbar.message = message
    },

    clone: function(object: any) {
      return JSON.parse(JSON.stringify(object))
    }
  },
  
  created: function(){
    this.user = parse(localStorage.getItem('user')).value;
  }
}).$mount("#app");
