<template>
  <v-container>
    <v-form v-model="form" ref="form">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            label="Nome"
            v-model="current.name"
            :rules="rules.name"
          />
        </v-col>
      </v-row>   
      <v-row>
          <v-col class="py-0">
            <v-combobox
                v-model="current.parent"
                :items="parents.data"
                :loading="parents.loading"
                hide-no-data
                item-text="name"
                item-value="id"
                :return-object="true"
                @update:search-input="searchParents($event)"
                label="Pai"
              ></v-combobox>
          </v-col>
        </v-row>   
      <v-row>
        <v-col class="py-0">
          <v-select
            label="Tipo"
            v-model="current.type"
            :items="types"
            item-text="name"
            item-value="value"
          >
          </v-select>
        </v-col>

        <v-col cols="2" class="py-0">
          <v-text-field
            label="Peso"
            type="number"
            :rules="rules.weight"
            v-model="current.weight"
          />
        </v-col>

        <v-col cols="2" class="py-0">
          <v-select
            label="Ativa"
            :items="selectItems"
            item-text="name"
            item-value="value"
            v-model="current.active"
          ></v-select>
        </v-col>
        <v-col cols="2" class="py-0">
          <v-select
            label="Possui especialidade"
            :items="selectItems"
            item-text="name"
            item-value="value"
            v-model="current.specialties"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-subheader>MODO CLARO</v-subheader>
          <v-row>
            <v-col cols="8">
              <v-text-field
                accept="image/*"
                v-model="current.image"
                autocomplete="off"
                readonly
              >
                <template v-slot:prepend-inner>
                  <v-avatar size="24">
                    <img v-if="current.image" v-bind:src="`${url}/resources/` + current.image">
                  </v-avatar>
                </template>

                <template v-slot:append>
                  <v-btn icon @click="download(current.image)">
                    <v-icon>
                      get_app
                    </v-icon>
                  </v-btn>

                  <v-btn icon @click="uploadDialog.show = true; uploadDialog.type='light'">
                    <v-icon>
                      cloud_upload
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pt-5">
              <v-menu
                v-model="lightmenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="current.color"
                    label="Cor"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <v-avatar :color="current.color" size="16" class="mr-4"> 
                      </v-avatar>
                    </template>
                  </v-text-field>
                </template>
                <v-color-picker
                  v-model="current.color"
                  mode="hexa"
                >
                </v-color-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-subheader>MODO ESCURO</v-subheader>
          <v-row>
            <v-col cols="8">
              <v-text-field
                accept="image/*"
                v-model="current.imageDark"
                autocomplete="off"
                readonly
              >
                <template v-slot:prepend-inner>
                  <v-avatar size="24">
                    <img v-if="current.imageDark" v-bind:src="`${url}/resources/` + current.imageDark">
                  </v-avatar>
                </template>

                <template v-slot:append>
                  <v-btn icon @click="download(current.imageDark)">
                    <v-icon>
                      get_app
                    </v-icon>
                  </v-btn>

                  <v-btn icon @click="uploadDialog.show=true; uploadDialog.type='dark'">
                    <v-icon>
                      cloud_upload
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pt-5">
              <v-menu
                v-model="darkmenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="current.colorDark"
                    label="Cor"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <v-avatar :color="current.colorDark" size="16" class="mr-4"> 
                      </v-avatar>
                    </template>
                  </v-text-field>
                </template>
                <v-color-picker
                  v-model="current.colorDark"
                  mode="hexa"
                >
                </v-color-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="uploadDialog.show" width="600px">
      <v-card class="px-2">
        <v-card-title>Nova imagem</v-card-title>

        <v-card-text>
          <v-form v-model="uploadform" ref="uploadform">
            <v-col class="px-0">
              Ao confirmar, será feito o upload para a estrutura.
            </v-col>
            <v-row>
              <v-file-input
                label="Escolher imagem"
                show-size
                counter
                accept="image/*"
                @change="uploadDialog.file = $event"
                :rules="rules.file"
              >
              </v-file-input>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer>
          </v-spacer>

          <v-btn
            text
            @click="resource(uploadDialog.file, uploadDialog.type)"
            :loading="uploadDialog.loading"
            :disabled="!uploadform"
          >UPLOAD</v-btn>

          <v-btn
            text
            @click="uploadDialog.show = false"
          >CANCELAR</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-subheader>
      VERBETES
    </v-subheader>
    <v-data-table
      :items="contents"
      :headers="headers"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
    >
      <template v-slot:[`item.id`]="{ item }">
        <div class="copyable" @click="copy(item.id)">{{ item.id }}</div>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbars.upload">Imagem alterada!</v-snackbar>
    <v-snackbar v-model="snackbars.patch">Altearções salvas!</v-snackbar>
    <v-snackbar v-model="snackbars.copy">Texto copiado!</v-snackbar>

    <v-dialog v-model="removeDialog.show" width="500px" height="200px">
      <v-card>
        <v-card-title class>Remover Categoria</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p style="padding-bottom:0px">Ao remover a categoria, não será mais possível restaurá-la. Tem certeza que deseja remover esta categoria?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="error"
            :loading="removeDialog.loading"
            @click="remove()"
            width="100"
          >Confirmar</v-btn>
          <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import { diff } from "deep-diff";


Vue.use(VueLodash)

export default {
  data: () => ({
    saving: false,
    form: false,
    uploadform: false,

    contents: [],

    subscriptions: [],

    darkmenu: false,
    lightmenu: false,

    selectItems: [
      {value: true, name: 'Sim'},
      {value: false, name: 'Não'}
    ],

    snackbar: false,
    loading: false,
    total: 0,

    snackbars: {
      upload: false,
      patch: false,
      copy: false, 
    },

    newLightImage: null,
    newDarkImage: null,

    removeDialog: {
      show: false,
      loading: false,
    },

    options: {
      page: 1,
      itemsPerPage: 10
    },   
    
    parents: {
      loading: false,
      data: []
    },

    plans: {
      all: [],
      available: [],
      add: {}
    },

    uploadDialog: {
      show: false,
      loading: false,
      file: null,
    },

    rules: {
      name: [
        v => !!v || 'O nome é obrigatório.',
        v => (!!v && v.length <= 128) || "O nome deve ter no máximo 128 letras."
      ],
      weight: [
        v => !!v || 'O peso é obrigatório.',
        v => v <= 255 || 'O peso máximo é 255.',
        v => v > 0 || 'O peso mínimo é 0.'
      ],
      file: [
        v => !!v || 'O arquivo é obrigatório.',
        v => (!!v && v.size < 1000000) || 'O arquivo deve ter no máximo 1 MB.'
      ],
    },

    partnershipTypes: {
      1: 'Padrão',
      2: 'Secad',
      3: 'Empresa'
    },

    types: [
      {value: 1, name: "Padrão"},
      {value: 2, name: "Artigos/Notícias"},
      {value: 3, name: "Calculadoras"},
      {value: 4, name: "Orientações Medicamentos"},
      {value: 5, name: "CID"},
    ],
    
    old: {},
    current: {},

    lightImage: null,
    darkImage: null,
    
    headers: [      
      { text: 'NOME', value: 'name', sortable: false },
      { text: 'ID', value: 'id', width:'320px', sortable: false },
    ]
  }),
  
  computed: {
    hasChanged: function() {
      return !!diff(this.current, this.old)
    },
  },

  watch: {
    hasChanged: { 
      handler(changed){
        this.$root.actions.save.disable = !changed || !this.$refs.form.validate();
      }, 
      deep: true
    },

    options: {
      deep: true,
      handler() {
        this.getContents()
      }
    }
  },

  mounted: function () {
    this.$root.title = "Categoria";
    const id = router.currentRoute.params.id
    this.$root.show({create: false, save: true, remove: true})
    this.$root.actions.save.disable = !this.$root.canAccess('PATCH', '/categories/');
    this.$root.actions.remove.disable = !this.$root.canAccess('DELETE', '/categories/');
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.push(this.$root.actions.save.event.subscribe(e => {
      this.save()
    }));

    this.$data.subscriptions.push(this.$root.actions.remove.event.subscribe(e => {
      this.removeDialog.show = true
    }));    

    this.load()
    this.searchParents();
  },

  methods: {
    load() {
      const id = this.$router.currentRoute.params.id
      axios
        .get(`categories/${id}?version=1`)
          .then(res => {
            this.old = res.data.data
            this.current = this.clone(this.old)

            this.getContents()
          })
    },

    getContents() {
      const start = (this.options.page - 1) * this.options.itemsPerPage
      const limit = this.options.itemsPerPage

      const id = this.$router.currentRoute.params.id
      this.loading = true

      axios
        .get(`contents?category=${id}&start=${start}&limit=${limit}`)
        .then(res => {
          this.contents = res.data.data
          this.total = res.data.total
          this.loading = false
        })
    },
  
    searchParents: _.debounce(function(e) {
      if (e) {
        this.parents.loading = true;
        axios
          .get(`/categories?version=1&name=${e}&limit=20`)
          .then(e => {
            this.parents.data = e.data.data;
          })
          .catch(e => {
            this.$dialog.notify.error(this.$codes(e), {
              position: "top-right",
              timeout: 5000
            });
          })
          .finally(() => {
            this.parents.loading = false;
          });
      }
    }, 500),
    

    copy: async function(item){
      await this.$copyText(item);
      this.snackbars.copy = true;
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    download(id) {
      var win = window.open(`${axios.defaults.baseURL}/resources/${id}`, '_blank');
      win.focus();
    },

    resource(image, type) {
      this.uploadDialog.loading = true

      const form = new FormData()
      form.append("resource", image);
      form.append("name", image.name);

      const resource = uuid()
      axios.put(`resources/${resource}`, form, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
          this.snackbars.upload = true

          if (type == 'light') this.current.image = resource
          else this.current.imageDark = resource

          this.uploadDialog.loading = false
          this.uploadDialog.show = false
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          });
        })
    },

    save() {
      this.$root.actions.save.loading = true
      
      //remove the object
      if(this.current.parent && typeof(this.current.parent) == 'object') this.current.parent = this.current.parent.id;  

      let keys = diff(this.current, this.old).map(e => e.path[0]);
      
      const obj = {op: 'update'}
      keys.forEach(e => {
        Object.assign(obj, {[e]: this.current[e]})
      })

      this.loading = true
      axios
        .patch(`categories/${this.old.id}`, obj)
        .then(res => {
          this.snackbars.patch = true
          this.load()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: 'top-right',
            timeout: 5000
          });          
        })
        .finally(() => {
          this.$refs.form.disabled = false
          this.$root.actions.save.loading = false
        })
    },

    remove() {
      this.removeDialog.loading = true

      axios
        .delete(`categories/${this.current.id}`)
        .then(() => {
          this.$router.back()
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
    }
  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    next();
  }
};
</script>
<style lang="scss">

  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>