<template>
  <v-container>
    <div>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="filters.user"
            prepend-icon="mdi-magnify"
            label="Usuário"
            v-on:input="debounce"
          ></v-text-field>
        </v-col>
         <v-col cols="4">
          <v-text-field
            v-model="filters.by"
            label="Indicado por"
            v-on:input="debounce"
          ></v-text-field>
        </v-col>
         <v-col cols="4">
          <v-select
            label="Parceria"
            v-model="filters.partnership"
            :items="partnerships"
            item-text="name"
            item-value="id"
            clearable
            @change="debounce"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    
    <v-data-table
      :loading="loading.referrals"
      loading-text="Aguarde..."
      :headers="headers"
      :items="referrals"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.user`]="{ item }">
          <span>{{ item.user.name }}</span>
      </template>
      <template v-slot:[`item.by`]="{ item }">
          <span>{{ item.by.name }}</span>
      </template>
      <template v-slot:[`item.partnership`]="{ item }">
          <span>{{ item.partnership.name }}</span>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ format(item.date) }}</span>
      </template>


    </v-data-table>

  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import pick from "object.pick";
import { v4 as uuid } from "uuid";
import omitEmpty from "omit-empty";
import * as storage from "../libs/storage";
import UserEdit from "../components/User.Edit";
import PushCreate from "../components/Push.Create";
import VoucherUse from "../components/Voucher.Use";
import moment from "moment";

export default {

  components: {
  },

  data: () => ({
    loading: {
      referrals: false,
      partnerships: false,
    },

    referrals: [],
    options: {
      page: 1,
      itemsPerPage: 10
    },

    partnerships: [],
    subscriptions: [],

    filters: {
      user: "",
      by: "",
      partnership: ""
    },

    total: 0,
    headers: [
      { text: "USUÁRIO", value: "user", sortable: false },
      { text: "ORIGEM", value: "source", sortable: false },
      { text: "INDICADO POR", value: "by", sortable: false },
      { text: "PARCERIA", value: "partnership", sortable: false },
      { text: "DATA", value: "date", sortable: false },
    ],

  }),

  watch: {
    options: {
      async handler() {
        this.load();
      },
      deep: true
    },

  },

  mounted: function() {
    this.$root.title = "Programa de Indicações";
    this.$root.show({ });

    // this.$data.subscriptions.push();

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("referrals")) || {}, ["options","filters"])
    );

    this.$data.loading.users = true;
    this.load();
    this.getPartnerships();
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("referrals", JSON.stringify(this.$data));
    next();
  },

  computed: {
  },

  methods: {
    format(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    debounce: _.debounce(function() {
      this.load();
    }, 500),

    load: function() {
      const limit = this.options.itemsPerPage || 10;
      const start = (this.options.page - 1) * limit;

      this.$data.loading.users = true;
      axios
        .get(
          `/users/referrals?version=2&start=${start}&limit=${limit}&sort=-date`,
          {params: {user:this.filters.user, partnership:this.filters.partnership, by:this.filters.by}}
        )
        .then(e => {
          this.$data.referrals = e.data.data;
          this.$data.total = e.data.total;
        })
        .catch(e => {})
        .finally(e => {
          this.$data.loading.users = false;
        });
    },

    getPartnerships: function() {
      axios.get(`partnerships?version=1`).then(e => {
        this.partnerships = e.data.data;
      });
    },

  }
};
</script>
<style>
.link:hover {
  cursor: pointer;
}

.error {
  font-size: 12px;
}
</style>