<template>
   <v-container style="postion:relative">
      <div>
         <v-spacer></v-spacer>
         <v-row>
            <v-col cols="12" md="12">
               <v-text-field
                  v-model="name"
                  prepend-icon="mdi-magnify"
                  label="Busca"
                  v-on:input="debounce"
                  ></v-text-field>
            </v-col>
         </v-row>
      </div>
      <br><br>
      <v-data-table
         :loading="loading" 
         loading-text="Aguarde..."
         :headers="headers"
         :items="specialties"
         no-data-text="Sem resultados"
         :options.sync="options"
         :server-items-length="total"
         @click:row="click"
         :footer-props="{
         'items-per-page-options': [10, 20, 30, 40, 50]
         }"   
         >

         <template v-slot:[`item.id`]="{ item }">
            <div class="copyable" v-on:click.stop="$event.stopPropagation(); copy(item.id)">{{ item.id }}</div>
         </template>
         <template v-slot:[`item.created`]="{ item }">
            <div>{{ dateFormat(item.created) }}</div>
         </template>
         <template v-slot:[`item.default`]="{ item }">
            <div>{{ item.default ? 'Sim' : '-' }}</div>
         </template>
         <template v-slot:[`item.type`]="{ item }">
            <div>{{ partnerTypes[item.type] }}</div>
         </template>
         <template v-slot:[`item.action`]="{ item }">
            <v-icon 
              small
              class="mr-2"
              color="red"
              :disabled="!$root.canAccess('DELETE', '/specialties/')"
              @click="$event.stopPropagation(); remove(item.id, true)"
            >
              mdi-delete
            </v-icon>
         </template>

         
      </v-data-table>

      <v-snackbar v-model="snackbars.copy" :timeout="2000">
         Id copiado
      </v-snackbar>

      <v-snackbar v-model="snackbars.create">
        Substância criada!
      </v-snackbar>

      <v-snackbar v-model="snackbars.delete">
        Substância removida.
      </v-snackbar>

      <v-dialog v-model="createDialog.show" width="600px">
        <v-card>
          <v-card-title>
            Criar Substância
          </v-card-title>

          <v-card-text class="py-0">
            <v-card-subtitle class="px-0">
              Digite as informações da substância a ser criada.
            </v-card-subtitle>
            <v-form v-model="form">
              <v-text-field
                label="Nome"
                v-model="newSubstance.name"
                :rules="rules.name"
              />

              <v-combobox
                ref="keywordsBox"
                v-model="newSubstance.keywords"
                :items="keywords"
                label="Palavra-chave"
                chips
                deletable-chips
                multiple
                style="padding-bottom:20px"
              ></v-combobox>
              
            </v-form>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="create()" :disabled="!form" :loading="createDialog.loading">
              CRIAR
            </v-btn>
            <v-btn text color="primary" @click="createDialog.show=false">
              CANCELAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
         v-model="removeDialog.show"
         width="500px"
         height="200px"
         >
         <v-card>
            <v-card-title class="">Remover Substância</v-card-title>
            <v-card-text style="padding-bottom:0px">
              <p style="padding-bottom:0px">Ao remover a substância, não será mais possível restaurá-la. Tem certeza que deseja remover esta substância?</p>
            </v-card-text>
            <v-card-actions>
               <v-spacer/>
               <v-btn text color="error"  :loading="removeDialog.loading" @click="remove(removeDialog.id, false)" width="100">Confirmar</v-btn>
               <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

   </v-container>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from 'axios';
import router from '../router';
import VueLodash from 'vue-lodash'
import { v4 as uuid } from 'uuid';
import pick from 'object.pick';
import moment from 'moment';
import * as storage from '../libs/storage'

Vue.use(VueLodash)

export default {
  data: () => ({
    name: '',

    snackbars: {
      create: false,
      copy: false,
      delete: false,
    },

    form: false,
    keywords: [],

    newSubstance: {
      name: '',
      keywords: []
    },

    valid: false,
    createDialog: { show: false, loading: false },
    removeDialog: { show: false, loading: false, id: null },
    subscriptions: [],

    loading: false,    
    rules:{
      name: [
        v => !!v || 'Nome é obrigatório',
        v => (v && v.length <= 64) || 'O nome deve ter no máximo 64 caracteres',
      ],
    },
  
    specialties: [],

    options: {
       page: 1,
       itemsPerPage: 10
    },

    total: 0,
    headers: [
      { text: 'ID', value: 'id', width: '320px', align: 'left', sortable: false},
      { text: 'NOME', value: 'name', sortable: false },
      { text: 'AÇÕES', value: 'action', width: '60px', sortable: false }
    ]
  }),
  
  watch: {
    options: {
      async handler () {
        this.reload();
      },
      deep: true
    },
  },

  computed: {
    selectedPlans: function() {
      return this.plans.filter(e => this.newPartnership.plans.includes(e.id))
    }
  },

  mounted: function () {
    this.$root.title = "Substâncias";
    this.$root.show({create: true});
    this.$root.actions.create.disable = !this.$root.canAccess('POST', '/contents/substances/');

    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(this.$root.actions.create.event.subscribe(e => {
      this.open();
    }));

    Object.assign(this.$data, pick(JSON.parse(storage.getItem('plans'))  || {},['name', 'options']));
    this.load(this.$data.code, 0, this.$data.options.itemsPerPage);
  },

  methods: {

    dateFormat: function(str) {
      return moment(str).format('DD/MM/YYYY, H:mm')
    },

    click: function(item) {
      router.push(`/substances/${item.id}`);
    },

    open() {
      this.createDialog.show = true
    },

    copy: async function(item){
      await this.$copyText(item);
      this.snackbars.copy = true;
    },
    
    remove: function(id, dialog) {
      
      if (dialog){
        this.removeDialog.show = true;
        this.removeDialog.id = id;
      } else {

        //this.loading.removing = true;
        this.removeDialog.loading = true;

        axios
          .delete(`/contents/substances/${id}`)
          .then(e => {
              this.removeDialog.show = false;
              this.reload();
          })
          .catch(e => {

          })
          .finally(e => {
            this.removeDialog.loading = false;
          });

      } 
    },

    debounce: _.debounce(function () { 
      this.reload();
    }, 500),

    reload: function(){
      var name = this.name;
      var sort = this.options.sortBy.filter(e => !!e).map((e,i) => `${this.options.sortDesc[i]?"-":""}${e}`).join(",")

      var limit = this.options.itemsPerPage;
      var start = (this.options.page - 1) * limit;

      this.load(name, start, limit, sort);
    },

    load: function(name, start, limit, sort){
      
      this.loading = true;

      var filter = function(field, value){
        return value===null || value===undefined? "": "&"+field+"="+value;
      }

      axios
        .get(`/contents/substances?version=1&name=${name}&start=${start}&limit=${limit}`)
        .then(e => {
          this.specialties = e.data.data;
          this.total = e.data.total;
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: 'top-right',
            //outlined: true,
            timeout: 5000
          });
        })
        .finally(e => {
          this.loading = false;
        });
    },

    create() {
      
      const specialty = {
        id: uuid(),
        name: this.newSubstance.name,
        keywords: this.newSubstance.keywords
      };

      this.createDialog.loading = true;
      axios
        .post(`contents/substances`, specialty)
        .then(res => {
          this.snackbars.create = true
          
          this.createDialog.show = false
          this.createDialog.loading = false

          this.reload()
        })
    }

  },

  beforeRouteLeave(to, from, next){
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem('plans', JSON.stringify(this.$data));
    next();
  }
};
</script>
<style lang="scss">
 
  .copyable {
    &:hover {
      color: rebeccapurple;
      cursor: pointer;
    }
  }

</style>