<template>
  <v-container>
    <div>
      <v-spacer></v-spacer>
      <v-row>
         <v-col cols="12">
          <v-text-field
            v-model="search"
            label="Busca"
            v-on:input="debounce"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-data-table
      :loading="loading.users"
      loading-text="Aguarde..."
      show-select
      :headers="headers"
      :items="users"
      @click:row="edit"
      v-model="selected"

      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click.stop="curriculum(item)">
          <v-icon small>mdi-content-paste</v-icon>
        </v-btn>
        <v-btn icon @click.stop="message(item)">
          <v-icon small>mdi-message</v-icon>
        </v-btn>
        <v-btn icon @click.stop="voucher(item)">
          <v-icon small>mdi-tag</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.type`]="{ item }">{{ typeMap(item.type) }}</template>
    </v-data-table>

  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import pick from "object.pick";
import { v4 as uuid } from "uuid";
import omitEmpty from "omit-empty";
import * as storage from "../libs/storage";
import UserEdit from "../components/User.Edit";
import PushCreate from "../components/Push.Create";
import VoucherUse from "../components/Voucher.Use";

export default {
  components: {
  },

  data: () => ({
    loading: {
      users: false,
      roles: false,
      create: false
    },

    snackbar: false,

    imgfile: null,
    maxsize: 1000000,

    imghover: false,

    options: {
      page: 1,
      itemsPerPage: 10
    },

    subscriptions: [],

    form: false,
    accessform: true,

    newUser: {},
    roles: [],

    search: "",
    selected: [],
    total: 0,
    headers: [
      { text: "NOME", value: "name", sortable: false },
      { text: "TIPO", value: "type", sortable: false },
      { text: "E-MAIL", value: "email", sortable: false },
      {
        text: "ID",
        align: "left",
        width: "300px",
        sortable: false,
        value: "id"
      }
    ],
    users: [],

        types: [
      {
        value: 1,
        name: "Usuário"
      },
      {
        value: 2,
        name: "Autor"
      }
    ],

  }),

  watch: {
    selected: {
      async handler() {
        this.$root.actions.compare.disable = (this.selected.length != 2);
      },
      deep: true
    },

    options: {
      async handler() {
        this.load();
      },
      deep: true
    },

    'dialogs.message.show': function(open) {
      if (!open) this.$root.componentKey ++
    }
  },

  mounted: function() {
    this.$root.title = "Comparação";
    this.$root.show({ compare: true });

    this.$root.actions.compare.disable = true;
    this.$data.subscriptions.push(
      this.$root.actions.compare.event.subscribe(e => {
        this.compare();
      })
    );

    Object.assign(
      this.$data,
      pick(JSON.parse(storage.getItem("users")) || {}, ["options","filters","name"])
    );

    this.$data.loading.users = true;
    this.load();
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("users", JSON.stringify(this.$data));
    next();
  },

  computed: {
    
    validateForms: function() {
      if (this.newUser.access) {
        return !!(this.form && this.accessform);
      }
      return !!this.form;
    },

    oversize: function() {
      if (this.imgfile) return this.imgfile.size > this.maxsize;
      return false;
    },

  },

  methods: {
    debounce: _.debounce(function() {
      this.load();
    }, 500),

    load: function() {
      const limit = this.options.itemsPerPage || 10;
      const start = (this.options.page - 1) * limit;

      this.$data.loading.users = true;
      axios
        .get(`/users?version=5&start=${start}&limit=${limit}&search=${this.search}&sort=-created`)
        .then(e => {
          this.$data.users = e.data.data;
          this.$data.total = e.data.total;
        })
        .catch(e => {})
        .finally(e => {
          this.$data.loading.users = false;
        });
    },

    typeMap(_type) {
        const type = this.types.filter(e => e.value == _type)[0];
        return type ? type.name : "-";
    },


    message: function(user) {
      this.dialogs.message.show = true
      this.dialogs.message.user = user
    },

    edit(item) {
      this.$router.push(`/users/${item.id}`)
    },

    compare() {
      this.$router.push(`/compare?users=${this.selected[0].id},${this.selected[1].id}`)
    }
  }
};
</script>
<style>
.link:hover {
  cursor: pointer;
}

.error {
  font-size: 12px;
}
</style>