<template>
  <v-container>
    <v-data-table
      :items="credentials"
      :headers="headers"
      :loading="loading"
      no-data-text="Nenhuma credencial encontrada"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click="preRemove(item)">
          <v-icon small color="error">delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row class="justify-end">
      <v-btn text @click="dialogs.create = true; createKey = !createKey">+ Adicionar</v-btn>
    </v-row>

    <v-dialog v-model="dialogs.remove.show" width="500px" height="200px">
      <v-card>
        <v-card-title class>Excluir credencial</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p
            style="padding-bottom:0px"
          >Ao excluir a credencial, o usuário não poderá mais logar no sistema com esses dados. Tem certeza de que deseja deletar essa credencial?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="error"
            :loading="dialogs.remove.loading"
            @click="remove()"
            width="100"
          >Confirmar</v-btn>
          <v-btn text color="primary" @click="dialogs.remove.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.create" width="600px">
      <credential-create
        :key="createKey"
        v-on:success="openSnackbar('Credencial criada!'); load()"
        v-on:close="dialogs.create = false"
        :user="this.user"
      ></credential-create>
    </v-dialog>

    <v-snackbar v-model="snackbar.show">{{ snackbar.text }}</v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import CredentialCreate from "./Credential.Create";

export default {
  name: "user-credentials",

  components: {
    CredentialCreate
  },

  props: {
    user: Object
  },

  data: () => ({
    credentials: [],
    loading: false,
    createKey: 0,
    
    headers: [
      {
        text: "ID",
        value: "id",
        width: "320px",
        align: "left",
        sortable: false
      },
      { text: "NOME", value: "username", sortable: false },
      { text: "AÇÕES", value: "action", width: "60px", sortable: false }
    ],

    dialogs: {
      remove: {
        show: false,
        credential: "",
        alias: "",
        loading: false
      },
      create: false
    },

    snackbar: {
      show: false,
      text: ""
    },

    aliases: []
  }),

  watch: {
    user: {
      deep: true,
      handler(val) {
        if (val.id) this.load();
      }
    }
  },

  computed: {
    random() {
      return Math.random(1000)
    }
  },

  methods: {
    load: async function() {
      this.loading = true;
      await this.getCredentials();
      this.loading = false;
    },

    getCredentials() {
      return axios
        .get(`users/${this.user.id}/aliases`)
        .then(res => {
          this.aliases = res.data.data
          return res.data.data.map(e => e.source.id).concat('-1').join(',');
        })
        .then(ids => axios.get(`credentials?id=${ids}`))
        .then(res => {
          this.credentials = res.data.data;
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        });
    },

    openSnackbar(text) {
      this.snackbar.text = text;
      this.snackbar.show = true;
    },

    preRemove(item) {
      this.dialogs.remove.show = true;
      this.dialogs.remove.credential = item.id;
      const alias = this.aliases.filter(e => e.source.id == item.id)[0];
      this.dialogs.remove.alias = alias && alias.id;
    },

    remove() {
      this.dialogs.remove.loading = true;

      Promise.all([
        axios.delete(`credentials/${this.dialogs.remove.credential}`),
        axios.delete(
          `/users/${this.user.id}/aliases/${this.dialogs.remove.alias}`
        )
      ])
        .then(res => {
          this.openSnackbar("Credencial removida.");
          this.dialogs.remove.show = false;

          this.load();
        })
        .catch(err => {
          this.$dialog.notify.error(this.$codes(err), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(() => {
          this.dialogs.remove.loading = false;
        });
    }
  },

  mounted: function() {}
};
</script>

<style>
</style>