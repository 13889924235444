<template>
  <v-card>
    <v-card-title class>Publicar</v-card-title>
    <v-card-text style="padding-bottom:0px">
      <p
        style="padding-bottom:0px"
      >Ao publicar esta revisão, o conteúdo será disponibilizado para os aplicativos.</p>
      <p style="padding-bottom:0px">Tem certeza que deseja publicar esta revisão?</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="error" :loading="loading" @click="publish()" width="180">Publicar Revisão</v-btn>
      <v-btn text color="primary" @click="$emit( 'close' )" width="100">Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "content-publish",

  props: {
    content: String,
    revision: Object
  },

  data: () => ({
    loading: false
  }),

  methods: {
    publish: function() {
      this.loading = true;
      axios
        .patch(`/contents/${this.content}`, {
          op: "publish",
          version: this.revision.version
        })
        .then(e => {
          this.$dialog.notify.info("Conteúdo publicado com sucesso!", {
            position: "top-right",
            timeout: 5000
          });

          this.$emit("reload");
          this.$emit("close");
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>