<template>
  <v-container style="postion:relative">
    <br />
    <br />
    <v-data-table
      :loading="loading"
      loading-text="Aguarde..."
      :headers="headers"
      :items="banners"
      no-data-text="Sem resultados"
      :options.sync="options"
      :server-items-length="total"
      @click:row="category"
      :footer-props="{
         'items-per-page-options': [5, 10, 20, 30, 40, 50]
         }"
    >
      <template v-slot:[`item.id`]="{ item }">
        <div
          class="copyable"
          v-on:click.stop="$event.stopPropagation(); copy(item.id)"
        >{{ item.id }}</div>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <v-avatar size="36">
          <img v-bind:src="`${url}/resources/` + item.image" />
        </v-avatar>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div>{{ formatType(item.type) }}</div>
      </template>
      
      <template v-slot:[`item.weight`]="{ item }">
        <div>{{ formatWeight(item.weight) }}</div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          color="red"
          @click="$event.stopPropagation(); remove(item.id, true)"
          :disabled="!$root.canAccess('DELETE', 'banners')"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="2000">Id copiado</v-snackbar>
    <v-snackbar v-model="snackbars.create" :timeout="2000">Categoria criada!</v-snackbar>

    <v-dialog v-model="removeDialog.show" width="500px" height="200px">
      <v-card>
        <v-card-title class>Remover Banner</v-card-title>
        <v-card-text style="padding-bottom:0px">
          <p style="padding-bottom:0px">Ao remover o banner, não será mais possível restaurá-lo. Tem certeza que deseja remover este banner?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="error"
            :loading="removeDialog.loading"
            @click="remove(removeDialog.id, false)"
            width="100"
          >Confirmar</v-btn>
          <v-btn text color="primary" @click="removeDialog.show = false" width="100">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createDialog.show"
      width="800px"
    >
      <v-card>
        <v-card-title>Novo Banner</v-card-title>
        
        <v-card-text>
          <v-form v-model="valid" ref="form">
          <v-subheader class="px-0">Preencha os campos abaixo para criar um novo banner.</v-subheader>          
           <v-row>
              <v-col md="8" cols="12" class="py-0">
                <v-text-field
                  label="Título"
                  v-model="newBanner.title"
                  autocomplete="off"
                  :rules="rules.title"
                />
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <v-select
                  label="Tipo"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  v-model="newBanner.type"
                  :rules="rules.required"
                ></v-select>
              </v-col>  
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  label="Subtítulo"
                  v-model="newBanner.subtitle"
                  autocomplete="off"
                  :rules="rules.subtitle"
                />
              </v-col>                            
            </v-row>
            <v-row>
              <v-col md="8" cols="12" class="py-0">
                <v-text-field
                  label="URL"
                  v-model="newBanner.url"
                  autocomplete="off"
                  :rules="rules.url"
                />
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <v-select
                  label="Peso"
                  :items="weights"
                  item-text="name"
                  item-value="value"
                  v-model="newBanner.weight"
                  :rules="rules.required"
                ></v-select>
              </v-col>  
            </v-row>
            <v-subheader class="px-0">IMAGEM</v-subheader>
            <v-row>
              <picture-input 
                ref="pictureInput"
                width="750" 
                height="150" 
                margin="12" 
                accept="image/jpeg,image/png" 
                size="1" 
                button-class="btn"
                :crop="false"
                :custom-strings="{
                  upload: '<small>Selecione um arquivo para subir</small>', // HTML allowed
                  drag: '<div class=\'mdi mdi-cloud-upload\' style=\'font-size:20px\' /><br><font size=3>Selecione um arquivo ou arraste-o aqui</font>', // HTML allowed
                  tap: '<small>Selecione um arquivo da sua galeria</small>',
                  change: 'MUDAR IMAGEM', 
                  remove: 'REMOVER IMAGEM'                
                }"
                @change="onImageChange">
              </picture-input>       
            </v-row>
            <v-subheader class="px-0">TEXTO</v-subheader>
            <v-row>
              <editor
                style="height: calc(100% - 100px); width: 100%"
                ref="editor"
                api-key="no-api-key"
                v-on:input="onChange"
                :disabled="createDialog.loading"
                :initial-value="newBanner.text"
                :init="{
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'fullscreen | undo redo | formatselect | bold italic underline strikethrough backcolor | superscript subscript |  \
                    alignleft aligncenter alignright alignjustify | table image | \
                    bullist numlist outdent indent | removeformat | help',
                  images_upload_url: 'postAcceptor.php'
                }"
                >
              </editor>
            </v-row>

      
          </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-spacer />
          <v-btn
            text
            :loading="createDialog.loading"
            @click="create()"
            :disabled="!valid || createDialog.loading || !newBanner.image || !newBanner.text"
            width="100"
          >CRIAR</v-btn>
          <v-btn text color="primary" @click="cancel()" width="100">CANCELAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import axios from "axios";
import router from "../router";
import VueLodash from "vue-lodash";
import { v4 as uuid } from "uuid";
import pick from "object.pick";
import moment from "moment";
import * as storage from '../libs/storage'
import PictureInput from 'vue-picture-input'
import Editor from "@tinymce/tinymce-vue";


Vue.use(VueLodash);

export default {
  data: () => ({

    valid: false,
  
    name: "",
    snackbar: false,
    snackbars: {
      create: false,
    },

    newBanner: {
      title: '',
      subtitle: '',
      image: '',
      type: 0,
      text: '',
      url: '',
      weight: 0
    },

    types: [
      {value: 1, name: 'Para todos'},
      {value: 2, name: 'Para assinantes'},
      {value: 3, name: 'Para não assinantes'}
    ],

    weights: [
      {value: 1, name: 'Peso 1 (menos importante)'},
      {value: 2, name: 'Peso 2'},
      {value: 3, name: 'Peso 3'},
      {value: 4, name: 'Peso 4'},
      {value: 5, name: 'Peso 5'},
      {value: 6, name: 'Peso 6 (mais importante)'},
    ],


    dialog: false,
    removeDialog: { show: false, loading: false, id: null },
    createDialog: { show: false, loading: false, id: null },
    saving: false,
    loading: false,
    subscriptions: [],

    rules: {
      title: [
        v => !!v || "O título é obrigatório",
        v => (v && v.length <= 96) || "O título deve ter no máximo 96 caracteres"
      ],
      subtitle: [
        v => (!v || (v && v.length <= 256)) || "O subtítulo deve ter no máximo 236 caracteres"
      ],
      url: [
        v => (!v || (v && v.length <= 256)) || "A URL deve ter no máximo 236 caracteres"
      ],
      required: [
        v => !!v || 'Campo obrigatório',
      ]
    },

    banners: [],
    plans: [],

    options: {
      page: 1,
      itemsPerPage: 10
    },

    total: 0,

    headers: [
      { text: "TITULO", value: "title", sortable: false },
      { text: "TIPO", value: "type", width: "160px", sortable: false },
      { text: "PESO", value: "weight", width: "200px", sortable: false },
      { text: "ID", value: "id", width: "320px", sortable: false },
      { text: "AÇÕES", value: "action", width: "60px", sortable: false }
    ]
  }),

  components: {
    PictureInput,
    editor: Editor,
  },

  watch: {
    options: {
      async handler() {
        this.load();
      },
      deep: true
    },
  },

  computed: {
  },

  mounted: function() {
    this.$root.title = "Banners";
    this.$root.show({ create: true });
    this.$root.actions.create.disable = !this.$root.canAccess('POST', '/banners/');
    this.url = axios.defaults.baseURL;

    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions.push(
      this.$root.actions.create.event.subscribe(e => {
        this.open();
      })
    );

    this.load();
  },

  methods: {
    
    onChange: _.debounce(function(e) {
      if (tinyMCE.activeEditor) {
        this.newBanner.text = tinyMCE.activeEditor.getContent({});
      }
    }, 500),

    onImageChange (image) {
      if (image) {
        this.newBanner.image = this.$refs.pictureInput.file;
        
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },

    dateFormat: function(str) {
      return moment(str).format("DD/MM/YYYY, H:mm");
    },

    category: function(item) {
      router.push(`/banners/${item.id}`);
    },

    open: function() {
      //for (var member in this.newBanner) this.newBanner[member] = undefined;
      this.createDialog.show = true;  
    },
    cancel: function() {
      this.$refs.form.reset();
      this.createDialog.show = false;  
    },

    copy: async function(item) {
      await this.$copyText(item);
      this.snackbar = true;
    },

    formatType(type) {
      if (type) {
        const obj = this.types.filter(e => e.value == type)[0]
        return obj.name
      }
      return "-"
    },

    formatWeight(weight) {
      if (weight) {
        const obj = this.weights.filter(e => e.value == weight)[0]
        return obj.name
      }
      return "-"
    },

    remove: function(id, dialog) {
      if (dialog) {
        this.removeDialog.show = true;
        this.removeDialog.id = id;
      } else {
        this.removeDialog.loading = true;

        axios
          .delete(`/banners/${id}`)
          .then(e => {
            this.removeDialog.show = false;
            this.load();
          })
          .catch(e => {})
          .finally(e => {
            this.removeDialog.loading = false;
          });
      }
    },

    create() {
      this.createDialog.loading = true
      const image = uuid()

      const lightform = new FormData()
      lightform.append("resource", this.newBanner.image);
      lightform.append("name", this.newBanner.image.name);

      Promise.all([
        axios.put(`resources/${image}`, lightform, {headers: {'Content-Type': 'multipart/form-data'}})
      ])
        .then(res => {
          Object.assign(this.newBanner, {
            id: uuid(),
            image: image,
            title: this.newBanner.title,
            subtitle: this.newBanner.subtitle,
            type: this.newBanner.type,
            text: this.newBanner.text
          })
          axios.post(`banners`, this.newBanner)
            .then(res =>  {
              this.snackbars.create = true;
              this.createDialog.show = false;
              this.$refs.form.reset();
              this.load();
            })
            .catch(err => {
              this.$dialog.notify.error(this.$codes(err), {
                position: "top-right",
                timeout: 5000
              });
            })
            .finally(() => {
              this.createDialog.loading = false;
            })
        })
    },

    debounce: _.debounce(function() {
      this.load();
    }, 500),
    
    load: function() {
      this.loading = true;
      const start = (this.options.page - 1) * this.options.itemsPerPage
      const limit = this.options.itemsPerPage

      axios
        .get(`/banners?version=1&start=${start}&limit=${limit}`)
        .then(e => {
          this.banners = e.data.data;
          this.total = e.data.total;
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",
            timeout: 5000
          });
        })
        .finally(e => {
          this.loading = false;
        });
    },

    getPlans: function() {
      axios
        .get(`plans?sort=-created`)
        .then(e => {
          this.plans = e.data.data;
        })
        .catch(e => {
          this.$dialog.notify.error(this.$codes(e), {
            position: "top-right",

            timeout: 5000
          });
        });
    },

    formatPlan: function(item) {
      return `${item.name} (${item.sku})`;
    },

    upload(file, type) {
      if (type == 'light') Object.assign(this.lightImage, {file: file, url: ''})
      else Object.assign(this.darkImage, {file: file, url: ''})

      if (file) {
        const reader = new FileReader()

        reader.readAsArrayBuffer(file)
        reader.onload = (e) => {
          const result = reader.result
          const base = new Uint8Array(result)
            .reduce((data, byte) => data + String.fromCharCode(byte), "")

          const str = btoa(base)
          const url = `data:image/jpg;charset=utf-8;base64, ${str}`

          if (type == 'light') Object.assign(this.lightImage, {url: url})
          else Object.assign(this.darkImage, {url: url})
        }
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$data.subscriptions.forEach(e => e.unsubscribe());
    this.$data.subscriptions = [];

    storage.setItem("plans", JSON.stringify(this.$data));
    next();
  }
};
</script>

<style lang="scss">
.copyable {
  &:hover {
    color: rebeccapurple;
    cursor: pointer;
  }
}
</style>